import React, { FunctionComponent, ReactElement } from 'react';
import { Button, PageBanner, useLocalStorage } from '@pocketrn/rn-designsystem';
import localePrimer from '../../../utils/localePrimer';
import { sessionUserController } from '../../../utils/userStateInit';
import { useSelector } from 'react-redux';
import { selectFirebaseUser, selectSessionUser } from '../../user-state';
import { useNavigate } from 'react-router-dom';
import { CoreRoute } from '../../../entities/Router';

const locale = 'technician.impersonationBanner';

const ImpersonationBanner: FunctionComponent = (): ReactElement | null => {
  const impersonatedUid = useLocalStorage('_impersonatedUid');
  const sessionUser = useSelector(selectSessionUser);
  const firebaseUser = useSelector(selectFirebaseUser);
  const navigate = useNavigate();

  const isImpersonating = (sessionUser?.uid === impersonatedUid) &&
    (firebaseUser?.uid !== impersonatedUid);

  const logout = () => {
    navigate(CoreRoute.MainPage);
    sessionUserController.logout();
  };

  if (!isImpersonating) {
    return null;
  }

  return (
    <PageBanner
      type="info"
      text={localePrimer.translate(locale, 'content')}
      actionButton={
        <Button
          type="primary"
          onClick={logout}
          testIdRoot="impersonationBanner-logout-btn"
        >
          {localePrimer.translate(locale, 'logout')}
        </Button>
      }
      testIdRoot="impersonationBanner"
    />
  );
};

export default ImpersonationBanner;
