import { SupportTicketJson } from '@pocketrn/entities/dist/core';
import { helpActionId } from './actions';
import { SDKEntities, Action, getSDKReducerState } from '@pocketrn/client/dist/entity-sdk';

export interface HelpState {
  supportTicket: SDKEntities<SupportTicketJson>;
}

const getInitialState = (): HelpState => {
  return { supportTicket: { listEntities: [], mapEntities: {}, loading: false } };
};

const initialState = getInitialState();

const helpReducer = (state = initialState, action: Action): HelpState => {
  return getSDKReducerState(state, action, helpActionId, getInitialState) as HelpState;
};

export default helpReducer;
