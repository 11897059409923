// @NOTE: This is the the date when it was most recently updated, if you make any legal changes to the content be sure to update this date
export const caregiverAuthorizationDate = '2023-03-12';

export const caregiverAuthorizationContent = `
I authorize the caregiver **full access to my account**, including but not limited to the ability
to view and change my medical, personal, and payment information, start meetings on my behalf,
which I may not be present for, and to discuss my medical information and involve my care with 
medical professionals. I understand that I should only give this permission to those I fully trust 
to manage my care and account.
`;
