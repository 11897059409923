import { LicenseType } from '@pocketrn/entities/dist/core';
import { CallTypeTag, ShiftErrorCode } from '@pocketrn/entities/dist/meeting';

export const meeting = {
  callTypeCard: {
    select: 'Select',
    showDetails: 'Details',
    hideDetails: 'Hide Details',
    tag: {
      [CallTypeTag.Assessment]: 'Assessment',
      [CallTypeTag.Consultation]: 'Consultation',
      [CallTypeTag.CheckIn]: 'Check-In',
    },
    caption: {
      meetingLengthMinMax: (min: string, max: string): string => `${min} - ${max} minutes`,
      meetingLength: (length: string): string => `${length} minutes`,
      videoRequired: 'Video Required',
    },
    recommended: 'Recommended',
  },
  modal: {
    userMatch: {
      title: 'You’ve been matched!',
      content: 'You have been matched with a patient or caregiver.',
      goToUserMatch: 'Review Match',
    },
    startScheduledMeeting: {
      requestor: {
        markdown: `
          #### Appointment is starting now!

          Your scheduled appointment with a nurse is being reviewed and about to start.
        `,
        confirm: 'Wait for Nurse',
      },
      acceptor: {
        markdown: `
          #### Appointment is starting now!

          Your scheduled appointment with a patient or caregiver is ready for your review.
        `,
        confirm: 'Prep for Appointment',
      },
    },
    meeting: {
      title: 'Join the Meeting',
      content: 'You may immediately join the meeting or review a summary of the meeting details before joining.',
      reviewDetails: 'Review Details',
      joinMeeting: 'Join Meeting',
    },
    declineFailure: {
      title: 'Cannot Decline Match',
      content: 'You must accept this match because you are the only available nurse for this patient.  If you believe this is an error, please reach out to our Support Team.',
      ack: 'Ok',
    },
    invalidShift: {
      markdown: (localizedReason: string): string => `
        #### The shift could not be saved because:

        ${localizedReason}
      `,
      shiftErrorCode: {
        [ShiftErrorCode.ShiftEndDateInPast]: 'The shift end date cannot be in the past.',
        [ShiftErrorCode.ShiftEndDateTooLate]: 'The shift is too far in the future.',
        [ShiftErrorCode.ShiftHasNoProviders]: 'You need to specify at least one clinic for this shift.',
        [ShiftErrorCode.ShiftStartAfterEnd]: 'The shift start time is after its end time.',
        [ShiftErrorCode.ShiftStartDateTooFarInPast]: 'The shift start date is too far in the past.',
        [ShiftErrorCode.ShiftTooLong]: 'The shift is too long.',
        [ShiftErrorCode.ShiftTooShort]: 'The shift is too short.',
        [ShiftErrorCode.UnsupportedShiftType]: 'This is currently not a supported shift type.',
      },
    },
    expiredSubscription: {
      title: 'Your subscription has expired.',
      content: 'Renew your subscription to be able to connect with a nurse or schedule an appointment.',
      manageButton: 'Manage My Subscription',
      dismissButton: 'Dismiss',
    },
    sameDayCredit: {
      title: 'Your free trial expires today',
      content: 'Upgrade to a paid membership today to ensure your service continues after your trial.',
      manageButton: 'View Paid Plans',
      dismissButton: 'Dismiss',
    },
    oneCreditDay: {
      title: 'Your free trial expires tomorrow',
      content: 'Upgrade to a paid membership today to ensure your service continues tomorrow.',
      manageButton: 'View Paid Plans',
      dismissButton: 'Dismiss',
    },
    xCreditDays: {
      title: (xDays: number): string => `Your free trial expires in ${xDays} days.`,
      content: 'Upgrade to a paid membership to ensure your service continues after your trial.',
      manageButton: 'View Paid Plans',
      dismissButton: 'Dismiss',
    },
    editScheduledMeetingModal: {
      rescheduleHeader: 'Are you sure you want to reschedule this meeting?',
      rescheduleButton: 'Reschedule Meeting',
      cancelHeader: 'Are you sure you want to cancel this meeting?',
      cancelButton: 'Cancel Meeting',
      cancelContent: 'This action cannot be undone.',
      rescheduleContent: `
        By rescheduling this meeting, you may be making unexpected adjustments that could impact the meeting time, participants, and the selected nurse.
        
        If you wish to modify details about the meeting participants or schedule another meeting, you can do so without rescheduling.
      `,
      cancelReasonLabel: 'Reason for Cancellation',
      cancelReasonPlaceholder: 'Type your note here. (optional)',
    },
    missingLicenses: {
      title: 'You have no active licenses.',
      content: `
        Please contact Support at [(872) 233-4490](tel:+1872-233-4490) to add or update your licenses.
      `,
    },
    endShiftsEarlyWarning: {
      title: 'Are you sure you want to stop accepting patients?',
      content: (shifts: string): string => `This will end your in progress shifts before their scheduled end times:<br>${shifts}`,
      leaveAndEndButton: 'End In Progress Shifts',
    },
  },
  main: {
    startAcceptingRequestors: 'Meet with Patients',
    mustAddService: 'You must add at least one service before accepting meetings.',
    contactForMedicalEmergency: 'If there is a medical emergency, call 911.',
    tutorials: 'View Tutorials',
    manageActiveProviders: 'Manage Active Clinics',
    manageShifts: 'Manage Shifts',
    requestHelp: 'Find a Meeting',
    completeTutorialsModal: { title: 'Getting Started' },
    addServiceModal: { title: 'Set up your Offered Services' },
    acceptor: {
      innerPageMeetingTitle: 'Meet with Patients',
      requestorLeftQueue: 'The patient left the queue before you could accept the match.',
      overviewTitle: 'My Dashboard',
      overviewDescription: '',
      addShift: {
        title: 'Add a Shift',
        description: 'Define a shift to specify the clinics you’d like to accept patients for and when to stop accepting patients.',
      },
      acceptPatients: {
        title: 'Are you on shift accepting patients?',
        captionToggleYes: 'Toggle to “Yes” to start accepting patients.',
        captionToggleNo: 'Toggle to “No” to stop accepting patients.',
        matchingPaused: 'Matching is paused.',
        upcomingMeeting: 'You have a scheduled meeting starting soon.',
        inProgressMeeting: 'You have a meeting that is in progress.',
      },
      myShiftsGroup: {
        title: 'My Shifts',
        manageButton: 'Manage Shifts',
      },
      callTypes: {
        title: 'Clinic Call Types',
        manageButton: 'Manage Call Types',
      },
      error: {
        cannotEnterQueue: (noQueueMinutes: number): string => `You cannot enter queue while there are scheduled meetings within the next ${noQueueMinutes} minutes`,
        licenseRequired: `
        You cannot enter queue because you do not have a license.
        
        If this was a mistake, please contact Support.
        `,
      },
    },
    requestor: {
      overviewTitle: 'How can we help you?',
      innerPageMeetingTitle: 'Meet with a Nurse',
      overviewDescription: 'If this is a medical emergency, call 911.',
      reviewItems: {
        clinic: 'clinic',
        topic: 'topic',
        patient: 'patient',
        state: 'state',
      },
      selectClinic: {
        overviewTitle: 'Select a Clinic',
        overviewDescription: '',
        missingConsentsWithProvider: 'Patient needs to review acknowledgments.',
        goToAcknowledgments: 'Sign Consents',
      },
      selectSchedulingType: {
        schedulingTypeOptions: {
          overviewTitle: 'How would you like to schedule a meeting for this patient?',
          overviewDescription: '',
        },
        sendLink: {
          overviewTitle: 'Where should we send the “Schedule Meeting” link?',
          overviewDescription: 'The recipient will be sent a temporary link to schedule a meeting for the patient. You can invite multiple recipients, but all recipients will be modifying the same scheduled meeting.',
        },
        success: {
          overviewTitle: 'A “Schedule Meeting” link has been sent out.',
          overviewDescription: 'You can view the progress of this request in your meetings tab.',
        },
        scheduleNow: 'I Will Schedule the Meeting',
        scheduleLink: 'Send a Schedule Meeting Link',
        overviewHeaderAction: {
          text: 'What does this mean?',
          markdown: `
            #### I Will Schedule the Meeting
            You will walk through the steps to schedule the meeting with the nurse.
            You'll need to know when you and/or the patient is available and confirm
            an appointment time.
            
            #### Send a Schedule Meeting Link
            You will send out a temporary link via text or email that authorizes the recipient
            to schedule a meeting with a nurse at their convenience.
          `,
        },
      },
      selectRegion: {
        overviewPatientTitle: 'In which state are you currently located?',
        overviewCaregiverTitle: (firstName: string): string => `In which state is $[${firstName ? firstName : 'the patient'}]$ currently located?`,
        overviewDescription: '',
        currentLocation: 'Current Location',
        patientCurrentLocation: 'Patient’s Current Location',
        currentLocationRequired: '**To meet with a nurse, you must first select your location.**',
        changeCurrentClinicModal: { header: 'Would you like to change your current clinic?' },
        unsupportedRegionModal: {
          header: 'This clinic does not offer service in your current location.',
          text: 'For more information about which states are supported, contact our support team at [(872) 233-4490](tel:+1872-233-4490).',
        },
        unsupportedPatientRegionModal: {
          header: 'This clinic does not offer service in the patient’s current location.',
          text: 'For more information about which states are supported, contact our support team at [(872) 233-4490](tel:+1872-233-4490).',
        },
        continue: 'Continue',
      },
      selectTopic: {
        overviewTitle: 'Select a Topic',
        overviewDescription: '',
        redirectForServices: {
          title: 'Can’t find the help you need?',
          introduction: `
            Each clinic can choose which topics they offer on PocketRN and whether or
            not those topics are available to request as a caregiver or that require a patient.
  
            If the topic you need help with is not listed, we recommend that you contact the clinic manager directly.
            If you believe there is a mistake, email [support@pocketrn.com](mailto: support@pocketrn.com).
          `,
        },
        searchBar: {
          placeholder: 'Search for a topic...',
          noFilter: 'Clear Filter',
        },
        requiresVideoModal: `
          ### This is a Video Visit

          In order to complete this meeting, the nurse will need to see the patient via video.

          #### By continuing, you confirm either:
          * the patient will be able to join the meeting via video, or
          * you—or someone you invite to participate in the meeting—will be able to join the meeting via video while physically present with the patient.

          If these requirements are not met, the nurse may need to cancel the meeting.

          #### Need help?
          View our instructions on how to [join the meeting](https://www.pocketrn.com/support/zoom-install).
          If you need any assistance, please contact Support at [(872) 233-4490](tel:+1872-233-4490).
        `,
        suggestionCard: {
          markdown: {
            asPatient: `
              #### Can't find the topic you are looking for?

              The clinic has provided additional instructions. Or you can try switching to another clinic. They may offer the service you're looking for.
            `,
            asCaregiver: `
              #### Can't find the topic you are looking for?

              This clinic may not offer the *caregiver-only* meeting topic you are looking for.
              You may need to go back and select a patient to continue or review the additional
              instructions provided by the clinic.
            `,
          },
          learnMore: 'See Clinic Instructions',
          switchClinic: 'Switch Clinic',
        },
      },
      connectWithNurse: {
        overviewTitle: 'Ready to Meet?',
        overviewDescription: 'By requesting a meeting, you agree to a virtual visit.',
        additionalDetails: 'Are there any additional details you would like the nurse to know before the meeting?',
        note: {
          label: 'Note to Nurse',
          placeholder: 'Type your note here. (optional)',
        },
        connectNow: 'Connect Now',
        error: {
          cannotEnterQueue: (noQueueMinutes: number): string => `You cannot enter queue while there are scheduled meetings within the next ${noQueueMinutes} minutes`,
        },
      },
      selectPatient: {
        overviewTitle: 'Select a Patient',
        overviewDescription: '',
        select: 'Select',
        addNewPatient: 'Add a Patient',
        caregiverOnly: {
          text: 'Request a caregiver-only meeting',
          subText: 'Meet with a nurse for general training or about a topic unrelated to a single patient.',
        },
        noPatients: 'In order to meet with a nurse about a patient’s care, you first need to add the patient.',
        insufficientPermissions: 'You do not have permission to start a meeting for this patient.',
        missingAckowledgments: 'Patient needs to review acknowledgments.',
        missingDateOfBirth: 'Patient is missing a valid date of birth.',
        signAckModal: {
          title: 'Patient needs to review acknowledgments.',
          description: 'The patient has not yet consented to the terms of PocketRN.  If the patient has not agreed to them by the time of the scheduled meeting, the meeting may be canceled.',
          signAcks: 'Sign Acknowledgments',
        },
        busyInMeetingModal: `
          #### Patient is busy

          This patient is currently in a meeting or in queue for a meeting. You may still request a meeting for them, but they may be unable to join.
        `,
      },
      meetingNotifications: {
        overviewTitle: 'Where should we send notifications about this meeting?',
        overviewDescription: '',
        patientNotificationGroup: {
          phones: {
            titleForPatient: 'Your Phones:',
            titleForCaregiver: 'Patient’s Phones:',
          },
          email: {
            titleForPatient: 'Your Email:',
            titleForCaregiver: 'Patient’s Email:',
          },
          self: 'Self',
        },
        patientCaregiversGroup: {
          titleForPatient: 'Your Caregivers:',
          titleForCaregiver: 'Patient’s Caregivers:',
          add: 'Add a Caregiver',
          noCaregivers: 'You do not have any caregivers connected to your account.',
          helper: {
            title: 'Who is considered a caregiver on PocketRN?',
            markdown: `
              A caregiver is anyone who helps or cares for you. They may be a loved one, a professional caregiver, a legal representative, or a member of a partnering care organization.
              
              You can manage the permissions of each caregiver and may freely invite as many caregivers as you wish to participate in your care.
            `,
          },
        },
        customNotifiedGroup: {
          title: 'Additional Recipients:',
          add: 'Add an Email or Phone Number',
          sendToEmail: 'Send to Email Address',
          textToPhone: 'Text to Phone Number',
          andOr: 'and/or',
          noCustomNotified: 'No additional recipients added.',
          mobileAndText: 'Mobile/Text',
          email: 'Email',
          helper: {
            title: 'What is a Recipient?',
            markdown: `
              #### What is a Recipient?

              You may add other email addresses or phone numbers.  They will be notified about this meeting only and will not be saved for any future meetings. If you’d rather save recipient information for future meetings, add a caregiver instead.
            `,
          },
        },
      },
      addCaregiverModal: {
        title: 'Add a Caregiver',
        description: 'You may add a caregiver to receive notifications about this meeting.',
        add: 'Add Caregiver',
      },
      connectWithNurseCard: {
        title: 'I’d like to meet with a nurse right now.',
        button: 'Meet Right Now',
      },
      scheduleMeetingCard: {
        title: 'I’d like to schedule a meeting with a nurse.',
        button: 'Schedule a Meeting',
      },
      // @CUSTOM: Nevvon - used for the Nevvon partnership clinics.
      nevvonAccountCard: {
        title: 'Access your caregiver training materials.',
        button: 'Go to my Nevvon Account',
      },
      otherMeetingsInProgress: `
        #### Are you sure you want to continue?

        You already have other meetings in progress. By continuing, you could potentially be in more than one meeting at once. We recommend waiting until after your current meeting is complete.
      `,
      upcomingMeetingWarningModal: `
        #### Are you sure you want to continue?
        
        You have an upcoming scheduled appointment that will start shortly.  
        By continuing, you could potentially be in more than one meeting at once.  
        We recommend waiting until after your schedule appointment is complete if you are one of the intended participants.
      `,
      alreadyRequestedMeetingModal: `
        #### You cannot connect with a nurse at this time.

        You are already in a meeting with a nurse as per your request. You cannot request more than on meeting at a time.
      `,
      subscriptionBanner: {
        content: 'Your subscription has expired, click Upgrade to renew your membership and enable your access to video chat with nurses.',
        upgrade: 'Upgrade',
      },
    },
    meetings: {
      overviewTitle: 'Your Meetings',
      overviewTitleForManaged: (name: string): string => `Meetings for ${name}`,
      overviewScheduleTitle: 'Schedule a Meeting',
      overviewDescription: 'View the in progress, upcoming and past meetings.',
      tabs: {
        inProgress: 'In Progress',
        upcoming: 'Upcoming',
        past: 'Past',
      },
      myMeetingsGroup: {
        title: 'My Meetings',
        managedButton: 'Manage Meetings',
        viewMoreButton: 'View More',
      },
      inProgress: 'In Progress',
      needsAttention: 'Needs Attention',
      inReviewForRequestor: 'Waiting for Nurse',
      inReviewForAcceptor: 'Pending Review',
      youAreLate: 'You’re running late',
      acceptorIsLate: 'Nurse is running late',
      noMeetingsInProgress: 'There are no meetings in progress.',
      noUpcomingMeetings: 'There are no meetings scheduled.',
      youAreNotParticipating: 'Not a participant',
      requestedMeetingButNotParticipating: 'You requested this meeting but you are not a participant. If you’d like to participate, you can do so by editing the participants or contacting the patient if you don’t have the permissions.',
      noPastMeetings: 'There are no past meetings.',
      newMeetingButton: 'Schedule a meeting',
      viewMyPatientsMeetingsButton: 'View My Patients’ Meetings',
      myMeetingsButton: 'My Meetings',
      patientProfileButton: (patientName: string): string => `${patientName}'s Profile`,
      form: {
        selectDateAndTime: 'Select a Date & Time',
        selectDate: 'Select a Date',
        selectTime: 'Select a Time',
        timeZone: 'Time Zone',
        timeZonePlaceholder: 'Select a time zone',
        nurseSelectLabel: 'Nurse',
        anyNurse: 'Any Available Nurse',
        getIntlDateStr: (date: Date, tz: string):
          string => new Intl.DateTimeFormat(undefined, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          weekday: 'short',
          hour: 'numeric',
          minute: 'numeric',
          timeZone: tz,
        }).format(date),
      },
      confirmMeeting: {
        title: 'Does This Look Right?',
        description: 'We’ve matched you with a qualified nurse.',
        button: 'Confirm Meeting',
        additionalDetails: 'Are there any additional details you would like the nurse to know before the meeting?',
        note: {
          label: 'Note to Nurse',
          placeholder: 'Type your note here. (optional)',
        },
        error: { nurseUnavailable: 'Nurse is no longer available to meet at this time, please choose another time.' },
      },
      confirmation: {
        description: '',
        overviewHeaderAction: {
          text: 'How will I join the meeting?',
          markdown: `
            #### How will I join the meeting?

            At the time of the meeting, a link will be sent to the meeting participants via
            text and email.  Alternatively, participants can always join a meeting by logging
            into the PocketRN app at the time of the meeting.

            It is possible that you did not include yourself as a meeting participant.  If so,
            you can modify the scheduled meeting to ensure you will be notified of the meeting.
          `,
        },
        newMeeting: {
          title: 'Your meeting has been scheduled',
        },
        rescheduled: {
          title: 'Your meeting has been rescheduled',
        },
        canceled: {
          title: 'Your meeting has been canceled',
          error: { alreadyCanceled: 'This meeting has already been canceled.' },
        },
        updated: {
          title: 'Your meeting has been updated',
        },
        addToCalendar: {
          fileName: (callType: string) :string => `PocketRN Meeting - ${callType}`,
          description: 'You can always view and manage your scheduled meetings by visiting https://app.pocketrn.com/meetings',
          button: 'Add to Calendar',
        },
      },
      editScheduledMeeting: {
        overviewTitle: 'Scheduled Meeting',
        rescheduleButton: 'Reschedule Meeting',
        editParticipantsButton: 'Edit Participants',
        cancelButton: 'Cancel Meeting',
        participantsNote: 'If you would rather schedule a different time or need to cancel this meeting, kindly get in touch with the person who scheduled the meeting or the nurse.',
        potentialDuplicateEventsWarning: {
          markdown: `
          #### Are you sure?
  
          This event may already be on your calendar.
          You can still download the event to your calendar if you wish, but it may create a duplicate event.
        `,
        },
        notEditableNotOwnerNote: 'If you need to reschedule or cancel this meeting, kindly get in touch with the person who scheduled the meeting or the nurse.',
        notEditableOwnerNote: 'In order to reschedule or cancel this meeting, switch to the account type used to schedule the meeting.',
      },
    },
  },
  shift: {
    moreProviders: (count: number): string => `+${count} more clinics`,
    showProviders: {
      title: 'Clinics for Shift',
    },
    shiftDeletedWarning: `
      Deleting this shift will not alter any existing scheduled meetings.

      If you need to reschedule or cancel a meeting due to this change, you'll need to do so manually.
    `,
    inProgress: 'In Progress',
  },
  callTypes: {
    moreCallTypes: (count: number): string => `+${count} more call types`,
    showCallTypes: {
      title: 'Call Types for Clinic',
    },
    noCallTypes: 'No call types set for this clinic',
  },
  shifts: {
    innerPageTitle: 'Manage Shifts',
    main: { title: 'Upcoming Shifts' },
    shift: {
      addTitle: 'Add a Shift',
      editTitle: 'Edit a Shift',
      description: 'A shift will automatically start and stop at the times indicated.',
    },
    buttons: {
      add: 'Add a Shift',
      deleteAll: 'Delete All Shifts',
    },
    deleteAllShiftsModal: {
      confirm: (count: number): string => `
      #### Are you sure you want to delete all ${count} shifts?

      This action cannot be undone and it deletes each shift one at a time, so it may take a few moments.
    `,
      deleting: (count: number): string => `
      #### Deleting shifts...

      There are ${count} shifts remaining to be deleted. Please wait.
    `,
    },
    form: {
      clinics: 'Clinics',
      date: 'Start date',
      startTime: 'Start Time',
      endTime: 'End Time',
      timeZone: 'Time Zone',
      timeZonePlaceholder: 'Select a time zone',
    },
    shiftEditedWarning: `
      Modifying this shift will not alter any existing scheduled meetings.

      If you need to reschedule or cancel a meeting due to this change, you'll need to do so manually.
    `,
    missingCallTypesWarning: `
      One or more of your selected clinics are missing call types.

      You cannot accept patients until you remove this clinic or add call types.
    `,
    addCallTypes: 'Add Call Types',
  },
  userMatch: {
    personCardTitles: {
      callRequestor: 'Call Requested By:',
      participants: 'Participants:',
      patient: 'Patient:',
      nurse: 'Nurse:',
    },
    waitForNursePage: {
      overviewTitle: 'Your meeting will start soon!',
      overviewDescription: 'Please wait while the nurse reviews your info. You may leave this page, we’ll text you a link within the next 10 minutes once the meeting starts.',
      listKeys: {
        licenses: 'Licenses',
        clinic: 'Clinic',
      },
      licenses: {
        [LicenseType.CertifiedNursingAssistant]: 'Certified Nursing Assistant',
        [LicenseType.MedicalAssistant]: 'Medical Assistant',
        [LicenseType.LicensedVocationalNurse]: 'Licensed Vocational Nurse',
        [LicenseType.LicensedPracticalNurse]: 'Licensed Practical Nurse',
        [LicenseType.RegisteredNurse]: 'Registered Nurse',
        [LicenseType.NursePractitioner]: 'Nurse Practictioner',
        [LicenseType.DoctorOfMedicine]: 'Doctor of Medicine',
      },
    },
    inQueuePage: {
      overviewTitleAcceptor: 'Now Accepting Patients',
      overviewDescriptionAcceptor: `
        Matching you with a patient or caregiver.
        
        Feel free to close or navigate away from this page.  If you do, we will send you an SMS notification when a match is found.  If you cannot accept a meeting, you can safely ignore or decline the match and the patient will match with the next available service provider.
      `,
      overviewTitleRequestor: 'You are now being matched with a nurse.',
      overviewDescriptionRequestor: `
        Feel free to close this page. We’ll send you a text message with instructions once you've been matched.
      `,
      searchingForRequestors: 'Searching for a match...',
      searchingForAcceptors: 'Searching for a match...',
      enteringQueue: 'Entering the queue, please do not close this window...',
    },
    caregiverOnlyNote: {
      title: 'This is a caregiver-only meeting',
      description: `
        This meeting will not include a patient.  You should only provide general medical advice
        and training to the caregiver.  If caregivers discuss a specific patient’s care, we suggest
        scheduling a separate meeting as we may need to obtain the patient’s consent.

        Note that any references to ‘patient’ on this page pertain to the caregiver.
      `,
    },
    matchFoundPage: {
      overviewTitle: 'You’ve been matched!',
      overviewDescription: 'Please accept or decline this meeting.',
      willAutomaticDeclineSeconds: (count: string): string => `Time before auto-decline: ${count}`,
      requestedCallType: 'Help Requested',
      genderIdentity: 'Gender',
      viewProfile: 'View Profile',
      noZoomMeetings: 'No Zoom hosts were available at this time. This is not normal, and our support team has been notified.',
      reviewMessage: 'You have 5 minutes to accept the meeting before the match is automatically declined. After accepting the match, you’ll have up to 10 minutes to prepare before joining the meeting.',
      clinic: 'Clinic',
      topic: 'Topic',
      instructions: 'Instructions',
      noInstructions: 'No instructions provided for this call type.',
    },
    meetingPrepPage: {
      overviewTitle: 'Meeting Prep Time',
      patientWaiting: 'The patient or caregiver is waiting, please review their info below. As soon as you feel prepared, press the button to start the meeting.',
      willAutomaticStartSeconds: (count: number): string => `Time before auto-start: ${count}`,
      startMeeting: 'Start Meeting',
    },
    meetingPage: {
      overviewTitle: 'Ready to join the meeting?',
      nonParticipantOverviewTitle: 'Meeting in Progress',
      overviewDescription: 'Review the meeting details.  When ready, hit the button below to join the virtual meeting.',
      finishMeeting: 'End Meeting',
      leaveMeeting: 'Leave Meeting',
      leaveFeedback: 'Leave Feedback',
      joinMeeting: 'Join Meeting',
      notAParticipant: 'You cannot join this meeting because you are not a participant.',
      copyMeetingLink: 'Copy Meeting Link',
      copyMeeting: {
        error: 'Failed to copy zoom link to clipboard',
        success: 'Zoom link copied to clipboard',
      },
      contactRequestor: 'Remind Patient',
      confirmFinishMeeting: {
        title: 'Are you sure you want to finish the meeting?',
        description: `
          It may take a couple minutes for all participants to join the meeting.

          For any technicial difficulties, call our support staff at [(872) 233-4490](tel:+1872-233-4490).
        `,
      },
      confirmLeaveMeeting: {
        title: 'Are you sure you want to leave the meeting?',
        description: `
          It may take a couple minutes for all participants to join the meeting.

          For any technicial difficulties, call our support staff at [(872) 233-4490](tel:+1872-233-4490).
        `,
      },
      notifiedRequestor: 'We have sent a reminder to the participant to join the Zoom meeting. Make sure to have the Zoom app open and watch for them to join.',
      requestorEndedMeeting: 'The requestor has ended the meeting.',
      acceptorEndedMeeting: 'The nurse has ended the meeting.',
      requiresVideo: {
        title: 'Patient must join via video',
        markdown: `
          To complete this meeting, the patient must join via video.
          If not possible, please cancel or reschedule.

          If the patient needs assistance, please 
          contact Support at [(872) 233-4490](tel:+1872-233-4490).
        `,
      },
    },
    requestMeetingFeedbackPage: {
      overviewTitle: 'Before you go, your feedback is greatly appreciated.',
      reportIssue: 'Provide Additional Feedback',
      noPHI: 'Do not include PHI...',
      acceptor: {
        rateMotivation: 'I had the resources or training to appropriately care for this patient.',
        rateAppropriateness: 'A PocketRN visit was appropriate for the topics discussed.',
        rateEngagement: 'I had a positive experience matching, reviewing notes, and joining this call.',
        selectAvoidedEscalation: 'Based on your best judgment or context from the patient, could this visit have resulted in (select all that apply):',
        additionalContext: 'Feel free to provide additional context',
      },
      requestor: {
        rateMotivation: 'I feel more motivated to care for myself after meeting with a nurse today.',
        rateInPersonCare: 'If I didn’t have this PocketRN visit, I would have used in-person care instead.',
        rateEngagement: 'I would enjoy meeting with this nurse again if matched in the future.',
        selectAlternativeCare: 'If I didn’t have PocketRN available to me today, I would have:',
      },
      caregiver: {
        rateMotivation: 'I feel more motivated to care for my patient after meeting with a nurse today.',
        rateInPersonCare: 'If I didn’t have this PocketRN visit, I would have used in-person care instead.',
        rateEngagement: 'I would enjoy meeting with this nurse again if matched in the future.',
        selectAlternativeCare: 'If I didn’t have PocketRN available to me today, I would have:',
      },
      highestGrade: 'Strongly Agree',
      lowestGrade: 'Strongly Disagree',
      selectAvoidedEscalationOptions: {
        exacerbation: 'Potentially avoided exacerbation (any type or severity)',
        primaryCare: 'Potentially avoided visit to PCP or Specialty Care',
        readmission: 'Potentially avoided readmission following discharge',
        urgentCare: 'Potentially avoided Emergency Department or Urgent Care visit',
        hospitalization: 'Potentially avoided hospitalization',
        explain: 'Explain (Do not include PHI)...',
      },
      selectAlternativeCareOptions: {
        differentTelehealth: 'Used a different telehealth service',
        primaryCare: 'Visited my Primary Care Provider',
        specialtyCare: 'Visited a Specialty Care Provider',
        emergencyRoom: 'Gone to the Emergency Room',
        urgentCare: 'Visited an Urgent Care Clinic',
        nothing: 'Done nothing',
        explain: 'Explain here...',
      },
    },
  },
  participantsCards: {
    requestor: 'Call Requested By:',
    patient: 'Patient:',
    nurse: 'Nurse:',
    participants: 'Participants:',
  },
  reportIssue: {
    overviewTitle: 'Provide Additional Feedback or Report Issue',
    overviewDescription: `
      Do not include any medical information.

      All medical related questions should be directed to a nurse or to your primary care provider.
    `,
    sendMessage: 'Send',
    message: {
      title: 'Or send us a message',
      label: 'Message',
      email: '[support@pocketrn.com](mailto:support@pocketrn.com)',
      phone: '[(872) 233-4490](tel:+1872-233-4490)',
    },
    messageSentNote: 'Your report was sent. A representative will contact you via email to follow up within 1-2 business days.',
    blockUser: 'Block Participant',
    blockUserNote: (fullName: string): string => `Blocking $[${fullName ? fullName : 'the participant'}]$ will ensure you never match with them again.`,
    userBlockedNote: (fullName: string): string => `
      You will not be matched with $[${fullName ? fullName : 'the participant'}]$ again.
      
      If this was a mistake, please contact Support.
    `,
  },
  manageCallTypes: {
    overviewTitle: 'Manage Call Types',
    overviewDescription: (clinic: string): string => `
      Below are the call types you can choose to offer patients at **$[${clinic}]$**.
      
      You may change these selections at any time.
    `,
  },
  queueStats: {
    queuePosition: { requestor: 'Other patients ahead of you' },
    queuePositionForService: { requestor: 'Ahead of you for your requested service' },
    queueTotal: { requestor: 'Other patients in the queue' },
    elapsedTime: (time: string): string => `Elapsed Time: ${time}`,
    duration: (time: string): string => `Duration: ${time}`,
  },
  nurseMeetingEnd: {
    overviewTitle: 'Wrap up your visit.',
    overviewDescription: `
      For our records, enter the amount of time you spent **reviewing the patient notes and meeting with the patient** on Zoom. 
      This may seem redundant, but it's often easy to forget to end the meeting immediately after the Zoom call.
    `,
    form: {
      done: 'Done',
      label: 'Total duration',
      placeholder: 'Enter time in minutes',
      minutesSinceStart: (minutes: number): string => `Minutes since match accepted: ${minutes}`,
      totalCompensation: (total: number): string => `Total Compensation: ${total} minutes`,
      unknownCompensation: 'Total Compensation: ?',
      inputError: 'Cannot be greater than the time since match accepted.',
      unacceptedInput: 'Input not accepted.',
    },
  },
  provider: {
    operationHours: {
      providerClosed: 'Virtual clinic currently closed',
      providerClosedForNurse: 'Virtual clinic currently closed, but you may continue to accept patients.',
      overviewDescription: (provider: string): string => `$[${provider}]$ nurses are available for video visits during the following hours:`,
      needHelp: 'If you need help outside of regular business hours, please contact your primary care provider or specialty provider.',
      localTimeZone: (): string => new Intl.DateTimeFormat().resolvedOptions().timeZone,
      inQueueTitle: 'You are about to join the queue for the following clinic(s): ',
      activeClinicsInQueue: (clinics: string[]): string => `Your active clinics are: **$[${clinics.join(', ')}]$**`,
      activeClinicInQueue: (clinic: string): string => `Your active clinic is: **$[${clinic}]$**`,
      noClinicsInQueue: 'You have no active clinics',
    },
  },
  sessions: { moreMenu: { messages: 'Messages' } },
};

export const locales = { meeting };
