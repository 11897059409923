import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { Notification, NotificationType } from '@pocketrn/entities/dist/chatbot';
import { useSelector } from 'react-redux';
import {
  selectNotificationList,
  selectNotificationLoading,
} from '../../state/redux/messages/selectors';
import { Notifications } from '../../ds/index';
import { Filter, FilterGroup } from '@pocketrn/rn-designsystem';
import { MessagesController } from '../../state/controllers/Messages.controller';
import { LocalePrimer } from '@pocketrn/localizer';

const locale = 'messages.notifications';

enum NotificationsFilter {
  Notifications = 'notifications',
  NotificationArchive = 'notificationArchive',
}

interface Props {
  messagesController: MessagesController;
  localePrimer: LocalePrimer;
}

const NotificationsContent: FunctionComponent<Props> = (props): ReactElement => {
  const notificationsFilterMenuItems = [
    {
      value: NotificationsFilter.Notifications,
      text: props.localePrimer.translate(locale, 'filter.notifications'),
    },
    {
      value: NotificationsFilter.NotificationArchive,
      text: props.localePrimer.translate(locale, 'filter.archived'),
    },
  ];

  type NotificationTypeFilter = 'all' | NotificationType;

  const notificationTypeFilterMenuItems = [
    {
      value: 'all',
      text: props.localePrimer.translate(locale, 'filter.type.all'),
    },
    {
      value: NotificationType.System,
      text: props.localePrimer.translate(locale, 'filter.type.system'),
    },
    {
      value: NotificationType.PrnStaff,
      text: props.localePrimer.translate(locale, 'filter.type.prnStaff'),
    },
  ];

  const notifications = useSelector(selectNotificationList);
  const notificationsLoading = useSelector(selectNotificationLoading);
  const [
    displayNotifications,
    setDisplayNotifications,
  ] = useState([] as Notification[]);
  const [
    activeNotification,
    setActiveNotification,
  ] = useState(undefined as undefined | Notification);
  const [
    notificationsFilter,
    setNotificationsFilter,
  ] = useState(NotificationsFilter.Notifications);
  const [
    notificationTypeFilter,
    setNotificationTypeFilter,
  ] = useState('all' as NotificationTypeFilter);
  const loading = notificationsLoading;

  useEffect(() => {
    if (!notificationsLoading) {
      filter();
    }
  }, [ notificationsLoading, notificationTypeFilter, notifications ]);

  useEffect(() => {
    const getNotifications = async () => {
      await props.messagesController.clearNotifications();
      if (notificationsFilter === NotificationsFilter.Notifications) {
        await props.messagesController.retrieveNotifications(false);
      } else {
        await props.messagesController.retrieveNotifications(true);
      }
    };
    getNotifications();
  }, [notificationsFilter]);

  const handleScroll = (atBottom: boolean) => {
    if (
      notificationsFilter === NotificationsFilter.NotificationArchive &&
      atBottom &&
      !notificationsLoading
    ) {
      props.messagesController.retrieveNotifications(true);
    }
  };

  const toggleNotification = (notification: Notification) => {
    if (activeNotification?.id === notification.id) {
      setActiveNotification(undefined);
    } else {
      setActiveNotification(notification);
      if (notification.unread) {
        props.messagesController.markNotificationAsRead(notification.id);
      }
    }
  };

  const archiveNotification = (notification: Notification) => {
    if (activeNotification?.id === notification.id) {
      setActiveNotification(undefined);
    }
    props.messagesController.archiveNotification(notification.id);
  };

  const filter = () => {
    let selectedNotifications = notifications;
    if (notificationTypeFilter !== 'all') {
      selectedNotifications = selectedNotifications.filter(notification => {
        return notification.type === notificationTypeFilter;
      });
    }
    setDisplayNotifications(selectedNotifications);
  };

  const filterNotificationType = (keyword: string) => {
    setNotificationTypeFilter(keyword as NotificationTypeFilter);
  };

  const filterNotifications = (keyword: string) => {
    setNotificationsFilter(keyword as NotificationsFilter);
  };

  return (
    <Notifications
      filter={
        <FilterGroup>
          <Filter
            value={notificationsFilter}
            menuItems={notificationsFilterMenuItems}
            onChange={filterNotifications}
          />
          <Filter
            value={notificationTypeFilter}
            menuItems={notificationTypeFilterMenuItems}
            onChange={filterNotificationType}
          />
        </FilterGroup>
      }
      notifications={displayNotifications}
      activeNotification={activeNotification}
      onScrollToBottom={handleScroll}
      onToggleNotification={toggleNotification}
      onArchiveNotification={archiveNotification}
      loading={loading}
      localePrimer={props.localePrimer}
    />
  );
};

export default NotificationsContent;
