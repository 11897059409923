import { Action, ReduxAction } from '@pocketrn/client/dist/entity-sdk';
import { TextJson } from '../../../entities/interfaces/Text.json';
import { Text } from '../../../entities/Text';

export const REDUCER_KEY = 'chatbot';

const ra = new ReduxAction(REDUCER_KEY);

export interface SetChatbotChat extends Action {
  chatbotChat: TextJson[];
}

export interface AddToChatbotChat extends Action {
  chatbotChat: TextJson[];
}

export interface SetUnreadNotificationsCount extends Action {
  unreadNotificationsCount: number;
}

export interface SetUnreadUserChatsCount extends Action {
  unreadUserChatsCount: number;
}

export interface SetDrawerIsOpen extends Action {
  isOpen: boolean;
}

export const ChatbotActionId = {
  SET_CHATBOT_CHAT: ra.actionType('SET_CHATBOT_CHAT'),
  ADD_TO_CHATBOT_CHAT: ra.actionType('ADD_TO_CHATBOT_CHAT'),
  SET_UNREAD_NOTIFICATIONS_COUNT: ra.actionType('SET_UNREAD_NOTIFICATIONS_COUNT'),
  SET_UNREAD_USERCHATS_COUNT: ra.actionType('SET_UNREAD_USERCHATS_COUNT'),
  SET_DRAWER_IS_OPEN: ra.actionType('SET_DRAWER_IS_OPEN'),
};

export class ChatbotActions {
  public static setChatbotChat(chat: Text[]): SetChatbotChat {
    const chatbotChat = chat.map(text => text.json());
    return {
      type: ChatbotActionId.SET_CHATBOT_CHAT,
      chatbotChat,
    };
  }

  public static addToChatbotChat(chat: Text[]): AddToChatbotChat {
    const chatbotChat = chat.map(text => text.json());
    return {
      type: ChatbotActionId.ADD_TO_CHATBOT_CHAT,
      chatbotChat,
    };
  }

  public static setUnreadNotificationsCount(
    unreadNotificationsCount: number,
  ): SetUnreadNotificationsCount {
    return {
      type: ChatbotActionId.SET_UNREAD_NOTIFICATIONS_COUNT,
      unreadNotificationsCount,
    };
  }

  public static setUnreadUserChatsCount(
    unreadUserChatsCount: number,
  ): SetUnreadUserChatsCount {
    return {
      type: ChatbotActionId.SET_UNREAD_USERCHATS_COUNT,
      unreadUserChatsCount,
    };
  }

  public static setDrawerIsOpen(isOpen: boolean): SetDrawerIsOpen {
    return {
      type: ChatbotActionId.SET_DRAWER_IS_OPEN,
      isOpen,
    };
  }
}
