import { FirebaseFunctionInterface, FirebaseSDK, OnCallFunction } from '@pocketrn/client/dist/entity-sdk';
import { Auth } from 'firebase/auth';
import { Draft, DraftSpecifiers } from './Draft';
import { buildDraft } from './Draft.factory';

export class SecureDraftSDK extends FirebaseSDK {
  constructor(functions: FirebaseFunctionInterface, firebaseAuth: Auth) {
    super(functions, firebaseAuth);
  }

  public async getDraft(specifiers: DraftSpecifiers): Promise<Draft | undefined> {
    try {
      const resp = await this.functions.httpsCallable(OnCallFunction.Help, 'getDraft')({
        providerId: specifiers.providerId ?? null,
        groupId: specifiers.groupId,
        id: specifiers.id,
      });
      if (!resp.data.draft) {
        return undefined;
      }
      return buildDraft(resp.data.draft);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async setDraft(draft: Draft): Promise<void> {
    const _draft = draft.json();
    try {
      await this.functions.httpsCallable(OnCallFunction.Help, 'setDraft')({
        draft: {
          id: _draft.id,
          groupId: _draft.groupId,
          providerId: _draft.providerId ?? null,
          value: _draft.value,
          expiresAt: _draft.expiresAt,
        },
      });
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async clearDraft(specifiers: DraftSpecifiers): Promise<void> {
    try {
      await this.functions.httpsCallable(OnCallFunction.Help, 'deleteDraft')({
        providerId: specifiers.providerId ?? null,
        groupId: specifiers.groupId,
        id: specifiers.id,
      });
    } catch (err) {
      throw await this.handleErr(err);
    }
  }
}
