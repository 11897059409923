import { UpChevronIcon } from '@pocketrn/rn-designsystem';
import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import styles from './AccordionItem.module.css';

interface AccordionItemProps {
  value: string;
  label: string;
  toggleActive: (value: string) => void;
  active: boolean;
  subLabel?: string;
  note?: string;
  subNote?: string;
  icon?: ReactElement;
  ignorePadding?: boolean;
  children?: ReactNode;
  testIdRoot?: string;
}

export const AccordionItem: FunctionComponent<AccordionItemProps> = (props): ReactElement => {
  const toggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    props.toggleActive(props.value);
  };

  const getContentStyles = (): string => {
    const s = [`${styles.content}`];
    if (props.active) {
      s.push(`${styles.active}`);
    }
    if (!props.ignorePadding) {
      s.push(`${styles.padded}`);
    }
    return s.join(' ');
  };

  return (
    <section className={styles.wrapper} data-test={props.testIdRoot}>
      <div className={styles.itemWrapper} onClick={toggle}>
        <div className={styles.left}>
          {props.icon}
          <div className={`${styles.labelWrapper} ${props.icon ? styles.withIcon : ''}`}>
            <span className={styles.label}>{props.label}</span>
            {props.subLabel &&
              <span className={styles.sublabel} data-test={`${props.testIdRoot}-${props.subLabel}`}>
                {props.subLabel}
              </span>
            }
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.noteWrapper}>
            {props.note && <span className={styles.note}>{props.note}</span>}
            {props.subNote && <span className={styles.sublabel}>{props.subNote}</span>}
          </div>
          <div className={`${styles.icon} ${props.active ? styles.active : ''}`}>
            <UpChevronIcon />
          </div>
        </div>
      </div>
      <div className={getContentStyles()}>
        {props.children}
      </div>
    </section>
  );
};
