// tslint:disable-next-line:no-implicit-dependencies
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
// @NOTE: this must be initialized before the app is created so that firebase auth can be initialized.
import '../state/controllers/User.controller';
import coreReducer from '../state/redux/core/reducer';
import registrationReducer from '../state/redux/registration/reducer';
import {
  chatbotReducer,
  messagesReducer,
  CHATBOT_REDUCER_KEY,
  MESSAGES_REDUCER_KEY,
} from '../apps/messaging-app/index';
import {
  technicianReducer,
  TECHNICIAN_REDUCER_KEY,
} from '../apps/technician-app/index';
import {
  helpReducer,
  HELP_REDUCER_KEY,
} from '../apps/help-app/index';
import {
  meetingReducer,
  MEETING_REDUCER_KEY,
  userMatchReducer,
  USER_MATCH_REDUCER_KEY,
} from '../apps/meeting-app/index';
import {
  firebaseUserReducer,
  FIREBASE_USER_REDUCER_KEY,
  sessionUserReducer,
  SESSION_USER_REDUCER_KEY,
  userStateReducer,
  USER_STATE_REDUCER_KEY,
} from '../apps/user-state/index';
import {
  pageReducer,
  PAGE_REDUCER_KEY,
} from '../apps/page-state/index';
import {
  featureFlagReducer,
  FEATURE_FLAG_REDUCER_KEY,
} from '../apps/feature-flags/index';

const rootReducer = combineReducers({
  core: coreReducer,
  registration: registrationReducer,
  [MEETING_REDUCER_KEY]: meetingReducer,
  [USER_MATCH_REDUCER_KEY]: userMatchReducer,
  [CHATBOT_REDUCER_KEY]: chatbotReducer,
  [MESSAGES_REDUCER_KEY]: messagesReducer,
  [SESSION_USER_REDUCER_KEY]: sessionUserReducer,
  [FIREBASE_USER_REDUCER_KEY]: firebaseUserReducer,
  [TECHNICIAN_REDUCER_KEY]: technicianReducer,
  [HELP_REDUCER_KEY]: helpReducer,
  [USER_STATE_REDUCER_KEY]: userStateReducer,
  [PAGE_REDUCER_KEY]: pageReducer,
  [FEATURE_FLAG_REDUCER_KEY]: featureFlagReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export default store;
