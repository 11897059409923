import { License } from '@pocketrn/entities/dist/core';
import { useForegroundPage } from '@pocketrn/rn-designsystem';
import React, { FunctionComponent, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import localePrimer from '../../../utils/localePrimer';
import styles from './Card.module.css';
import overrideStyles from './QueueDataCard.module.css';

export interface QueueDataCardProps {
  title?: string;
  name: string;
  email: string;
  providerId?: string;
  region?: string;
  licenses?: License[];
  callTypes: string[];
  blockedLookupIds?: string[];
  testIdRoot: string;
}

const locale = 'technician.viewQueueData.card';

export const QueueDataCard: FunctionComponent<QueueDataCardProps> = (props): ReactElement => {
  const location = useLocation();
  const foreground = useForegroundPage(location);

  const getLicenses = () => {
    return props.licenses?.map((license, index) => (
      <p key={index}>{license.jurisdiction.toString()}</p>
    ));
  };

  const getCallTypes = () => {
    return props.callTypes.map((c, index) => (
      <p key={index}>{c}</p>
    ));
  };

  const getBlockedLookupIds = () => {
    return props.blockedLookupIds?.map((block, index) => <p key={index}>{block}</p>);
  };

  const getDetailItems = () => {
    const items = [];
    items.push({
      key: localePrimer.translate(locale, 'fullName'),
      value: props.name,
      dataTestId: 'fullName',
    });
    items.push({
      key: localePrimer.translate(locale, 'email'),
      value: props.email,
      dataTestId: 'email',
    });
    if (props.providerId) {
      items.push({
        key: localePrimer.translate(locale, 'providerId'),
        value: props.providerId,
        dataTestId: 'providerId',
      });
    }
    if (props.region) {
      items.push({
        key: localePrimer.translate(locale, 'region'),
        value: props.region,
        dataTestId: 'region',
      });
    }
    if (props.licenses) {
      items.push({
        key: localePrimer.translate(locale, 'licenses'),
        value: getLicenses(),
        dataTestId: 'licenses',
      });
    }
    items.push({
      key: localePrimer.translate(locale, 'callTypes'),
      value: getCallTypes(),
      dataTestId: 'callTypes',
    });
    const blockedStr = getBlockedLookupIds();
    if (blockedStr) {
      items.push({
        key: localePrimer.translate(locale, 'blocked'),
        value: getBlockedLookupIds(),
        dataTestId: 'blocked',
      });
    }
    return items;
  };

  const detailItems = getDetailItems();
  return (
    <section
      className={`${styles.card} ${overrideStyles.card} ${foreground ? styles.cardForeground : ''}`}
    >
      {props.title &&
        <div className={styles.header}>
          <h3>{props.title}</h3>
        </div>
      }
      <table>
        <tbody>
          {detailItems.map(row => (
            <tr key={row.key} data-test={`${props.testIdRoot}-queueDataCard-${row.dataTestId}-rowKey`}>
              <td
                className={`${styles.key} ${overrideStyles.key}`}
                data-test={`${props.testIdRoot}-queueDataCard-${row.dataTestId}-tdKey`}
              >
                {row.key}
              </td>
              <td
                className={`${styles.value} ${overrideStyles.value}`}
                data-test={`${props.testIdRoot}-queueDataCard-${row.dataTestId}-tdValue`}
              >
                {row.value}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};
