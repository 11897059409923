import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import {
  AutoComplete,
  Button,
  ButtonGroup,
  Form,
  Paragraph,
  TextField,
} from '@pocketrn/rn-designsystem';
import { Person } from '@pocketrn/entities/dist/community';
import formValidator from '../../utils/fv';
import localePrimer from '../../utils/localePrimer';

const locale = 'core.acknowledgments.signature';

interface Props {
  title: string;
  person: Person;
  loading?: boolean;
  onNameChange: (firstName: string, lastName: string) => void;
  onCancel: () => void;
}

const SignatureUpdateForm: FunctionComponent<Props> = (props): ReactElement => {
  const { person } = props;
  const [ firstName, setFirstName ] = useState('');
  const [ firstNameErr, setFirstNameErr ] = useState('');
  const [ lastName, setLastName ] = useState('');
  const [ lastNameErr, setLastNameErr ] = useState('');

  useEffect(() => {
    setFirstName(person.firstName ?? '');
    setLastName(person.lastName ?? '');
  }, [person]);

  const changeFirstName = (newValue: string) => {
    setFirstNameErr('');
    setFirstName(newValue);
  };

  const changeLastName = (newValue: string) => {
    setLastNameErr('');
    setLastName(newValue);
  };

  const formFieldsAllValid = (): {
    firstName: string,
    lastName: string,
    allValid: boolean,
  } => {
    let allValid = true;
    const firstNameValidation = formValidator.validateFirstName(firstName);
    if (firstNameValidation.error) {
      setFirstNameErr(firstNameValidation.error);
      allValid = false;
    }
    const lastNameValidation = formValidator.validateLastName(lastName);
    if (lastNameValidation.error) {
      setLastNameErr(lastNameValidation.error);
      allValid = false;
    }
    return {
      firstName: firstNameValidation.correctedValue,
      lastName: lastNameValidation.correctedValue,
      allValid,
    };
  };

  const changeName = () => {
    const validation = formFieldsAllValid();
    if (!validation.allValid) {
      return;
    }
    props.onNameChange(firstName, lastName);
  };

  return (
    <Form>
      <Paragraph
        extraBold
        text={props.title}
        noBottomPadding
        align="center"
        color="black"
        size="sm"
      />
      <TextField
        error={firstNameErr}
        label={localePrimer.translate(locale, 'firstName')}
        value={firstName}
        onChange={changeFirstName}
        loading={props.loading}
        testIdRoot="signature-firstName-field"
        autoComplete={AutoComplete.FirstName}
      />
      <TextField
        error={lastNameErr}
        label={localePrimer.translate(locale, 'lastName')}
        value={lastName}
        onChange={changeLastName}
        loading={props.loading}
        testIdRoot="signature-lastName-field"
        autoComplete={AutoComplete.LastName}
      />
      <ButtonGroup noBottomPadding>
        <Button
          type="primary"
          onClick={changeName}
          loading={props.loading}
          disabled={!!(firstNameErr || lastNameErr)}
          testIdRoot="signature-saveName-btn"
          submitOnEnter
        >
          {localePrimer.translate('shared.save')}
        </Button>
        <Button
          type="text"
          onClick={props.onCancel}
          loading={props.loading}
          testIdRoot="signature-cancelEditName-btn"
        >
          {localePrimer.translate('shared.cancel')}
        </Button>
      </ButtonGroup>
    </Form>
  );
};

export default SignatureUpdateForm;
