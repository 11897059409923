import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Choice } from '../../entities/Choice';
import { BotResponseLibrary } from '../../utils/BotResponseLibrary';
import { selectChatbotChat } from '../../state/redux/chatbot/selectors';
import { ChatBot } from '../../ds/components/Chat/ChatBot';
import { LocalePrimer } from '@pocketrn/localizer';
import { useScrollToTarget } from '@pocketrn/rn-designsystem';

interface ChatBotContentProps {
  botResponseLibrary: BotResponseLibrary;
  close: () => void;
  localePrimer: LocalePrimer;
  isOpen: boolean;
}

const ChatBotContent: FunctionComponent<ChatBotContentProps> = (props): ReactElement => {
  const isOpen = props.isOpen;
  const chatbotChat = useSelector(selectChatbotChat);
  const [ choices, setChoices ] = useState([] as Choice[]);
  const [ Target, scrollToTarget ] = useScrollToTarget();

  useEffect(() => {
    if (!chatbotChat.length) {
      props.botResponseLibrary.initializeBotChat();
    }
  }, []);

  useEffect(() => {
    if (chatbotChat.length) {
      const mostRecentMessage = chatbotChat[chatbotChat.length - 1];
      const choices = props.botResponseLibrary.getChoices(mostRecentMessage);
      setChoices(choices);
      if (isOpen) {
        scrollToTarget();
      }
    }
  }, [ chatbotChat, isOpen ]);

  return (
    <ChatBot
      chat={chatbotChat}
      choices={choices}
      scrollToBottomTarget={Target}
      close={props.close}
      localePrimer={props.localePrimer}
    />
  );
};

export default ChatBotContent;
