import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalFooter,
  Paragraph,
  H2,
} from '@pocketrn/rn-designsystem';
import { LocalePrimer } from '@pocketrn/localizer';
import { CoreRoute } from '../../../../entities/Router';
import { TimeUtils } from '@pocketrn/time-utils';
import { AccountType } from '@pocketrn/entities/dist/core';
import { selectSessionUser } from '../../../user-state';
import { useSelector } from 'react-redux';

const locale = 'meeting.modal';

const MAX_CREDIT_DAYS = 4;

interface Props {
  localePrimer: LocalePrimer;
  closeModal?: () => void;
}

const SubscriptionNoticeModal: FunctionComponent<Props> = (props): ReactElement | null => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(selectSessionUser);
  const account = user?.activeAccountType;
  const { localePrimer } = props;

  const expiredSubscription = !user?.stripe?.getActiveProductKeys().length;
  const creditEndsAt = user?.stripe?.getCreditEndsAt();

  const [ isOpened, setIsOpened ] = useState(false);
  const [ creditDaysDifference, setCreditDaysDifference ] = useState(0);

  useEffect(() => {
    // @NOTE: When a payment is made, there is a redirect from Stripe back to the
    // subscription page.  There is a race condition in which the database may not
    // have the user's activeProductKeys yet by the time the redirect finishes, causing
    // the modal to appear even though they JUST submitted their payment information.
    if (location.pathname.includes(CoreRoute.Subscription)) {
      setIsOpened(false);
      return;
    }
    if (!account || account !== AccountType.Patient) {
      setIsOpened(false);
      return;
    }
    if (expiredSubscription) {
      setIsOpened(true);
      return;
    }
    const creditDaysDifference = TimeUtils.getDaysDifference(
      creditEndsAt,
      new Date(),
    );
    creditDaysDifference && setCreditDaysDifference(creditDaysDifference);
    setIsOpened(true);
  }, []);

  const closeModal = () => {
    setIsOpened(false);
    if (props.closeModal) {
      props.closeModal();
    }
  };

  const manageSubscription = () => {
    setIsOpened(false);
    navigate(CoreRoute.Subscription);
  };

  if (
    // @NOTE: If we use expiredSubscription, the modal will not be shown.
    // because the expiredSubscription ckeck for active trial or active subscription
    !user?.stripe?.activeProductKeys.length &&
    (account && account === AccountType.Patient) &&
    ((creditDaysDifference <= MAX_CREDIT_DAYS && creditDaysDifference >= 0))
  ) {
    const noticeLocale = expiredSubscription ? 'expiredSubscription' :
      (creditDaysDifference === 1) ? 'oneCreditDay'
        : (creditDaysDifference === 0) ? 'sameDayCredit'
          : 'xCreditDays';
    const title = (creditDaysDifference <= 1 || expiredSubscription) ?
      localePrimer.translate(locale, noticeLocale, 'title')
      : localePrimer.translate(locale, noticeLocale, 'title')(creditDaysDifference);
    return (
      <Modal
        onClose={closeModal}
        hide={!isOpened}
        clickAway
        constrainedSize="md"
        footer={
          <ModalFooter
            buttons={[
              <Button
                key={0}
                type="primary"
                onClick={manageSubscription}
                testIdRoot="subscriptionNoticeModal-manageSubscription-btn"
                submitOnEnter
              >
                {localePrimer.translate(locale, `${noticeLocale}.manageButton`)}
              </Button>,
              <Button
                key={1}
                type="text"
                onClick={closeModal}
                testIdRoot="subscriptionNoticeModal-dismiss-btn"
              >
                {localePrimer.translate(locale, `${noticeLocale}.dismissButton`)}
              </Button>,
            ]}
          />
        }
      >
        <H2
          align="center"
          text={title}
          testIdRoot="subscriptionNoticeModal-title"
        />
        <Paragraph text={localePrimer.translate(locale, `${noticeLocale}.content`)} />
      </Modal>
    );
  } else {
    return null;
  }
};

export default SubscriptionNoticeModal;
