import { MeetingSDK } from '../../services/firebase/MeetingSDK';
import { ActionKey, meetingActions, REDUCER_KEY } from '../redux/sdk/actions';
import { Shift, ShiftFactory, ShiftJson, ShiftType, SingleShiftFactory, SingleShiftJson } from '@pocketrn/entities/dist/meeting';
import { MeetingState } from '../redux/sdk/reducer';
import { PRNErrorResponse } from '@pocketrn/client/dist/entity-sdk';

// @NOTE: Redux does not export its Store type.
export type ReduxStore = any;

export class ShiftController {
  public sdk: MeetingSDK;
  public store: ReduxStore;

  constructor(sdk: MeetingSDK, store: ReduxStore) {
    this.sdk = sdk;
    this.store = store;
  }

  public async getShifts(): Promise<void> {
    this.store.dispatch(meetingActions.setLoading(ActionKey.Shifts, true));
    const shifts = await this.sdk.getShifts();
    this.store.dispatch(meetingActions.setListEntities(ActionKey.Shifts, shifts));
    this.store.dispatch(meetingActions.setLoading(ActionKey.Shifts, false));
  }

  public async addShift(shift: Shift): Promise<{
    shiftErrorCode?: string;
  }> {
    try {
      await this.sdk.addShift(shift);
      await this.getShifts();
      return {};
    } catch (err) {
      if (err instanceof PRNErrorResponse) {
        return { shiftErrorCode: err.code };
      } else {
        throw err;
      }
    }
  }

  public async editShift(shift: Shift): Promise<{
    shiftErrorCode?: string;
  }> {
    try {
      await this.sdk.editShift(shift);
      await this.getShifts();
      return {};
    } catch (err) {
      if (err instanceof PRNErrorResponse) {
        return { shiftErrorCode: err.code };
      } else {
        throw err;
      }
    }
  }

  public async deleteShift(id: string): Promise<void> {
    this.store.dispatch(meetingActions.setLoading(ActionKey.Shifts, true));
    await this.sdk.deleteShift(id);
    const shifts = this.getShiftsListEntities();
    const _shifts = shifts.filter(shift => shift.id !== id);
    this.store.dispatch(meetingActions.setListEntities(ActionKey.Shifts, _shifts));
    this.store.dispatch(meetingActions.setLoading(ActionKey.Shifts, false));
  }

  private getShiftsListEntities(): Shift[] {
    const shifts = this.state().shifts.listEntities;
    return shifts.map((shift: ShiftJson) => {
      switch (shift.type) {
        case ShiftType.Single:
          return SingleShiftFactory.build(shift as SingleShiftJson);
        default:
          return ShiftFactory.build(shift);
      }
    });
  }

  private state(): MeetingState {
    return this.store.getState()[REDUCER_KEY];
  }
}
