import { MessageType } from '../../entities/supportTicket';
import { HelpSDK } from '../../services/firebase/HelpSDK';
import { helpActions, ActionKey } from '../redux/sdk/actions';

// @NOTE: Redux does not export its Store type.
export type ReduxStore = any;

export class SupportTicketController {
  public sdk: HelpSDK;
  public store: ReduxStore;

  constructor(sdk: HelpSDK, store: ReduxStore) {
    this.sdk = sdk;
    this.store = store;
  }

  public async reportIssue(messageType: MessageType, message: string): Promise<void> {
    this.store.dispatch(helpActions.setLoading(ActionKey.SupportTicket, true));
    await this.sdk.reportIssue(message, messageType);
    this.store.dispatch(helpActions.setLoading(ActionKey.SupportTicket, false));
  }

  public async contactSupport(messageType: MessageType, message: string): Promise<void> {
    this.store.dispatch(helpActions.setLoading(ActionKey.SupportTicket, true));
    await this.sdk.contactSupport(message, messageType);
    this.store.dispatch(helpActions.setLoading(ActionKey.SupportTicket, false));
  }
}
