import { ReduxAction, Action } from '@pocketrn/client/dist/entity-sdk';
import { FirebaseUser } from './FirebaseUser';

export const REDUCER_KEY = 'firebaseUser';

const ra = new ReduxAction(REDUCER_KEY);

export interface SetUser extends Action {
  user: FirebaseUser;
}

export const FirebaseUserActionId = {
  UNSET_USER: ra.actionType('UNSET_USER'),
  SET_USER: ra.actionType('SET_USER'),
};

export class FirebaseUserActions {
  public static unsetUser(): Action {
    return { type: FirebaseUserActionId.UNSET_USER };
  }

  public static setUser(user: FirebaseUser): SetUser {
    return {
      type: FirebaseUserActionId.SET_USER,
      user: {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        emailVerified: user.emailVerified,
        signinProvider: user.signinProvider,
      },
    };
  }
}
