import React, { FunctionComponent, ReactElement } from 'react';
import { Notification as NotificationEntity } from '@pocketrn/entities/dist/chatbot';
import { TimeUtils } from '@pocketrn/time-utils';
import { ElapsedTime } from '@pocketrn/time-utils/lib/src/TimeUtils';
import styles from './Notification.module.css';
import {
  ArchiveIcon,
  IconButton,
  Markdown,
  SwipeableItem,
} from '@pocketrn/rn-designsystem';
import { LocalePrimer } from '@pocketrn/localizer';
import { CircleIcon } from '../Chat/CircleIcon';

interface NotificationProps {
  notification: NotificationEntity;
  isActive: boolean;
  onToggleNotification: () => void;
  onArchiveNotification: () => void;
  localePrimer: LocalePrimer;
}

const locale = 'messages.notifications';

export const Notification: FunctionComponent<NotificationProps> = (props): ReactElement => {
  const getTimeString = (elapsedTime: ElapsedTime): string => {
    return props.localePrimer.translate(
      'format.time.unitsAbbreviations',
      elapsedTime.localeKey,
    )(elapsedTime.time);
  };

  const getNotificationStyles = () => {
    const s = [`${styles.notification}`];
    if (props.notification.unread) {
      s.push(`${styles.unread}`);
    }
    if (props.isActive) {
      s.push(`${styles.open}`);
    }
    return s.join(' ');
  };

  const content = (
    <div className={styles.wrapper} onClick={props.onToggleNotification}>
      {props.notification.unread &&
        <CircleIcon />
      }
      <div className={getNotificationStyles()}>
        <div className={styles.notificationType}>
          {props.localePrimer.translate(locale, 'filter.type', props.notification.type)}
        </div>
        <div className={styles.notificationMessage}>
          <Markdown noBottomPadding size="sm" markdown={props.notification.text} />
        </div>
      </div>
      <div className={styles.infoAndOptions}>
        <div className={!props.notification.archivedAt ? styles.time : ''}>
          {getTimeString(TimeUtils.getElapsedTime(props.notification.sentAt))}
        </div>
        {!props.notification.archivedAt &&
          <div className={styles.archive}>
            <IconButton onClick={props.onArchiveNotification}>
              <ArchiveIcon />
            </IconButton>
          </div>
        }
      </div>
    </div>
  );

  return props.notification.archivedAt ? (
    content
  ) : (
    <SwipeableItem
      backgroundIcon={<ArchiveIcon dark={true} />}
      onSwipe={props.onArchiveNotification}
    >
      { content }
    </SwipeableItem>
  );
};
