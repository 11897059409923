// @NOTE: This is the the date when it was most recently updated, if you make any legal changes to the content be sure to update this date
export const privacyPracticesDate = '2022-10-06';

export const privacyPracticesContent = `
Completion of this document authorizes the disclosure and use of health information about
you. Failure to provide all information requested may invalidate this authorization.

PocketRN is committed to protecting your privacy and ensuring that your health
information is used and disclosed appropriately. **The Health Insurance Portability and Accountability Act of 1996 (“HIPAA”)** 
requires us to ask each of our patients to acknowledge
receipt of our Notice of HIPAA Privacy Practices.  This Notice of Privacy Practices
identifies all potential uses and disclosures of your health information by our practice
and outlines your rights with regard to your health information. Please sign the form
below to acknowledge that you have received our Notice of Privacy Practices.

The Notice is published on the PocketRN website. You can also receive a copy of the Notice
by asking for one from PocketRN Support, or by printing one from our website,
[PocketRN.com](https://pocketrn.com/policies/), at any time. On our website you can find links to our Privacy Policy, HIPPA
NPP, and Terms on the bottom toolbar, or you can use the links listed below.

[Privacy Policy](https://pocketrn.com/policies/privacy)

[HIPAA Notice of Privacy Practices](https://www.pocketrn.com/policies/notice-of-privacy-practices)

[Terms of Service](https://pocketrn.com/policies/terms)

By signing this document, I acknowledge that I have received a copy of the Notice of Privacy
Practices of PocketRN.
`;
