import { Action } from '@pocketrn/client/dist/entity-sdk';
import { UserMatchActionId, SetRequestorLeftQueue, SetMatchMaking, SetScheduledMeetingStarting } from './actions';

export interface UserMatchState {
  requestorLeftQueue: boolean;
  inQueue: boolean;
  meetingFound: boolean;
  scheduledMeetingStarting: string;
}

const initialState: UserMatchState = {
  requestorLeftQueue: false,
  inQueue: false,
  meetingFound: false,
  scheduledMeetingStarting: '',
};

const userMatchReducer = (state = initialState, action: Action): UserMatchState => {
  const newState = state;
  switch (action.type) {
    case UserMatchActionId.SET_REQUESTOR_LEFT_QUEUE:
      return State.setRequestorLeftQueue(newState, action as SetRequestorLeftQueue);
    case UserMatchActionId.SET_MATCHMAKING:
      return State.setMatchMaking(newState, action as SetMatchMaking);
    case UserMatchActionId.SET_MEETINGFOUND:
      return State.setMeetingFound(newState, true);
    case UserMatchActionId.ACK_MEETINGFOUND:
      return State.setMeetingFound(newState, false);
    case UserMatchActionId.SET_SCHEDULED_MEETING_STARTING:
      return State.setScheduledMeetingStarting(newState, action as SetScheduledMeetingStarting);
    case UserMatchActionId.ACK_SCHEDULED_MEETING_STARTING:
      return State.setScheduledMeetingStarting(newState, { meetingId: '', type: '' });
    default:
      return state;
  }
};

class State {
  public static setRequestorLeftQueue(
    state: UserMatchState,
    action: SetRequestorLeftQueue,
  ): UserMatchState {
    return { ...state, requestorLeftQueue: action.leftQueue };
  }

  public static setMatchMaking(
    state: UserMatchState,
    action: SetMatchMaking,
  ): UserMatchState {
    return { ...state, inQueue: action.inQueue };
  }

  public static setMeetingFound(
    state: UserMatchState,
    meetingFound: boolean,
  ): UserMatchState {
    return { ...state, meetingFound };
  }

  public static setScheduledMeetingStarting(
    state: UserMatchState,
    scheduledMeetingStarting: SetScheduledMeetingStarting,
  ): UserMatchState {
    return { ...state, scheduledMeetingStarting: scheduledMeetingStarting.meetingId };
  }
}

export default userMatchReducer;
