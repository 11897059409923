import React, { Children, FunctionComponent, ReactElement, ReactNode } from 'react';
import { assertNotFragment } from '@pocketrn/rn-designsystem';
import styles from './Accordion.module.css';

interface AccordionProps {
  ignorePadding?: boolean;
  children?: ReactNode;
}

export const Accordion: FunctionComponent<AccordionProps> = (props): ReactElement => {
  return (
    <section className={`${styles.wrapper} ${props.ignorePadding ? styles.noPadding : ''}`}>
      {Children.map(props.children, child => {
        if (!React.isValidElement(child)) {
          return null;
        }

        assertNotFragment(child);

        return <div className={styles.item}>{React.cloneElement(child)}</div>;
      })}
    </section>
  );
};
