import React, { FunctionComponent, ReactElement } from 'react';
import {
  QuestionSquareIcon,
  IconButton,
  ChatIcon,
  NotificationIcon,
} from '@pocketrn/rn-designsystem';
import { WindowType } from '../../../entities/enums/WindowType';
import styles from './AppBar.module.css';

interface AppBarProps {
  windowType: WindowType;
  changeWindowType: (windowType: WindowType) => void;
  unreadNotificationsCount: number;
  unreadUserChatsCount: number;
}

export const AppBar: FunctionComponent<AppBarProps> = (props): ReactElement => {
  return (
    <nav className={styles.appBar}>
      <IconButton onClick={() => props.changeWindowType(WindowType.Notifications)}>
        <NotificationIcon
          color={props.windowType === WindowType.Notifications ? 'prnOrange' : undefined}
          badge={props.unreadNotificationsCount}
        />
      </IconButton>
      <IconButton onClick={() => props.changeWindowType(WindowType.Bot)}>
        <QuestionSquareIcon
          color={props.windowType === WindowType.Bot ? 'prnOrange' : undefined}
        />
      </IconButton>
      <IconButton onClick={() => props.changeWindowType(WindowType.Messages)}>
        <ChatIcon
          color={props.windowType === WindowType.Messages ? 'prnOrange' : undefined}
          badge={props.unreadUserChatsCount}
        />
      </IconButton>
    </nav>
  );
};
