import React, { FunctionComponent, ReactElement } from 'react';
import localePrimer from '../../utils/localePrimer';
import {
  AutoComplete,
  Button,
  ButtonGroup,
  Form,
  Markdown,
  Paragraph,
  TextField,
} from '@pocketrn/rn-designsystem';
import { logger } from '@pocketrn/client/dist/app-logger';
import { firebaseAuth } from '../../utils/firebase';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
} from 'firebase/auth';
import formValidator from '../../utils/fv';
import { sessionUserController } from '../../utils/userStateInit';

const locale = 'core.login.auth';
const logInLocale = `${locale}.login`;

interface Props {
  email: string;
}

export const ReauthEmailPasswordElement: FunctionComponent<Props> = (props): ReactElement => {
  const [ password, setPassword ] = React.useState('');
  const [ passwordError, setPasswordError ] = React.useState('');
  const [ loading, setLoading ] = React.useState(false);
  const [ unexpectedError, setUnexpectedError ] = React.useState('');
  const [ confirmationMessage, setConfirmationMessage ] = React.useState('');

  const logout = () => {
    setLoading(true);
    sessionUserController.logout();
  };

  const formFieldsAllValid = (): boolean => {
    let allValid = true;
    // @NOTE: to support legacy password requires, we have a very loose
    // password validation for logging in versus registering.
    const legacyPasswordLoginSupportOptions = {
      minLength: 6,
      requireLowercase: false,
      requireUppercase: false,
      requireNumber: false,
      requireSpecialCharacter: false,
    };
    const passwordValidation = formValidator.validatePassword(
      password,
      legacyPasswordLoginSupportOptions,
    );
    if (passwordValidation.error) {
      setPasswordError(passwordValidation.error);
      allValid = false;
    } else {
      setPasswordError('');
    }
    return allValid;
  };

  const handleUserCredential = async (userCredential: any) => {
    setLoading(true);
    window.location.reload();
  };

  const login = async () => {
    try {
      const credential = EmailAuthProvider.credential(props.email, password);
      if (!firebaseAuth.currentUser) {
        logger.logError('No current user when reauthenticating', { email: props.email });
        sessionUserController.logout();
        return;
      }
      const userCredential = await reauthenticateWithCredential(
        firebaseAuth.currentUser,
        credential,
      );
      handleUserCredential(userCredential);
    } catch (e) {
      setLoading(false);
      if ((e as any)?.code) {
        const { code } = e as any;
        // See: https://firebase.google.com/docs/auth/admin/errors
        if (code === 'auth/wrong-password') {
          setPasswordError(localePrimer.translate(locale, 'wrongPassword'));
        } else {
          setUnexpectedError(localePrimer.translate(locale, 'unexpectedError')(code));
        }
      } else {
        throw e;
      }
    }
  };

  const submitEmailPasswordForm = () => {
    setUnexpectedError('');
    setConfirmationMessage('');
    const allValid = formFieldsAllValid();
    if (!allValid) {
      return;
    }
    setLoading(true);
    login();
  };

  const changePassword = (v: string) => {
    setPassword(v);
    setPasswordError('');
  };

  const handleForgotPassword = () => {
    _sendPasswordResetEmail();
  };

  const _sendPasswordResetEmail = async () => {
    setLoading(true);
    try {
      await sendPasswordResetEmail(firebaseAuth, props.email);
      setLoading(false);
      setConfirmationMessage(localePrimer.translate(locale, 'passwordResetEmailSent'));
    } catch (e) {
      setLoading(false);
      if ((e as any)?.code) {
        const { code } = e as any;
        setUnexpectedError(localePrimer.translate(locale, 'unexpectedError')(code));
      } else {
        throw e;
      }
    }
  };

  return (
    <Form>
      <Paragraph
        align="center"
        text={props.email}
        testIdRoot="reauth-element-email"
        color="prnBlue"
        noBottomPadding
        ellipsis
      />
      <TextField
        label={localePrimer.translate(logInLocale, 'password')}
        type="password"
        autoFocus
        error={passwordError}
        onChange={changePassword}
        value={password}
        testIdRoot="auth-element-password"
        loading={loading}
        autoComplete={AutoComplete.CurrentPassword}
      />
      <ButtonGroup noBottomPadding direction="column">
        {(unexpectedError || confirmationMessage) &&
          <Markdown
            align="center"
            markdown={unexpectedError || confirmationMessage}
            color={unexpectedError ? 'error' : undefined}
            noBottomPadding
          />
        }
        <Button
          type="primary"
          onClick={submitEmailPasswordForm}
          loading={loading}
          testIdRoot="auth-element-submit"
          submitOnEnter
        >
          {localePrimer.translate(logInLocale, 'submit')}
        </Button>
        <Button
          type="secondary"
          onClick={logout}
          testIdRoot="auth-element-logout"
          loading={loading}
        >
          {localePrimer.translate('shared.logout')}
        </Button>
        <Button
          type="text"
          small
          loading={loading}
          testIdRoot="auth-element-forgotPassword"
          onClick={handleForgotPassword}
        >
          {localePrimer.translate(locale, 'forgotPassword')}
        </Button>
      </ButtonGroup>
    </Form>
  );
};
