import { SetFlags, Initialized, FeatureFlagActionId } from './actions';
import { FeatureJson, FeatureProviderJson, FeatureUserJson } from './Feature.json';
import { Action } from '@pocketrn/client/dist/entity-sdk';

export interface Flags {
  features: FeatureJson[];
  featureProviders: FeatureProviderJson[];
  featureUsers: FeatureUserJson[];
}

export interface FeatureFlagState {
  flags: Flags;
  initialized: boolean;
}

const initalState: FeatureFlagState = {
  flags: { features: [], featureProviders: [], featureUsers: [] },
  initialized: false,
};

const featureFlagReducer = (state = initalState, action: Action): FeatureFlagState => {
  const newState = state;
  switch (action.type) {
    case FeatureFlagActionId.SET_FLAGS:
      return FeatureFlag.setFlags(newState, action as SetFlags);
    case FeatureFlagActionId.INITIALIZED:
      return FeatureFlag.initialized(newState, action as Initialized);
    default:
      return state;
  }
};

class FeatureFlag {
  public static setFlags(
    state: FeatureFlagState,
    action: SetFlags,
  ): FeatureFlagState {
    return {
      ...state,
      flags: {
        ...state.flags,
        features: action.flags.features.map(f => f.json()),
        featureProviders: action.flags.featureProviders.map(f => f.json()),
        featureUsers: action.flags.featureUsers.map(f => f.json()),
      },
    };
  }

  public static initialized(
    state: FeatureFlagState,
    action: Initialized,
  ): FeatureFlagState {
    return {
      ...state,
      initialized: action.initialized,
    };
  }
}

export default featureFlagReducer;
