import { PageRouter } from '@pocketrn/rn-designsystem';

/**
 * @NOTE: when adding new routes, the route path is important to note:
 * 1. Anything that doesn't have subpaths is considered a root path and
 *    will not have a back button.
 * 2. Anything that has subpaths will have a back button that uses the path fragments
 *    as a breadcrumb for the back button.
 */
export enum HelpRoute {
  Main = '/help',
  Tutorials = '/help/tutorials',
  ContactSupport = '/help/contact-support',
}

export enum RelativeHelpRoute {
  Tutorials = 'tutorials',
  ContactSupport = 'contact-support',
}

export default class HelpRouter extends PageRouter {
  constructor() {
    super('/');
  }
}
