import { createSelector } from '@reduxjs/toolkit';
import { TextFactory } from '../../../entities/factories/Text.factory';
import { TextJson } from '../../../entities/interfaces/Text.json';
import { ChatbotState } from './reducer';

const getChatbotChat = (state: { chatbot: ChatbotState }) => state.chatbot.chatbotChat;

export const selectChatbotChat = createSelector(
  [getChatbotChat],
  (chatbotChat: TextJson[]) => {
    return chatbotChat.map(chat => TextFactory.build(chat));
  },
);

export const selectUnreadNotificationsCount = (
  state: { chatbot: ChatbotState },
): number => state.chatbot.unreadNotificationsCount;

export const selectUnreadUserChatsCount = (
  state: { chatbot: ChatbotState },
): number => state.chatbot.unreadUserChatsCount;

export const selectMessagesCount = createSelector(
  [ selectUnreadNotificationsCount, selectUnreadUserChatsCount ],
  (unreadNotificationsCount: number, unreadUserChatsCount: number) => {
    return unreadNotificationsCount + unreadUserChatsCount;
  },
);

export const selectChatDrawerIsOpen = (
  state: { chatbot: ChatbotState },
): boolean => state.chatbot.drawerIsOpen;
