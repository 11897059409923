import { LocalePrimer } from '@pocketrn/localizer';
import React, { FunctionComponent, ReactElement } from 'react';
import { Choice } from '../../../entities/Choice';
import { Text } from '../../../entities/Text';
import styles from './ChatBot.module.css';
import { ChatChoice } from './ChatChoice';
import { ChatText } from './ChatText';

interface ChatBotProps {
  chat: Text[];
  choices: Choice[];
  scrollToBottomTarget: React.FunctionComponent;
  close: () => void;
  localePrimer: LocalePrimer;
}

export const ChatBot: FunctionComponent<ChatBotProps> = (props): ReactElement => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        {props.chat && props.chat.map((text, index) => {
          return <ChatText text={text} key={index} localePrimer={props.localePrimer} />;
        })}
        {props.choices.map((choice, index) => {
          return <ChatChoice choice={choice} close={props.close} key={index} />;
        })}
        {props.scrollToBottomTarget({})}
      </div>
    </div>
  );
};
