import React, { FunctionComponent, ReactElement } from 'react';
import styles from './UserChats.module.css';
import { Spinner } from '@pocketrn/rn-designsystem';
import {
  UserChats as UserChatsEntity,
  ChatOverview as ChatOverviewEntity,
} from '@pocketrn/entities/dist/chatbot';
import { ChatOverview } from './ChatOverview';
import { LocalePrimer } from '@pocketrn/localizer';

interface UserChatsProps {
  userChats: UserChatsEntity | undefined;
  onOpenUserChat: (chatOverview: ChatOverviewEntity) => void;
  loading?: boolean;
  localePrimer: LocalePrimer;
}

export const UserChats: FunctionComponent<UserChatsProps> = (props): ReactElement => {
  const getChatOverviews = (): ChatOverviewEntity[] => {
    if (!props.userChats) {
      return [];
    }
    const keys = Object.keys(props.userChats.chats);
    const overviews = [];
    for (const key of keys) {
      overviews.push(props.userChats.chats[key]);
    }
    overviews.sort((a, b) => b.latestMessage.sentAt < a.latestMessage.sentAt ? -1 : 1);
    return overviews;
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.userChats}>
        {props.userChats &&
          getChatOverviews().map((chatOverview, index) => {
            return (
              <div className={styles.chatOverview} key={index}>
                <ChatOverview
                  chatOverview={chatOverview}
                  onOpen={() => props.onOpenUserChat(chatOverview)}
                  localePrimer={props.localePrimer}
                />
              </div>
            );
          })
        }
        {props.loading && <Spinner />}
      </div>
    </section>
  );
};
