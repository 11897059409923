import {
  Provider,
  ProviderFactory,
  ProviderJson,
} from '@pocketrn/entities/dist/core';
import { createSelector } from '@reduxjs/toolkit';
import { TechnicianState } from './reducer';

const getProviderListEntities = (
  state: { technician: TechnicianState },
) => state.technician.provider.listEntities;
const getProviderMapEntities = (
  state: { technician: TechnicianState },
) => state.technician.provider.mapEntities;

export const selectProviderList = createSelector(
  [getProviderListEntities],
  (providerList: ProviderJson[]) => {
    return providerList.map(provider => ProviderFactory.build(provider));
  },
);

export const selectProviderMap = createSelector(
  [getProviderMapEntities],
  (providerMap: Record<string, ProviderJson>) => {
    const keys = Object.keys(providerMap);
    const map: Record<string, Provider> = {};
    for (const key of keys) {
      map[key] = ProviderFactory.build(providerMap[key]);
    }
    return map;
  },
);

export const selectProviderLoading = (
  state: { technician: TechnicianState },
): boolean => state.technician.provider.loading;

