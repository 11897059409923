import { ActionLinkEventType, ActionLinkType, StripeProductKey } from '@pocketrn/entities/dist/core';
import { visitConsentContent, visitConsentDate } from './acknowledgments/visitConsent/en';
import { privacyPracticesContent, privacyPracticesDate } from './acknowledgments/privacyPractices/en';
import { termsOfServiceContent, termsOfServiceDate } from './acknowledgments/termsOfService/en';
import { caregiverAuthorizationContent, caregiverAuthorizationDate } from './acknowledgments/caregiverAuthorization/en';
import { genderIdentityInformation } from './information/genderIdentity/en';

export const core = {
  actionLink: {
    signConsents: {
      verifyAccountType: {
        overviewTitle: 'Who are you in relation to this patient?',
        overviewDescription: '',
        innerPageTitle: 'Signer Type',
        patient: 'I’m the Patient',
        caregiver: 'I’m a Caregiver, Loved One, or Power of Attorney',
      },
    },
    rescheduleMeeting: {
      finished: {
        overviewTitle: 'Success! Thank you for scheduling this meeting.',
        overviewDescription: 'You can come back to this link anytime prior to the meeting to review or update it.',
        innerPageTitle: 'Success',
      },
      reschedule: {
        overviewTitle: 'It looks like this meeting has already been scheduled.',
        overviewDescription: 'If you were invited to participate, you’ll receive a text or email notification with a link to join at the time of the meeting.',
        innerPageTitle: 'Task Complete',
      },
    },
    verifyCaller: {
      thatsCorrectBtn: 'That’s Correct, Continue',
      innerPageTitle: 'Welcome',
      wrongCallerBtn: 'I was assigned by accident',
      overviewTitle: 'You’re assigned to complete the task for this patient.',
      overviewDescription: '',
      overviewHeaderAction: {
        text: 'What does this mean?',
        markdown: (wrongCallerBtnText: string): string => `
          #### What does it mean to complete a task?

          A user who helps manage this patient’s care has entered your contact information
          as someone who can help complete a task on PocketRN for this patient—either because
          you are the patient, you act on the patient’s behalf, or you are a caregiver or loved
          one who assists in the patient’s care.

          If you believe someone else is better suited to this role, or if you were assigned by accident,
          please select **"${wrongCallerBtnText}"**.
        `,
      },
    },
    wrongCaller: {
      overviewTitle: 'Not a problem! Can you help us fix the mistake?',
      innerPageTitle: 'Reassign Task',
      overviewDescription: `
        If you know who should be the owner of this task,
        please enter their email address or phone number below to reassign the task;
        otherwise, you can cancel this task to notify the original sender.
      `,
    },
    welcome: {
      register: {
        overviewTitle: 'Welcome to PocketRN!',
        overviewDescription: `
          PocketRN wants to put a virtual nurse in your pocket!
  
          We’ll walk you through the steps to get you set up.
        `,
        overviewDescriptionProvider: (name: string): string => `
          PocketRN has teamed up with ${name} to put a virtual nurse in your pocket!
          We’ll walk you through the steps to get you set up.
        `,
      },
      signConsents: {
        overviewTitle: 'Welcome to PocketRN!',
        overviewDescription: `
          Before we can activate your services on PocketRN, we need you to help get a few consents signed.
  
          We’ll walk you through the steps to sign as the patient or on behalf of the patient.
        `,
        overviewDescriptionProvider: (name: string): string => `
          PocketRN has teamed up with ${name} to put a virtual nurse in your pocket!
          Before we can activate your services on PocketRN, we need you to help get a few consents signed.
  
          We’ll walk you through the steps to sign as the patient or on behalf of the patient.
        `,
      },
      scheduleMeeting: {
        overviewTitle: 'Help Schedule a Meeting with a Nurse',
        overviewDescription: `
          You’ve been invited to schedule a virtual visit with a PocketRN nurse.
          We’ll guide you through the process, step by step.
        `,
        overviewDescriptionProvider: (name: string): string => `
          You’ve been invited to schedule a virtual visit with a PocketRN nurse in partnership with ${name}.
          We’ll guide you through the process, step by step.
        `,
      },
      innerPageTitle: 'Welcome',
      getStarted: 'Get Started',
      whatIsPRN: {
        btn: 'What is PocketRN?',
        markdown: `
        PocketRN is here to make healthcare more accessible for you, right from the comfort of your home. It's a safe, secure, and private online service where you can connect with specialized nurses over video or a phone call whenever you need them. Here’s how it simplifies your healthcare experience:

        * **Connect Easily:** Use PocketRN to talk to experienced nurses who specialize in the care you need.
        * **Always Available:** Get unlimited access to our nursing services. Whether you have an appointment or need sudden assistance, we're here for you.  No question is too big or too small and our team is trained to escalate to your partnering organization or medical professionals as needed.
        * **Simple to Use:** We'll help you set up everything for you and your loved ones to access PocketRN with easy instructions.
        * **We're Here to Help:** If you need extra guidance, just call or text us at [(872) 233-4490](tel:+1872-233-4490). Our support team is ready 24/7 to assist you through any part of the process.
        
        With PocketRN, expert nursing care is always in your pocket, ensuring you and your loved ones get the support you need, whenever you need it.
        
        `,
      },
    },
    error: {
      notFound: {
        overviewTitle: 'Bad Link',
        overviewDescription: 'It looks like you have clicked on a bad link. Please make sure to copy the entire URL and check the domain name.',
        innerPageTitle: 'Error',
        primaryBtn: 'Go to Homepage',
      },
      signedIn: {
        overviewTitle: 'Cannot Be Signed In While Viewing a Task',
        overviewDescription: 'It looks like you are signed into PocketRN in the same browser that you have an assigned task opened. In order to authenticate the task properly, we have to temporarily suspend your signed-in session. When the task is complete, please close that browser tab to continue.',
        innerPageTitle: 'Error',
        primaryBtn: 'Try Again',
      },
      unknown: {
        overviewTitle: 'Oops!',
        overviewDescription: 'Something went wrong. Please try again.',
        innerPageTitle: 'Error',
        primaryBtn: 'Try Again',
      },
      completed: {
        overviewTitle: 'Task Complete!',
        overviewDescription: 'You’re all set! The actions we needed you to take have been completed.',
        innerPageTitle: 'Success',
        primaryBtn: 'Go to Homepage',
      },
      completedByUser: {
        overviewTitle: 'Task Complete!',
        overviewDescription: 'You’re all set! Thank you for completing this task.',
        innerPageTitle: 'Success',
        primaryBtn: 'Go to Homepage',
      },
      finishRegistration: {
        overviewTitle: 'Task Complete!',
        overviewDescription: 'You’re all set! Thank you for completing this task.',
        innerPageTitle: 'Success',
        primaryBtn: 'Finish Securing Account',
        infoCard: `
          #### How do I meet with a nurse?

          This task was solely for verifying account details.
          If a meeting with a nurse is necessary at this time,
          you or someone else should have received a separate link to schedule it.
          
          Alternatively, you can visit [pocketrn.com](https://app.pocketrn.com)
          anytime to secure your account and access nurse scheduling along with
          the many other benefits of our platform.
        `,
      },
      canceledByUser: {
        overviewTitle: 'Task Canceled',
        overviewDescription: 'We have notified the original sender with your cancelation reason.  You’re no longer responsible for this task and no further action is needed.',
        innerPageTitle: 'Canceled',
        primaryBtn: 'Go to Homepage',
      },
      canceled: {
        overviewTitle: 'Task Canceled',
        overviewDescription: `
          The action you were trying to take was canceled and no further action
          is needed.

          Likely, this task was sent to another person to fulfill.
        `,
        innerPageTitle: 'Canceled',
        primaryBtn: 'Go to Homepage',
      },
      expiredActionLink: {
        overviewTitle: 'This Link Has Expired',
        overviewDescription: 'The link you clicked on has expired and is no longer valid.',
        innerPageTitle: 'Expired Link',
        primaryBtn: 'Go to Homepage',
      },
    },
  },
  assignments: {
    overviewTitle: 'Assignments',
    overviewDescription: `
      Manage the tasks that you have assigned or are assigned to you.
      
      Tasks can be created in various places in the app, such as when assigning someone to [schedule a meeting](/meetings/schedule).
    `,
    innerPageTitle: 'Assignments',
    tabs: {
      appliedToUid: 'Assigned to Me',
      callerUid: 'Assigned by Me',
    },
    noAssignments: {
      appliedToUid: 'No tasks have been assigned to you.',
      callerUid: 'You have not assigned any tasks.',
    },
    assignment: {
      title: {
        [ActionLinkType.SignConsents]: 'Review & Sign Terms',
        [ActionLinkType.ScheduleMeeting]: 'Schedule a Meeting',
        [ActionLinkType.Register]: 'Verify Account Info',
      },
      showFullDates: 'Show Date & Time',
      showOnlyDates: 'Show Date Only',
      tag: {
        completed: 'Completed',
        expired: 'Expired',
        canceled: 'Canceled',
        incomplete: 'Incomplete',
        reassigned: 'Reassigned',
      },
      history: {
        expired: 'The link to this task has expired.  Please reassign the task to renew it or cancel the task if it is no longer needed.',
        oneNotify: {
          created: (notify: string): string => `Link sent to $[${notify}]$.`,
          [ActionLinkEventType.Accessed]: (notify: string): string => `Link was accessed by $[${notify}]$.`,
          [ActionLinkEventType.Completed]: (notify: string): string => `Link was completed by $[${notify}]$.`,
          [ActionLinkEventType.Canceled]: (notify: string): string => `Link was canceled by $[${notify}]$.`,
          'reassigned': (notify: string): string => `Link was reassigned by $[${notify}]$.  The new assignment was created further up this list with more details.`,
          [ActionLinkEventType.MetadataModified]: (notify: string): string => `Link metadata updated by $[${notify}]$.`,
        },
        twoNotify: {
          created: (notifyA: string, notifyB: string): string => `Link sent to $[${notifyA}]$ and $[${notifyB}]$.`,
        },
        manyNotify: {
          created: (lastNotify: string, ...notifies: string[]): string => `Link sent to $[${notifies.join(', ')}]$, and $[${lastNotify}]$.`,
        },
        caller: {
          [ActionLinkEventType.Completed]: 'Link was completed by assignee.',
          [ActionLinkEventType.Canceled]: 'Link was canceled by assignee.',
          'reassigned': 'Link was reassigned by assignee.  The new assignment was created further up this list with more details.',
          [ActionLinkEventType.MetadataModified]: 'Link metadata updated by assignee.',
          [ActionLinkEventType.Notified]: 'Sent a reminder to complete the assignment.',
        },
      },
      action: {
        cancel: 'Cancel or Reassign',
      },
    },
  },
  permissions: {
    overviewTitle: 'Caregiver Permissions',
    innerPageTitle: 'Permissions',
    overviewDescription: '',
    cannotEditSelf: {
      title: 'You cannot edit this permission when you are this caregiver.',
      markdown: `
        If you were to revoke this permission, you would no longer be able to manage aspects of the patients account.

        If you need to remove yourself from this permission, have the patient or a different caregiver of the patient do so from their account.
      `,
    },
    editAccessUnmanaged: 'By checking each box below, you agree to give this caregiver the selected permissions. You may select as many or few as you would like.',
    editAccessManaged: `
      #### The terms below are framed from the perspective of the patient.
      
      By checking each box below, you agree to give this caregiver the selected permissions for the patient. You should only check a box if you are the patient, if the patient verbally agrees to grant that permission, or if you hold legal and medical Power of Attorney for the patient.
    `,
    yourPermissions: 'These are the permissions granted to you to be performed on the patient’s behalf.  The box is checked if you have been granted that permission.',
    agreeAndSave: 'Agree & Save',
    ownerTitle: 'Permissions',
    permissions: {
      powerOfAttorney: 'I acknowledge the caregiver has been granted **Power of Attorney** for my medical and legal decisions. I authorize the caregiver **full access to my account**.',
      fullAccess: 'I authorize the caregiver **full access to my account**, including, but not limited to, the ability to view and change my medical, personal, and payment information.  I understand that I should only give this permission to those I fully trust to manage my care and account.',
      startMeetings: 'I authorize the caregiver to **start meetings on my behalf**.  I understand that I may not be present during the meeting and that my medical information and care will be discussed.  This option is not available in the state of California. By selecting this option, I agree that I am not a resident of California.',
      alwaysNotifyOfMeetings: 'I authorize the caregiver to be **notified of my meetings** with the ability to join that meeting.',
    },
  },
  acknowledgments: {
    view: {
      overviewTitle: 'Acknowledgments',
      overviewDescription: 'Review the acknowledged terms and their signers.',
    },
    needToSign: {
      overviewTitle: 'Missing Acknowledgments',
      overviewDescription: 'Some acknowledgments have not been reviewed and signed by the patient or their Power of Attorney.',
      signAcks: 'Sign Now',
      proceedToPatient: 'Proceed to Patient',
    },
    innerPageTitle: 'Acknowledgments',
    signature: {
      acceptedByLabel: 'Accepted by',
      signedByLabel: 'Signed by',
      acceptBtn: 'Accept Terms',
      firstName: 'Legal First Name',
      lastName: 'Legal Last Name',
    },
    overline: 'Acknowledgment',
    terms: {
      visitConsent: {
        title: 'Informed Consent to Telehealth and Virtual Visits',
        content: visitConsentContent,
        updatedAt: visitConsentDate,
      },
      privacyPractices: {
        title: 'Acknowledgment of Receipt of Notice of Privacy Practices',
        content: privacyPracticesContent,
        updatedAt: privacyPracticesDate,
      },
      termsOfService: {
        title: 'Terms of Service',
        content: termsOfServiceContent,
        updatedAt: termsOfServiceDate,
      },
      caregiverAuthorization: {
        title: 'Acknowledgment of Caregiver Authorization',
        content: caregiverAuthorizationContent,
        updatedAt: caregiverAuthorizationDate,
      },
    },
    outdatedVersion: 'There is a new version',
    expired: 'Previous consent has expired',
    optional: 'Optional',
    getConsentsSinged: 'Get Consents Signed',
    sendActionsLinksGroup: {
      title: 'Select recipient(s)',
    },
    linkSentSuccess: {
      overviewTitle: 'A “Consent Signing” link has been sent out.',
      overviewDescription: 'You can view the progress of this request in your profile page.',
    },
    forPRN: 'For PocketRN',
    forProvider: (name: string): string => `For $[${name}]$`,
  },
  sendLink: {
    email: {
      self: 'Your Email Address',
      sendOut: 'Send to Email Address',
    },
    phone: {
      self: 'Your Phone Number',
      sendOut: 'Text to Phone Number',
    },
    sendToEmailOrPhone: 'Send to an email or phone number',
    andOr: 'and/or',
    optedOut: {
      markdown: (phone: string): string => `
        #### This phone number has opted out of messages from PocketRN.

        In order for us to text this phone number, the owner will need to send “start” to ${phone}.
      `,
      recheck: 'Re-Check this Number',
    },
    cancel: 'Cancel Task',
    reassign: 'Reassign Task',
    cancelPrompt: {
      title: 'Are you sure you want to cancel the task?',
      description: 'The original sender will be reassigned to the task and may not have the correct contact details.  If you are certain you do not know the contact information for the correct owner of this task, please provide a brief explanation that we will provide to the original sender as to why you are rejecting it.',
      reason: 'Reason for Cancellation',
    },
  },
  personalInfo: {
    accountInfo: {
      title: 'Account Info',
      helper: {
        title: 'Who will be able to see this?',
        markdown: ` 
          #### Email
          * **My Caregivers:** If you are a patient, your caregivers will be able to see your email.
          * **My Patients:** If you are a caregiver, your patients will be able to see your email.
          * **Nurses:** Nurses who are assigned to your care will be able to see your email.
          * **PocketRN Staff:** The PocketRN Support team will be able to see your email.
          * **Everyone else:** No one else will be able to see this information.
          
          #### Password
          No one will be able to see your password.  Never share your password with anyone, including
          PocketRN staff and nurses. If you need a family member or caregiver to access PocketRN,
          you can invite them to join as a caregiver with their own account credentials at no cost.
        `,
      },
    },
    basicInfo: {
      title: 'Basic Info',
      helper: {
        title: 'Who will be able to see this?',
        markdown: ` 
          * **My Caregivers:** If you are a patient, you may adjust this permission for each of your caregivers.
          * **My Patients:** If you are a caregiver, your patients will be able to see your name.
          * **Nurses:** Nurses who are assigned to your care will be able to see all of this information.
          * **PocketRN Staff:** The PocketRN Support team will be able to see all of this information.
          * **Everyone else:** No one else will be able to see this information.
        `,
      },
    },
    gender: {
      title: 'Gender',
      helper: {
        title: 'Who will be able to see this?',
        markdown: `
        * **My Caregivers:** If you are a patient, you may adjust this permission for each of your caregivers.
        * **My Patients:** If you are a caregiver, your patients will not be able to see this information.
        * **Nurses:** Nurses who are assigned to your care will be able to see this information.
        * **PocketRN Staff:** The PocketRN Support team will be able to see this information.
        * **Everyone else:** No one else will be able to see this information.
        `,
      },
    },
    genderIdentity: {
      preferNotToAnswer: 'Prefer not to answer',
      info: genderIdentityInformation,
    },
    email: {
      info: `
        #### Are you sure you want to change the email address?

        When you change the email address, you'll no longer be able to sign in
        with the old email address.
        The next time you refresh the page or log in using this new email address,
        it may ask you to enter in your password and verify the new email address.

        If you attempt to access this account using the old email address, it
        will be as if you are a new user.  If you'd rather not proceed, go back and revert the email address to
        the original one.  If you have any questions or concerns, please contact
        [support@pocketrn.com](mailto:support@pocketrn.com).
      `,
    },
    patient: {
      firstName: 'Patient First Name',
      lastName: 'Patient Last Name',
      preferredName: 'Patient Name They Go By',
      dateOfBirth: 'Patient Date of Birth',
      stateOfResidence: 'Patient Current Location',
      genderIdentity: 'Patient Gender',
      email: 'Patient Email Address',
    },
    caregiver: {
      firstName: 'Caregiver First Name',
      lastName: 'Caregiver Last Name',
      preferredName: 'Caregiver Name They Go By',
      dateOfBirth: 'Caregiver Date of Birth',
      stateOfResidence: 'Caregiver Current Location',
      genderIdentity: 'Caregiver Gender',
    },
    self: {
      firstName: 'Your First Name',
      lastName: 'Your Last Name',
      preferredName: 'Name You Go By',
      dateOfBirth: 'Your Date of Birth',
      stateOfResidence: 'Your Current Location',
      genderIdentity: 'Your Gender',
      email: 'Your Email Address',
    },
    error: {
      stateOfResidenceEmpty: 'Please provide your current location.',
      tooYoung: 'You must be at least 18 years old to use PocketRN.',
      stateUnavailable: 'You must be located in California to use PocketRN.',
    },
  },
  nameChangePolicy: {
    markdown: {
      self: `
        #### Name Change Policy

        Because you changed your legal name, you will need to re-sign our terms to ensure our records are legally accurate.
      `,
      patient: `
        #### Name Change Policy

        Because you changed your patient's legal name, they or their power of attorney will need to re-sign our terms to ensure our records are legally accurate.
      `,
      caregiver: `
        #### Name Change Policy

        Because you changed the caregiver's legal name, they will need to re-sign our terms to ensure our records are legally accurate.
      `,
    },
  },
  contactProfile: {
    title: {
      root: {
        patient: 'Patient Profile',
        caregiver: 'Caregiver Profile',
        nurse: 'Nurse Profile',
      },
      emergencyInformation: 'Emergency Information',
    },
    emergencyInformation: {
      nav: 'Emergency Information',
      warningModal: {
        markdown: `
          When you view the emergency contact information for this patient,
          the PocketRN Support Team will be alerted and join your meeting
          to assist you.  You should not wait for them to call emergency services.

          Do you wish to proceed?
        `,
      },
      address: {
        title: 'Patient Home Address:',
        value: (
          street1: string,
          street2: string | undefined,
          city: string,
          state: string,
          zip: string,
          country?: string,
          specialInstructions?: string,
        ): string => `
          $[${street1}]$<br>${street2 ? `$[${street2}]$<br>` : ''}
          $[${city}]$, $[${state}]$ $[${zip}]$
          ${country ? `<br>$[${country}]$` : ''}
          ${specialInstructions ? `<br>$[${specialInstructions}]$` : ''}
        `,
      },
      instructions: 'Special Instructions for Emergency Responders:',
      missing: 'None provided',
    },
  },
  phone: {
    setPrimaryPhoneBtn: 'Set as Primary Number',
    proceedModalText: `
      #### In order to make this your primary number, we need to verify it belongs to you.

      Hit “Proceed” and we'll send you a code via text message. Standard messaging rates may apply.
    `,
    cannotUnsetPrimaryModalText: `
      #### Cannot Unset Primary Number

      Once you have verified your phone number, it becomes your primary number and cannot be unset.
      If you wish, you may delete this phone or add a new phone to be your primary.
    `,
    group: {
      title: { self: 'Phones', managed: 'Patient’s Phones' },
      add: 'Add Phone',
      noPhonesAvailable: 'There are no phone numbers available.',
    },
    editPhone: 'Edit Phone',
    addPhone: 'Add Phone',
    number: { label: 'Phone Number' },
    priority: {
      label: 'Priority',
      value: (index: number): string => `Priority: ${index + 1}`,
    },
    type: {
      label: 'Phone Type',
      item: {
        mobile: 'Mobile',
        work: 'Work',
        home: 'Home',
      },
    },
    notificationPreference: {
      label: 'Notification Preference',
      item: {
        call: 'Call',
        text: 'Text',
        both: 'Call & Text',
      },
    },
    status: {
      label: 'Contact Status',
      helper: {
        title: 'What does this mean?',
        markdown: `
          * **Primary:** This phone has been verified by the user and cannot be altered.
          * **Invalid:** This phone number has been called or texted and determined to be a wrong number.
          * **Verified:** This phone number has been called or texted and determined to be a good source for contacting this user.
          * **Unverified:** This phone number has not been called or texted yet.
        `,
      },
      item: {
        verified: 'Verified',
        unverified: 'Unverified',
        invalid: 'Invalid',
        primary: 'Primary',
      },
    },
    optOut: {
      cannotOptInFromAppWithPhone: (phone: string): string => `This phone was opted out via a text message. To opt in, you will need to send "start" to $[${phone}]$.`,
      cannotOptInFromAppNoPhone: 'This phone was opted out via a text message. To opt in, you will need to send "start" to the number to which you originally sent the opt out request.',
    },
    primaryNumber: {
      title: 'Primary Number',
      label: 'Is this your primary number?',
      helper: {
        title: 'What does this mean?',
        markdown: `
          When you link your primary number, we send you text/SMS notifications about your account, upcoming meetings, and other important reminders.  You can change your primary number at any time.
        `,
      },
      notAllowed: 'You cannot set a primary number for anyone other than yourself.',
    },
    hiddenPhoneNumber: 'Phone Number',
    error: { phoneAlreadyExists: 'This phone number is already associated with your account.' },
  },
  dsExtra: {
    button: {
      update: 'Update',
      submit: 'Submit',
      countdown: { seconds: (seconds: number): string => `${seconds} s` },
    },
  },
  statusBar: {
    searching: 'Looking for a match:',
    matchFound: 'Match found:',
    inProgress: 'Meeting in progress:',
    ended: 'Meeting ended',
    meetingsAreInProgress: 'Meetings in progress',
  },
  accountSwitcher: {
    label: 'Active Account',
    activeAccount: 'Active Account',
    loading: 'Loading Accounts...',
    selectAccount: 'Select Account',
    joinDefaultCaregiver: 'Join the PocketRN General Clinic',
    joinDefaultPatient: 'Join the PocketRN General Clinic',
    inQueue: 'Cannot change while matching.',
    inMeeting: 'Cannot change while in a meeting.',
    accountType: {
      patient: 'Patient',
      nurse: 'Nurse',
      technician: 'Technician',
      caregiver: 'Caregiver',
      userAdmin: 'User Admin',
      permissionsAdmin: 'Permissions Admin',
    },
    selectClinic: 'Select Clinic',
    addAnotherAccount: 'Add Another Account',
  },
  inactiveInstance: {
    overviewTitle: 'Multiple PocketRN Apps Open',
    overviewDescription: 'You have opened another PocketRN instance, so we paused this one. You can either resume this as the active instance or close this window.',
    makeActive: 'Resume',
  },
  zoomLink: {
    openZoomApp: 'Open Zoom App',
    zoomId: 'Zoom Meeting Id',
    zoomPassword: 'Zoom Meeting Password',
    zoomUrl: 'Zoom Meeting Url',
  },
  main: {
    logout: 'Logout',
    logoutLong: 'Log out of PocketRN',
    profile: 'Manage Profile',
    privacyPolicy: 'Privacy Policy',
    terms: 'Terms',
    refresh: 'Refresh Page',
    select: 'Select',
    selected: 'Selected',
    caregivers: 'Caregivers',
    patients: 'Patients',
    subscription: 'Subscription',
  },
  mainAppBar: {
    help: 'Help',
    main: 'Home',
    profile: 'Profile',
    matching: 'Matching',
    inMeeting: 'Join Meeting',
  },
  login: {
    goToMarketingSite: 'Learn More',
    invite: {
      overviewTitle: 'Welcome to PocketRN!',
      overviewDescriptionPatient: (name: string, institution: string): string => `
        You have been invited to join as a patient on PocketRN by $[${name}]$ from $[${institution}]$.
      `,
      overviewDescriptionCaregiver: (name: string, institution: string): string => `
        You have been invited to join as a caregiver on PocketRN by $[${name}]$ from $[${institution}]$.
      `,
      overviewDescriptionNurse: (name: string, institution: string): string => `
        You have been invited to join as a nurse on PocketRN by $[${name}]$ from $[${institution}]$.
      `,
      overviewDescriptionOther: (name: string): string => `
        You have been invited to join PocketRN by $[${name}]$.
      `,
      loginCard: {
        cardTitle: 'Accept Invitation',
        cardDescription: 'To accept the invitation, log in with the same email address from which you received the invite.',
      },
      signupCard: {
        cardTitle: 'Accept Invitation',
        cardDescription: 'To accept the invitation, sign up with the same email address from which you received the invite.',
      },
    },
    login: {
      loginCard: {
        cardTitle: 'Log in to your account',
        cardDescription: 'Welcome back!',
      },
      signupCard: {
        cardTitle: 'Sign up for PocketRN',
        cardDescription: 'PocketRN is available **under select partnerships**. Please click the button below to register your new account.',
      },
      reauthCard: {
        cardTitle: 'Please sign in again',
        cardDescription: 'We need to verify your identiy. You must re-enter your password to continue.',
      },
    },
    disclaimer: 'By clicking Sign Up/Log In, you agree to our [Terms](https://www.pocketrn.com/policies/terms-of-service) and [Privacy Policy](https://www.pocketrn.com/policies/privacy).',
    tabTitle: {
      login: 'Log In',
      signup: 'Sign Up',
    },
    auth: {
      login: {
        google: 'Log in with Google',
        email: 'Log in with email',
        submit: 'Log In',
        password: 'Password',
      },
      signup: {
        google: 'Sign up with Google or Gmail',
        email: 'Sign up with email',
        submit: 'Sign Up',
        password: 'Create a password',
      },
      email: 'Email Address',
      forgotPassword: 'Forgot Password?',
      loginWithGoogle: 'The email you entered is a Google account.',
      emailAlreadyInUse: 'There is an existing account with this email.  Click "Log In Instead" below to log into this account.',
      emailNotFound: 'There is no account associated with this email.  Please check the email was entered correctly or [sign up](/signup) for a new account.',
      invalidEmail: 'The email you entered is invalid.  Please check your spelling and try again.',
      passwordResetEmailSent: 'We’ve sent you an email with instructions to reset your password.',
      submitAsLogin: 'Log In Instead',
      wrongPassword: 'The password you entered is incorrect.',
      unexpectedError: (error: string): string => `
        An unexpected error occurred:<br>
        **$[${error}]$**<br>
        Please contact [support@pocketrn.com](mailto:support@pocketrn.com).
      `,
    },
  },
  register: {
    verifyOwner: {
      managePatient: {
        overviewTitle: 'We were told that you will be the owner of this patient’s account.',
        overviewDescription: '',
        innerPageTitle: 'Account Owner',
        overviewHeaderAction: {
          text: 'What does this mean?',
          markdown: (otherOwnerBtnText: string): string => `
            #### What does it mean to manage an account?
  
            An owner of an account is the person who will have the dedicated account on PocketRN
            and will be responsible for ensuring the account is registered and consents are signed.
            The account owner will manage account notifications, connection requests, and all other
            operations.  An account owner will need an email address or a phone that can receive texts
            and access the internet.
  
            Not all patients, caregivers, or loved ones want to manage these aspects.  If you believe
            someone else is better suited to this role, please select **"${otherOwnerBtnText}"**.
          `,
        },
        editPatientInfo: (otherOwnerBtnText: string): string => `
          You'll have an opportunity at a later step to edit the patient's information.

          For now, select **"${otherOwnerBtnText}"**.
        `,
        otherOwner: 'The owner should be someone else',
        thatsCorrect: 'That’s Correct, Continue',
      },
      self: {
        singleOverviewTitle: 'You’ve been invited to be a caregiver for this patient.',
        pluralOverviewTitle: 'You’ve been invited to be a caregiver for these patients.',
        overviewDescription: '',
        innerPageTitle: 'Your Role',
        overviewHeaderAction: {
          text: 'What does this mean?',
          markdown: (otherOwner: string): string => `
            #### What does it mean to be a caregiver on PocketRN?
  
            You have been granted access to help facilitate a patient’s care on the PocketRN platform.
            Depending on your permissions, you may be able to schedule or be invited to meetings,
            view and edit the patient’s information, and more.
  
            If you believe you were invited by accident, please select **"${otherOwner}"**.
          `,
        },
        editPatientInfo: (thatsCorrect: string): string => `
          After the registration process, you’ll have an opportunity to view more information
          about this patient.

          For now, select **"${thatsCorrect}"**.
        `,
        otherOwner: 'I was invited by accident',
        thatsCorrect: 'That’s Correct, Continue',
      },
    },
    noOwner: {
      overviewTitle: 'Not a problem! Can you help us fix the mistake?',
      overviewDescription: `
        If you know who should be the owner of this account,
        please enter their email address or phone number below to reassign the task;
        otherwise, you can cancel this task to notify the original sender.
      `,
      innerPageTitle: 'Reassign Owner',
    },
    verifyAccountType: {
      patient: {
        wrong: {
          overviewTitle: 'Not a problem! Let’s fix the mistake.',
          overviewDescription: `
            All the information we were given was about the patient.
            What we’re going to do is have you create two accounts: one for you
            as the caregiver and one for the patient.
            
            #### Account Details
            We’ll switch the account contact information (email and/or phone) to
            be in your name as you’ll be the account owner, but we are going to keep
            all other details about the patient as-is.
            
            #### Step-by-Step
            Don’t worry, we’ll guide you through this transfer step-by-step and you
            can make any adjustments you need along the way.  When ready, proceed
            and we’ll redirect you to a new link to finish your caregiver account setup.
          `,
        },
        overviewTitle: 'We were told you are the patient. Is that true?',
        overviewDescription: '',
        accountType: {
          patient: 'Yes, I’m the Patient',
          caregiver: 'No, I’m a Caregiver',
        },
      },
      caregiver: {
        wrong: {
          overviewTitle: 'Not a problem! Let’s fix the mistake.',
          overviewDescription: `
            We were given information about a caregiver and a patient. We’re going to
            assume that the patient information we have is correctly about you,
            but that the contact information is wrongly associated with the caregiver.
            
            #### What We’re Going To Do
            We’ll switch the account contact information (email and/or phone) to
            be in your name, but we are going to keep everything else as-is.
            
            We will then delete the caregiver account as you won’t be needing it.
            If you’d like to add a caregiver to your account, you can always do so once
            you secure your account.

            #### Step-by-Step
            Don’t worry, we’ll guide you through this transfer step-by-step and you
            can make any adjustments you need along the way.
          `,
        },
        overviewTitle: 'We were told you are a caregiver for this patient. Is that true?',
        overviewDescription: '',
        accountType: {
          patient: 'No, I’m the Patient',
          caregiver: 'Yes, I’m a Caregiver',
        },
      },
      innerPageTitle: 'Relation to Patient',
    },
    accountDetails: {
      self: {
        overviewTitle: 'Here’s the info that was provided about you.',
        overviewDescription: 'Please verify it’s correct and update anything missing or wrong.',
        innerPageTitle: 'Your Details',
      },
      noInfoSelf: {
        overviewTitle: 'Let’s start by learning more about you.',
        overviewDescription: 'Please fill out the form below with information about yourself.',
        innerPageTitle: 'Your Details',
      },
    },
    verifyPhone: {
      managed: {
        overviewTitle: 'Let’s verify the patient’s phone information.',
        overviewDescription: `
          By adding the patient’s phone, we will keep them updated about upcoming meetings,
          messages from nurses, and other useful notifications.

          Caregiver phone numbers will be added in a later step.
        `,
        innerPageTitle: 'Patient Details',
      },
      self: {
        overviewTitle: 'Next, let’s verify your phone number.',
        overviewDescription: `
          PocketRN works best when we can send you text messages about upcoming meetings,
          messages from nurses, and other useful notifications.
          
          Edit or add a phone below.  Then, save the phone as your **primary number** to verify
          you are the owner of that number.
        `,
        innerPageTitle: 'Your Details',
      },
      primaryPhoneWarning: `
        #### Are you sure you want to skip verifying your phone?

        You have a phone number listed for your account that has not been verified.
        If you ever log into your account, we will no longer call or text your listed
        number unless it is verified in order to protect your privacy.  In other words, if
        you proceed without verifying your number, you may no longer receive important
        notifications from PocketRN.

        To verify your number, go back and edit your listed phone to set it as your
        primary number.
      `,
    },
    acknowledgements: {
      overviewTitle: 'Acknowledgments',
      overviewDescription: 'You must review and accept the following before proceeding.  By continuing, you’re agreeing to these terms.',
      innerPageTitle: 'Your Details',
    },
    installZoom: {
      overviewTitle: 'Let’s make sure you’re ready for your visits.',
      overviewDescription: 'We use Zoom for our virtual visits. You’ll need to install it on your device before using PocketRN.',
      innerPageTitle: 'Tutorials',
      zoomCheck: 'Zoom is Installed',
    },
    reviewPatientDetails: {
      overviewTitle: 'Great! Now let’s confirm some info about the patient.',
      overviewDescription: '',
      innerPageTitle: 'Success',
    },
    patientAccountDetails: {
      overviewTitle: 'Patient Personal Information',
      overviewDescription: 'Let’s start by confirming some basic information about the patient.',
      innerPageTitle: 'Patient Details',
    },
    scheduleMeetingCheck: {
      overviewTitle: 'Success! Now let’s set up your first nurse meeting.',
      overviewDescription: '',
      innerPageTitle: 'Schedule a Meeting',
      scheduleMeeting: 'Schedule a Meeting',
    },
    secureAccount: {
      overviewTitle: 'Lastly, let’s get your account secured.',
      overviewDescription: 'Make sure to sign up with the same email address listed below.',
      innerPageTitle: 'Finish Setup',
      later: 'I’ll do this later',
      confirmEmail: {
        description: 'In order to access your account at a later date, we need to ensure your email address is correct.  Please make sure it is typed correctly as you will be unable to alter it later.',
        confirmBtn: 'Confirm Email',
        skipBtn: 'Skip Anyway',
        field: 'Your Email Address',
      },
    },
    inviteCode: {
      overviewTitle: 'Do you have a code to join a specific clinic?',
      overviewDescription: 'Enter the clinic code or scan the QR code.<br>Don’t have a code? [Learn more](https://www.pocketrn.com/support/how-to-join-a-clinic-on-pocketrn) about clinic invitations.',
      form: {
        inputLabel: 'Clinic Code',
        inputPlaceholder: 'Enter clinic code or URL',
        scanQRCode: 'Scan a QR code',
        submit: 'Submit Code',
        viewInvites: 'View Account Invitations',
        continueWithoutCode: 'Continue without a code',
      },
    },
    selectProvider: {
      overviewTitle: {
        title: 'Join this clinic?',
        invalidTitle: 'No Matching Clinics.',
      },
      overviewDescription: {
        defaultClinic: 'The PocketRN General Medical Clinic provides direct access to nurses and is not associated with a partnering organization.',
        inviteCode: 'The code you scanned or entered is an invite to join the following clinic and plan:',
        invitedAccounts: 'You’ve been invited to the following clinic(s) below:',
        invalidDescription: 'There are no clinics using that invite code.',
      },
      clinicCard: {
        options: {
          sublabel: ((amount: number): string => `$${Math.ceil(amount)}/month`),
          sublabelWithTwoValues: ((amount1: string, amount2: number): string => `~~$${amount1}/month~~ $${Math.ceil(amount2)}/month`),
          content: ((trialDays: number): string => `
              * Unlimited visits/month
              * ${trialDays} free trial days
            `
          ),
          contentNoTrialDays: `
            * Unlimited visits/month
          `,
          subContent: `
            * 24/7 customer support
            * Scheduled and on-demand visits
            * App access for caregivers and loved ones
          `,
        },
      },
    },
    registrationSteps: {
      nurseApplicationPage: {
        overviewTitle: 'You haven’t been invited to join as a nurse',
        overviewDescription: 'If you believe this is a mistake, please contact [support@pocketrn.com](mailto:support@pocketrn.com).  Otherwise, click the link below to learn more about how you can join the PocketRN Nursing team!',
        getStarted: 'Learn More',
      },
      userForm: {
        overviewTitle: 'Welcome!',
        overviewDescription: 'Let’s start by getting some information about you.',
        overviewDescriptionCaregiver: 'Let’s start by getting some info about you. We’ll get details about the patient later.',
        form: {
          firstName: 'Legal First Name',
          firstNameCaregiver: 'Your Legal First Name',
          firstNameHelper: 'For use in legal and medical documents.',
          lastName: 'Legal Last Name',
          lastNameCaregiver: 'Your Legal Last Name',
          middleName: 'Middle Name',
          showPreferredBtn: 'Add the name you go by',
          preferredName: 'Name you go by',
          preferredNameCaregiver: 'Your Preferred Name',
          genderIdentity: 'Gender',
          genderIdentityCaregiver: 'Your Gender',
          genderIdentityInfo: genderIdentityInformation,
          learnMore: 'Learn More',
          stateOfResidence: 'State of Residence',
          stateOfResidenceCaregiver: 'Your State of Residence',
          stateOfResidenceError: 'You must be located in California',
          dateOfBirth: 'Date of Birth',
          dateOfBirthCaregiver: 'Your Date of Birth',
          dateOfBirthHelper: 'mm/dd/yyyy',
          insuranceNumber: 'Insurance #',
          zoomPersonalMeetingId: 'Personal Zoom Meeting ID',
          phone: 'Phone Number',
          saveChanges: 'save changes',
          preferNotToAnswer: 'Prefer not to answer',
        },
        notAvailable: {
          title: 'Sorry, PocketRN is not available in your state yet.',
          description: 'At the moment PocketRN is only available to patients who reside in California.',
          notifyAvailability: (state: string): string => `Would you like us to notify you when PocketRN is available in ${state}?`,
          endpointMessage: 'Join State Waitlist',
        },
        underAgeUser: {
          title: 'Sorry, you must be at least 18 years old.',
          description: 'At the moment PocketRN is only available to patients who are at least 18 years of age.',
        },
        waitlist: {
          title: 'You’ve been added to the waitlist.',
          description: (state: string): string => `
          We'll send you an email as soon as PocketRN is available in ${state}.<br>Thank you for your patience!
          `,
        },
        differentStateBtn: 'I live in a different state',
        waitlistBtn: 'Join the Waitlist',
      },
      accountTypeSelection: {
        overviewTitle: 'What kind of account are you signing up for?',
        overviewHeaderAction: {
          text: 'I’m not sure',
          markdown: `
            ### What does this mean?
  
            Choose the account type that best matches you. Don't worry, you can always switch account types later if you need to.
  
            #### Patient Account
            Select the patient account to meet with a nurse about your own health. As a patient, you can add caregivers or loved ones anytime to your account. 
  
            #### Caregiver Account
            Select this account if you are using PocketRN to sign up a patient or start a meeting on their behalf. A patient may be someone you care for or someone you are helping others care for. As a caregiver, you can add patients anytime to your account and manage their care.
  
            #### Nurse Account
            Select the nurse account if you are a licensed nurse and wish to join the PocketRN as a virtual nurse or join the Nursing community.
  
            You will be asked to verify your Nursing license to join and asked to apply if you wish to provide care on PocketRN.
          `,
        },
        patientAccountText: 'I’m a Patient',
        caregiverAccountText: 'I’m a Caregiver, Loved One, or Power of Attorney',
        nurseAccountText: 'I’m a Nurse',
      },
      addPhone: {
        overviewTitle: 'Now let’s link your mobile number.',
        overviewDescription: 'Linking your phone number allows us to send you text/SMS notifications about upcoming meetings and other reminders.',
        overviewHeaderActionText: 'Why do we need this?',
        cardDescription: 'Enter the phone number you’d like associated with your account. We’ll text* you a code to verify it’s you.',
        disclaimer: '*Standard messaging rates may apply.',
        sendVerificationCode: 'Send Code',
        resendVerificationCode: 'Resend Code',
        codeSent: 'Code Sent',
        phoneValidated: 'Phone Saved',
        sendVerificationCodeError: 'Unable to send code. Review your input or try again',
        verifyPhoneCodeError: (seconds: number): string => `Your code failed verification. Please try sending a new code in ${seconds} ${seconds === 1 ? 'second' : 'seconds'}.`,
        phoneAlreadyVerifiedError: 'This number is already linked to your account.',
        enterVerificationCodeLabel: 'Code',
        enterVerificationCodePlaceholder: 'Enter Code',
        enterVerificationCodeButton: 'Verify Code',
        enterPhoneNumberLabel: 'Mobile Phone #',
        nextButton: 'Next',
        verificationSuccessTitle: 'Your phone number has been linked.',
        verificationSuccessDescription: 'We’ll now be able to send you SMS alerts.',
        form: {
          placeholder: 'Mobile Phone #',
          enterCode: 'Enter Code',
        },
        modal: {
          overline: 'Learn More',
          title: 'Why does PocketRN need my phone number?',
          content: `
          PocketRN can send you text messages to notify you of meetings, messages, and notifications.  For example, while waiting to connect with a nurse, you may leave the app and we can notify you via a text message when a nurse is ready for you.

          We may also use your phone number to verify your identity when you call or text our support team.

          For these reasons, we highly recommend adding your phone number.  In your profile, you may configure how you'd like your phone to be used.
          `,
        },
      },
      installZoom: {
        overviewTitle: 'Let’s make sure you’re ready for your visits.',
        overviewDescription: 'We use Zoom for our virtual visits. You’ll need to install it on your device before using PocketRN. If you already have Zoom installed, you can skip this step.',
        zoomCheck: 'Zoom is Installed',
      },
      acknowledgments: {
        overviewTitle: 'Acknowledgments',
        updatedTerms: 'We need your consent',
        overviewDescription: 'You must review and accept the following before proceeding.',
        linkedPatientAcknowledgments: {
          overline: 'Guest Patient',
          overviewTitle: 'Acknowledgments',
          overviewDescription: 'You must review and accept the following before proceeding. By continuing, you’re agreeing to these terms.',
          signatureBlockLabel: {
            patient: 'Patient Name:',
            powerOfAttorney: 'Signed by Patient’s Medical Power of Attorney:',
          },
        },
      },
      registrationFinished: {
        overviewTitle: 'Your account has been set up successfully!',
        overviewDescriptionCaregiver: 'Next, we’ll help you add your patient to your account.',
        addPatientsBtn: 'Add Your First Patient',
        finishBtn: 'Finish Setup',
        otherActions: 'Here are some other actions you can take.',
        addEmergencyInfo: 'Add Emergency Information',
        emergencyInfoModal: { title: 'Emergency Information' },
      },
    },
    registerWithZoom: {
      overviewTitle: 'Register your new account.',
      overviewDescription: 'Sign in with your Zoom account. You’ll need to create an account if you don’t already have one.',
      signIn: 'Connect to Zoom Account',
    },
    finalizeRegistration: {
      overviewTitle: 'Register your new account.',
      overviewDescription: 'Finish providing your details to register.',
      register: 'register',
    },
  },
  verifyEmail: {
    overviewTitle: 'Verify Your Email Address',
    overviewDescription: 'We need you to verify your email address before we can activate your account.',
    cardDescription: (email: string): string => `<br>Please click on the link in the verification email that has been sent to:<br> **$[${email}]$**`,
    resendVerificationEmail: 'Resend Verification Email',
    resendVerificationEmailError: 'Unable to send verification email. Please wait a few minutes before attempting again.',
    logout: 'Switch Accounts',
    cannotAccessEmail: `
    ##### Are you trying to sign up a patient but don't have access to their email?
    
    We recommend creating a **caregiver** account using your own email address.  As a caregiver, you'll be able to add and manage the patient's profile after registering your own profile first.  For more information, please [contact Support](https://www.pocketrn.com/support).
  `,
    logoutBtn: 'Switch to my email address',
  },
  editPhone: {
    overviewTitle: 'Phone Preferences',
    overviewDescription: '',
  },
  editCalendarPreferences: {
    overviewTitle: 'Calendar Preferences',
    overviewDescription: '',
    consentModal: {
      title: 'What does this do?',
      markdown: `
        #### What does this do?

        By enabling this feature, you grant PocketRN access to your Google Calendar. This prevents patients and caregivers from scheduling appointments on PocketRN that overlap with Google Calendar events.

        This also automatically adds PocketRN appointments to your Google Calendar.

        **Note that appointments scheduled before activation will not be synced. Events added directly to your Google Calendar will not appear as appointments in PocketRN calendar.**

        If you disable this feature, any previously synced events will remain in your Google Calendar.
      `,
    },
    revokeConsentModal: {
      markdown: `
        #### Are you sure?

        By disabling this feature, you revoke PocketRN's access to your Google Calendar. Patients and caregivers may schedule appointments that overlap with Google Calendar events.

        **Any previously synced events will remain in your Google Calendar.**
      `,
    },
    consentError: 'Unable to save your Calendar Preferences. Please try again later.',
    innerPageTitle: 'Calendar Preferences',
    calendarSync: {
      title: 'Google Calendar Sync',
      caption: 'PocketRN appointments will appear in your Google Calendar and your patients won’t be able to schedule over your existing Google Calendar events.',
      enableButton: 'Authorize',
      disableButton: 'Revoke Authorization',
      viewMoreButton: 'View More',
    },
  },
  verifyPhone: {
    title: 'Verify Primary Phone',
    description: (phone: string): string => `We've sent a text message verification code to **$[${phone}]$**. Please enter it below.`,
    sendVerificationCodeError: 'We were unable to send a verification code to this phone. Verify you typed your phone number correctly or try again later.',
    phoneVerified: 'Phone Verified',
    phoneVerifiedDescription: 'This phone number is now your primary phone number.',
  },
  manageProfile: {
    overviewTitleFallback: 'User’s Profile',
    overviewDescription: 'View and manage your account.',
    overviewDescriptionManaged: 'View and manage your connection.',
    clinics: 'Clinics',
    meetings: 'Meetings',
    pendingInvites: 'You have pending invitations',
    manageClinicInvites: (invites: number): string => invites === 1 ? '1 Pending Invite' : `${invites} Pending Invites`,
    personalInformation: 'Personal Information',
    phonePreferences: 'Phone Preferences',
    assignments: 'Assignments',
    incompleteAssignments: (num: string): string => `Incomplete Assignments: ${num}`,
    emergencyInformation: 'Emergency Information',
    permissions: 'Permissions',
    acknowledgments: 'Acknowledgments',
    missingAcknowledgments: 'Missing Acknowledgments',
    calendarPreferences: 'Calendar Preferences',
    cardTitle: 'Personal Information',
    addPhone: 'A primary number has not yet been added.',
    profileSettings: {
      enableSmsNotifcations: 'Send notifications via text messages',
      notifyWhileUsingApp: 'Always receive notifications, even while PocketRN is open',
      cannotOptInFromAppWithPhone: (phone: string): string => `The primary phone was opted out via a text message. To opt in, you will need to send "start" to $[${phone}]$.`,
      cannotOptInFromAppNoPhone: 'The primary phone was opted out via a text message. To opt in, you will need to send "start" to the number to which you originally sent the opt out request.',
    },
    patients: 'Patients',
    caregivers: 'Caregivers',
    subscriptions: 'Subscriptions',
    creditEnds: (date: string): string => `Free Trial Expires ${date}`,
    nextBillingDate: (date: string): string => `Your next billing date is on: ${date}`,
    expiredSubscription: 'Expired',
    infiniteCredit: 'Managed by clinic',
    noAccountTypeModal: `
      #### You do not have access to this user.

      You have attempted to view information on a user's account
      that you are not authorized to view.

      If you believe this is an error, please [contact Support](/help/contact-support).
    `,
    remove: 'Remove Connection',
    removeConnectionModal: `
      #### Are you sure you want to remove this connection?

      All permissions associated with this user will be revoked for both you and the user.

      You can always re-approve this connection later.
    `,
  },
  clinics: {
    overviewTitle: 'Clinics',
    overviewDescription: '',
    inviteOnlyInfo: `
      #### You do not have permission to view this user's clinics.

      You may invite the user to join a clinic, but you cannot view the status of that clinic invitation.
    `,
    inviteToProvider: {
      overviewTitle: 'Select a Clinic',
      overviewDescription: 'Which of your clinics will this patient be joining?',
      addCustomProvider: 'Add a Custom Clinic',
    },
    buttons: {
      addClinic: 'Add a Clinic',
      manageSubscriptions: 'Manage Clinic Subscriptions',
      join: 'Join',
    },
    accounts: {
      pending: 'Pending Invitations:',
      myClinics: 'Active Clinics:',
      options: {
        label: 'Standard Package',
        sublabel: ((amount: string): string => `$${amount}/month`),
        content: ((trialDays: number): string => `
            * Unlimited visits/month
            * ${trialDays} free trial days
          `
        ),
        subContent: `
          * 24/7 customer support
          * Scheduled and on-demand visits
          * App access for caregivers and loved ones
        `,
      },
    },
    successPage: { overviewTitle: 'You successfully joined this clinic!' },
  },
  editProfile: {
    overviewTitle: 'Personal Information',
    overviewDescription: '',
    innerPageTitle: 'Personal Information',
    cannotViewProfile: `
      You do not have access to view this user's personal information.
    `,
  },
  emergencyInformation: {
    overviewTitle: 'Emergency Information',
    overviewDescription: 'In case of an emergency while on a call with a nurse, this info will be used to send help.',
    address: 'Your Current Address',
    addressHelper: {
      title: 'Who will be able to see this?',
      markdown: `
        * **Caregivers:** Unless you give permission otherwise, caregivers will not be able to see this information.
        * **Nurses:** Nurses who are assigned to your care will be able to see this information if there is an emergency.
        * **PocketRN Staff:** The PocketRN Support team will be able to see this information.
        * **Everyone else:** No one else will be able to see your information.
      `,
    },
    form: {
      street: 'Street Address',
      street2: 'Apt, suite, etc. (Optional)',
      city: 'City',
      State: 'State',
      zip: 'Zip Code',
      specialInstructions: {
        label: 'Access Instructions (Optional)',
        placeholder: 'Type any special instructions for accessing your home',
      },
      emergencyInstructions: {
        title: 'Special Instructions for Emergency Responders',
        placeholder: 'Type your instructions here (optional)',
      },
    },
  },
  user: {
    details: 'User Details',
    fullName: 'Full Name',
    email: 'Email',
    phone: 'Phone Number',
    dateOfBirth: 'Date of Birth',
    accounts: 'Accounts',
  },
  account: {
    type: 'Type',
    callTypes: 'Offered Call Types',
    providerId: 'Provider ID',
    status: 'Status',
    createdAt: 'Created At',
    suspendBtn: 'Suspend Account',
    approveBtn: 'Approve Account',
    creditEndsAt: 'Trial Ends At (UTC)',
    creditPlan: 'Credit Plan',
    creditBtn: 'Update Trial',
    discountAmount: 'Discount Amount',
    discountPercentage: 'Or Discount Percentage',
    discountBtn: 'Update Discount',
    queuePriority: 'Queue Priority',
    updateQueuePriority: 'Update Queue Priority',
  },
  logo: { alt: 'PocketRN Logo' },
  provider: {
    operationHours: {
      day: (day: string): string => `${day}:`,
      hour: (hour: string): string => hour,
      form: {
        closed: 'Closed',
        from: 'From',
        to: 'To',
      },
    },
  },
  linkedUser: {
    missingClinicModal: {
      manageClinics: 'Review Clinic Invitations',
      markdown: `
        #### The patient needs to join a clinic to continue.

        You can only invite a caregiver to an account that the patient has joined.
      `,
    },
    patient: {
      add: 'Add a Patient',
      overviewTitle: 'Patients',
      innerPageTitle: 'My Stuff',
    },
    caregiver: {
      add: 'Add a Caregiver',
      overviewTitle: 'Caregivers',
      innerPageTitle: 'My Stuff',
    },
    invite: {
      loadingAccountDetails: {
        markdown: `
          This step takes a bit more time than usual. Behind the scenes we’re working hard to:

          * Create a new account for the user.
          * Notify them of their new account.
          * Encrypt their data for safe keeping.
          * Connect you to the user.
        `,
      },
      unsavedChanges: 'You have unsaved changes. Are you sure you want to leave?',
      innerPageTitle: {
        patient: 'Add a Patient',
        caregiver: 'Add a Caregiver',
      },
      caregiverPermissions: {
        overviewTitle: 'Now let’s set up the caregiver’s permissions.',
        managed: {
          overviewDescription: 'We’ll need to know what the caregiver can do on behalf of the patient. You can always change these permissions later.',
        },
        unmanaged: {
          overviewDescription: 'We’ll need to know what the caregiver can do on your behalf. You can always change these permissions later.',
        },
      },
      caregiverSuccess: {
        overviewTitle: 'Success! You have added the caregiver.',
        overviewDescription: '',
      },
      accountDetails: {
        patient: {
          overviewTitle: 'Patient Details',
          overviewDescription: 'We’ll need some basic information about the patient in order to provide medical care.',
        },
        caregiver: {
          overviewTitle: 'Caregiver Details',
          overviewDescription: 'We’ll need some basic information about the caregiver.',
        },
      },
      scheduleMeeting: {
        schedulingOptions: {
          patient: {
            overviewTitle: 'Great! Now let’s set up the patient’s first nurse meeting.',
            overviewDescription: '',
          },
          headerAction: {
            text: 'Why is this important?',
            markdown: `
              #### Why is it important to schedule a meeting with a nurse?

              For most clinics on PocketRN, we highly recommend scheduling a new patient appointment with a nurse.  Here's why:

              * **To build a lasting bond.** Our focus is on creating a trusting relationship between your patient and their nurse, ensuring comfort and ease in reaching out for any need.
              * **To establish medical history.** This initial session helps us understand your patient's medical background, crucial for tailored care in future meetings.
              * **To develop a custom care plan.** We'll collaborate to create a care plan that respects your patient's preferences and health goals.

              Early engagement is key to effective care. Let's start this journey with a strong first step. Schedule your new patient appointment today, and let's make personalized at-home health care a seamless and comforting experience for your patient.
            `,
          },
          innerPageTitle: 'Schedule a Meeting',
          noConsentInfo: `
            #### We will need the patient's consent before the meeting.

            We recommend that you schedule the meeting far enough in advance to ensure the patient
            has signed the terms and is ready to meet with a nurse.

            If the scheduled time comes and the terms are not signed, the meeting may need to be canceled.
          `,
          whyCannotConnectNow: `
            #### Why can't I connect with a nurse right now?

            A meeting cannot occur until the patient has signed the terms.  We've sent the terms to be
            signed but we cannot guarantee they will be signed in time for an on-demand meeting.

            Instead, we recommend scheduling a meeting in advance to ensure the patient has time to
            sign the terms.
          `,
          option: {
            schedule: 'Schedule a Meeting',
            connectNow: 'Connect with a Nurse Now',
            skip: 'I’ll do this later',
          },
        },
        skipScheduling: {
          patient: {
            overviewTitle: 'You have successfully added the patient!',
            overviewDescription: '',
          },
          innerPageTitle: 'Success',
          accountOwner: {
            title: 'Patient Account',
            info: 'Foo',
            fullName: 'Full Name:',
            dateOfBirth: 'Date of Birth:',
          },
        },
      },
      consentSigning: {
        checkCoSignerPoA: {
          caller: {
            overviewTitle: 'Do you have Power of Attorney for this patient?',
            overviewDescription: 'You will need both legal and medical Power of Attorney, otherwise, select “No”.',
          },
        },
        passDevice: {
          alreadySigned: {
            overviewTitle: 'The terms have been signed for this patient.',
            overviewDescription: 'You can review the signed terms in the patient’s profile.<br>For now, you may safely proceed.',
          },
          poa: {
            overviewTitle: 'Please pass the device to the Power of Attorney.',
            overviewDescription: 'With legal Power of Attorney, they can agree to the terms for the patient.',
          },
          patient: {
            overviewTitle: 'Please pass the device to the patient.',
            overviewDescription: 'They will need to agree to the terms on the next page personally.',
          },
          cannot: {
            poa: 'I am not with the Power of Attorney',
            patient: 'I am not with the patient',
          },
        },
        acknowledgments: {
          caller: {
            overviewTitle: 'Acknowledgments',
            overviewDescription: 'You must review and accept the following before proceeding. By continuing, you agree to these terms on behalf of the patient.',
          },
          poa: {
            overviewTitle: 'Acknowledgments',
            overviewDescription: 'You must review and accept the following before proceeding. By continuing, you agree to these terms on behalf of the patient.',
          },
          patient: {
            overviewTitle: 'Acknowledgments',
            overviewDescription: 'You must review and accept the following before proceeding. By continuing, you’re agreeing to these terms.',
          },
        },
        selectNotified: {
          all: {
            overviewTitle: 'No problem, we can have the terms signed via email or text.',
            overviewDescription: `
              Provide the contact information for the patient, a caregiver with Power of Attorney, or someone who can help the patient read and sign.
            `,
          },
        },
        selectFormsToSend: {
          patient: {
            overviewTitle: 'Which consents do you want signed?',
            overviewDescription: '',
          },
        },
      },
      caregiverOwnerDetails: {
        overviewTitle: 'Invite this caregiver to PocketRN',
        overviewDescription: 'We do not have this caregiver in our system.  Please provide us with some information so we can more personally invite them to manage this account.',
      },
      accountOwnerExists: {
        patient: {
          overviewTitle: 'Your patient is already on PocketRN!',
          overviewDescription: 'Would you like to connect with them and invite them to join your selected clinic as a patient?',
          connect: 'Connect with Patient',
        },
        caregiverOfPatient: {
          overviewTitle: 'Great news! The caregiver is already on PocketRN!',
          overviewDescription: 'Would you like to connect with them and invite them to join your selected clinic as a caregiver? You’ll still go through the steps to set up the patient.',
          connect: 'Connect with Caregiver',
        },
        caregiver: {
          overviewTitle: 'Great news! The caregiver is already on PocketRN!',
          overviewDescription: 'Would you like to connect with them and invite them to join your selected clinic as a caregiver?',
          connect: 'Connect with Caregiver',
        },
        infoOnRemainingActions: `
          #### How do I schedule a meeting for this patient?

          Since this patient already has an account on PocketRN, you will need to wait until they
          approve your connection request and grant you the permissions to schedule a meeting
          on their behalf.
        `,
      },
      accountOwnerEmail: {
        overviewHeaderAction: {
          text: 'Can it be a caregiver’s email?',
          markdown: (noEmailBtnText: string): string => `
            ### Should I enter a caregiver’s email?

            No. Please make sure the email you've entered belongs to the patient.

            If the patient doesn't have an email, or you do not wish for the patient to have a personal account on PocketRN, please select **"${noEmailBtnText}."** You will have an opportunity to enter emails for caregivers, loved ones, or a Power of Attorney at a later step.
          `,
        },
        yourEmailError: 'This is your email address.  Did you mean to be the account manager?',
        foundUser: {
          markdown: `
            #### That email address is already associated with an account.

            You cannot assign an account to an existing PocketRN user.  Instead,
            that person should manage this through their own PocketRN account.

            Would you like to send them a request to connect?
          `,
          confirmBtn: 'Request to Connect',
          alreadyExists: 'A connection is already made with this user.',
          unknownError: 'An unknown error occurred. Please try again later.',
        },
        patient: {
          overviewTitle: 'Does the patient have an email address?',
          overviewDescription: 'We’ll use this to invite the patient to access their account, if they choose.',
          email: 'Patient Email Address',
          noEmailBtn: 'The patient doesn’t have an email',
        },
        caregiver: {
          overviewTitle: 'Does the caregiver have an email address?',
          overviewDescription: 'We’ll use this to invite the caregiver to access their account, if they choose.',
          email: 'Caregiver Email Address',
          noEmailBtn: 'The caregiver doesn’t have an email',
        },
      },
      accountOwnerPhone: {
        overviewHeaderAction: {
          text: 'Can it be a caregiver’s phone?',
          markdown: (noPhoneBtnText: string): string => `
            ### Should I enter a caregiver’s phone?

            No. Please make sure the phone number you've entered belongs to the patient.

            If the patient doesn't have a phone number that the patient would use to communicate with our nurses, please select **"${noPhoneBtnText}."** You will have an opportunity to enter phones for caregivers, loved ones, or a Power of Attorney at a later step.
          `,
        },
        phoneType: 'This number can be reached via:',
        phoneWithTextInformation: `
          #### We highly recommend listing a phone number that can receive texts.

          We may need to send the texts that contain links to sign terms, join meetings, etc.
        `,
        showChangeOwnerModal: {
          patient: {
            title: '',
            markdown: `
              #### This patient will need help managing their care on PocketRN.

              Either you, a caregiver, loved one, or Power of Attorney
              will be responsible for managing their account, consenting the patient, and scheduling their meetings.

              We’ll walk you through the process for setting this up.
            `,
            confirmBtn: 'Continue',
          },
          caregiver: {
            title: '',
            markdown: `
              #### Unfortunately, this might not work out.

              Without access to an email or phone number, PocketRN has no way to engage with the caregiver.

              Likely, there is no reason to add this caregiver to PocketRN.  A remote caregiver without a contact method will have
              no way to join the virtual visits.  A caregiver physically present with the patient can simply
              join using the patient's account.
            `,
            confirmBtn: 'Skip adding caregiver',
          },
        },
        patient: {
          overviewTitle: 'That’s ok! Does the patient have a phone number?',
          overviewDescription: 'PocketRN Nurses will use this to reach the patient. Patients can also use this phone number to request our services and set up their account, if they choose.',
          phone: 'Patient Phone Number',
          noPhoneBtn: 'The patient doesn’t have a phone',
        },
        caregiver: {
          overviewTitle: 'That’s ok! Does the caregiver have a phone number?',
          overviewDescription: 'We’ll use this to invite them to set up their account via a text message, if they choose. Even if their phone doesn’t receive texts, they can still call us to receive our services.',
          phone: 'Caregiver Phone Number',
          noPhoneBtn: 'The caregiver doesn’t have a phone',
        },
      },
      selectClinic: {
        patient: {
          overviewTitle: 'Select a Clinic',
          overviewDescription: 'Which of your clinics will this patient be joining?',
        },
        caregiver: {
          overviewTitle: 'Select a Clinic',
          overviewDescription: 'Which of your clinics will this caregiver be joining?',
        },
      },
      inviteCaregivers: {
        signerGroup: {
          title: 'Select Consent Signer',
        },
        inviteCaregivers: {
          overviewTitle: 'Who should help in the patient’s care?',
          overviewDescription: 'You may add caregivers or loved ones that can help manage this patient’s account, join the virtual meetings, and help sign consents.',
          group: {
            title: 'Patient’s Caregivers',
            add: 'Add a Caregiver',
            helper: {
              title: 'Who is considered a caregiver on PocketRN?',
              markdown: `
                A caregiver is anyone who helps or cares for a patient. They may be a loved one, a professional caregiver, a legal representative, or a member of a partnering care organization.
                
                You can manage the permissions of each caregiver and may freely invite as many caregivers as you wish to participate in a patient’s care.
              `,
            },
          },
        },
        assignPoA: {
          overviewTitle: 'Power of Attorney and Consents',
          overviewDescription: 'We need to consent the patient to use our services. If the patient cannot sign, you may designate a caregiver that holds Power of Attorney to sign on their behalf.',
          group: {
            title: 'Select any Power of Attorneys',
            add: 'Add a Power of Attorney',
            helper: {
              title: 'What is a Power of Attorney?',
              markdown: `
                A Power of Attorney is a legal document that grants one person, such as a caregiver, the authority to act on behalf of the patient in legal or medical matters.
                For simplicity, we refer to this person as a Power of Attorney.

                To be considered a Power of Attorney, the caregiver must have the legal right to make medical decisions for the patient. This may include making medical decisions, signing legal documents, or managing the patient’s account.

                A Power of Attorney will have full access to the patient’s account.
              `,
            },
          },
        },
        poaAccountType: 'Power of Attorney',
        editPermissions: 'Edit Permissions',
        poaSign: 'Power of Attorney Will Sign',
        noSigner: 'Select a Signer to Continue',
        patientSign: 'Patient Will Sign',
        selectedSigner: 'Consent Signer',
      },
    },
    requests: {
      received: (total: number): string => `Requests: ${total}`,
      sent: (total: number): string => `Pending: ${total}`,
      approved: (total: number): string => `Approved: ${total}`,
      declined: (total: number): string => `Declined: ${total}`,
      needsAttention: (total: number): string => `Needs Attention: ${total}`,
    },
    linkedUserCard: {
      dateOfBirth: (date: string): string => `Born on: ${date}`,
      invitedAt: (formattedDateTime: string): string => `Invite sent on: ${formattedDateTime}`,
      approve: 'Approve',
      view: 'View',
      add: 'Add',
      patientOf: (caregiverEmail: string): string => `Patient of: $[${caregiverEmail}]$`,
      missingAcknowledgments: 'Missing Acknowledgments',
    },
    patients: 'Patients',
    caregivers: 'Caregivers',
    permissions: 'Permissions',
    profile: {
      patient: { overviewTitle: 'Patient Profile' },
      caregiver: { overviewTitle: 'Caregiver Profile' },
      overviewDescription: '',
      email: 'Email:',
    },
    cannotFind: 'No user exists with these parameters.',
  },
  subscription: {
    overviewTitle: 'Subscription',
    overviewDescription: 'Manage your subscriptions and billing to PocketRN.',
    planType: 'Plan Type:',
    [StripeProductKey.Monthly]: {
      title: 'Monthly Subscription',
      dollarPer: (dollar: number): string => `$${Math.ceil(dollar)}/mo`,
      dollarAmmount: (dollar: number): string => `$${Math.ceil(dollar)}`,
    },
    paymentInfo: 'Payment Info:',
    setupSubscription: 'Set Up Paid Subscription',
    unexpectedState: `
      Oh no!  Your subscription is in an unexpected state.
      
      Please contact Support at [support@pocketrn.com](mailto:support@pocketrn.com) or call [(872) 233-4490](tel:+1872-233-4490).
      We'll make sure to get this issue resolved.
    `,
    resumeSubscription: 'Resume Subscription',
    cancelSubscription: 'Cancel Subscription',
    setupPaymentInfo: 'Set Up Payment Info',
    removePaymentInfo: 'Remove Payment Info',
    paidBy: (provider: string): string => `Payment managed by $[${provider}]$. You can still manage your payment method but will not be charged while $[${provider}]$ is handling the subscription cost.`,
    cancelNote: 'If you cancel, you can still use PocketRN until the end of the billing cycle.',
    setupNote: 'Setting up your paid subscription will ensure your service continues after your trial.',
    cancelPendingNote: (date: string): string => `Your subscription is set to cancel on ${date}`,
    pay: {
      overviewTitle: 'Billing Information',
      overviewDescription: `
        You may cancel your subscription at any time through the Subscription page or by contacting [support@pocketrn.com](mailto:support@pocketrn.com).
        See our [Cancellation Policy](https://www.pocketrn.com/policies/cancellation) for more information.
      `,
      error: {
        unexpected: 'An unexpected error occurred. Please try again.',
        invalidAddress: 'Invalid or unsupported address.  Make sure it is entered correctly.',
        contactSupport: 'Contact Support',
      },
      legalCheck: 'I understand and agree that my subscription will automatically renew and that I will be charged the subscription price until I cancel my subscription.',
    },
    paySucceeded: {
      overviewTitle: 'Your plan has been upgraded!',
      overviewDescription: 'You will now be redirected back to the Subscription page.',
      snackbar: { unableToValidateSubscription: 'We were unable to validate your subscription. Please try refreshing the page. Contact support@pocketrn.com if the problem persists.' },
    },
    updateMethodSucceeded: {
      overviewTitle: 'Your payment info has been updated.',
      overviewDescription: 'You will now be redirected back to the Subscription page.',
    },
    billing: {
      title: 'Next Billing Date:',
      nextBillDate: (date: string): string => `${date}`,
      paidBy: (provider: string, date: string): string => `Payment managed by $[${provider}]$ until ${date}`,
      creditRemaining: (credit: number): string => `Credit remaining: $${credit}`,
      appliedCredit: (credit: number): string => `$${credit} of credits will be applied to your next payment.`,
      discountAmount: (discount: number): string => `Discount applied: $${discount}`,
      discountPercentage: (discount: number): string => `Discount applied: ${discount}%`,
    },
    paymentMethod: {
      cardLast4: (last4: number): string => `xxxx xxxx xxxx ${last4}`,
      expirationYear: (year: number): string => `Exp: xx/${year.toString().slice(2)}`,
    },
    priceSelectError: 'An error occurred while choosing the price.',
    contactSupport: 'Contact Support',
  },
  suspendedAccount: {
    overviewTitle: 'Account Suspended',
    overviewDescription: (provider: string) : string => `You no longer have access to **$[${provider}]$** on PocketRN.`,
    contactSupport: `
      If you believe this is a mistake, please contact [support@pocketrn.com](mailto:support@pocketrn.com)
    `,
  },
};

export const locales = { core };
