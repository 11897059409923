import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { StringFileDownload } from '../../ds';
import { Page as DSPage } from '@pocketrn/rn-designsystem';
import { usePageLoading } from '../../utils/usePageLoading';
import { pageController } from '../../utils/pageStateInit';
import localePrimer from '../../utils/localePrimer';

interface Props {
  asPage?: boolean;
}

const LoadingRoute: FunctionComponent<Props> = (props): ReactElement | null => {
  usePageLoading(true);

  useEffect(() => {
    pageController.setVectorImage('');
    pageController.setDescription('');
    pageController.setTitle('');
  }, []);

  const preload = true;

  const doNothing = (): void => {
    // do nothing
  };

  if (props.asPage) {
    return null;
  }

  // @NOTE: this seems really hacky, but there are certain things (such
  // as locale) that need to be preloaded for some of the things in the legacy
  // ds to work.  Since the LoadingRoute **always** loads before the lazy loaded
  // routes kick in, it is essentially acting as an import loader for the ds/index
  // file, and thus everything that has a singleton instance exported from that file.
  // Once we fully deprecate all of ds, then this will no longer be needed.
  if (!preload) {
    return (
      <StringFileDownload
        label=""
        stringifiedFile=""
        type="csv"
        loading
      />
    );
  }

  return (
    <DSPage
      innerPage
      sideStripe="hide"
      loading
      localePrimer={localePrimer}
      requiredAssets={{ svgs: { loadingDetails: '' } }}
    />
  );
};

export default LoadingRoute;
