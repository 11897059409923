export enum MessageType {
  IssueWithConnectionOrVideo = 'issueWithConnectionOrVideo',
  UserNeverConnected = 'userNeverConnected',
  FlagUserAsInappropriate = 'flagUserAsInappropriate',
  OtherIssue = 'otherIssue',
  ReportBug = 'reportBug',
  RequestHelp = 'requestHelp',
  ProvideFeedback = 'provideFeedback',
  MedicalQuestion = 'medicalQuestion',
}
