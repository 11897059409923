import { Person } from '@pocketrn/entities/dist/community';
import { Account, AccountType, ClientCustomForm, ClientSignedCustomForm, LinkedUser, Provider, User } from '@pocketrn/entities/dist/core';
import { createContext } from 'react';

export interface ProfileContextType {
  user: User | null;
  person: Person | null;
  accounts: Account[];
  providerMap: Record<string, Provider>;
  accountType: AccountType | null;
  linkedUser: LinkedUser | null;
  customFormsMap: Record<string, ClientCustomForm>;
  signedCustomFormsMap: Record<string, ClientSignedCustomForm>;
  managedUid?: string;
  caregiverUid?: string;
  loading: boolean;
  setUser: (user: User) => void;
  setPerson: (person: Person) => void;
  refreshCustomForms: (managedUid?: string) => Promise<void>;
}

const handleVoid = (): void => {
  return;
};

export const ProfileContext = createContext<ProfileContextType>({
  user: null,
  person: null,
  accounts: [],
  accountType: null,
  linkedUser: null,
  customFormsMap: {},
  signedCustomFormsMap: {},
  loading: true,
  setUser: handleVoid,
  setPerson: handleVoid,
  providerMap: {},
  refreshCustomForms: async () => handleVoid(),
});
