import {
  MessagesController,
  ChatbotController,
  BotResponseLibrary,
} from '../apps/messaging-app/index';
import { chatbotSDK } from '../services/firebase';
import { firebaseAuth, firestore } from './firebase';
import localePrimer from './localePrimer';
import store from './store';
import { sessionUserController, userStateController } from './userStateInit';

export const chatbotController = new ChatbotController(store);
export const messagesController = new MessagesController(
  chatbotController,
  userStateController,
  sessionUserController,
  firebaseAuth,
  firestore,
  chatbotSDK,
  store,
);
export const botResponseLibrary = new BotResponseLibrary(localePrimer, chatbotController);
