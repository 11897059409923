import { Person } from '@pocketrn/entities/dist/community';
import { Acknowledgment, User } from '@pocketrn/entities/dist/core';
import { LocalePrimer } from '@pocketrn/localizer';
import {
  DefaultProps,
  DangerTriangleIcon,
  Modal,
  H4,
  Paragraph,
} from '@pocketrn/rn-designsystem';
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectSessionPerson,
  selectSessionUser,
  selectSessionUserCustomFormsMap,
  selectSessionUserSignedCustomFormsMap,
} from '../../apps/user-state';
import ImpersonationBanner from '../../apps/technician-app/components/ImpersonationBanner';
import AcknowledgmentsForm, { addSignedAcknowledgmentToUser } from '../registration/forms/Acknowledgments.form';
import { useAcknowledgmentsState } from '../../utils/useAcknowledgmentsState';

const locale = 'core.register.registrationSteps.acknowledgments';

interface Props extends DefaultProps {
  localePrimer: LocalePrimer;
}

const AcknowledgmentsModal: FunctionComponent<Props> = (props): ReactElement | null => {
  const sessionUser = useSelector(selectSessionUser);
  const sessionPerson = useSelector(selectSessionPerson);
  const customFormsMap = useSelector(selectSessionUserCustomFormsMap);
  const signedCustomFormsMap = useSelector(selectSessionUserSignedCustomFormsMap);
  const [ person, setPerson ] = useState(undefined as Person | undefined);
  const [ user, setUser ] = useState(undefined as User | undefined);
  const [ showModal, setShowModal ] = useState(false);
  const {
    allAcknowledgmentsSigned,
  } = useAcknowledgmentsState(props.localePrimer);

  useEffect(() => {
    setPerson(sessionPerson);
  }, [sessionPerson]);

  useEffect(() => {
    setUser(sessionUser);
  }, [sessionUser]);

  useEffect(() => {
    if (user && person) {
      const allSigned = allAcknowledgmentsSigned(user, person, undefined, false, {
        customFormsMap,
        signedCustomFormsMap,
        filters: { providerId: sessionUser?.activeProviderId },
      });
      setShowModal(!allSigned);
    }
  }, [ user, person, signedCustomFormsMap ]);

  if (!user || !person) {
    return null;
  }

  const handleSigned = (acknowledgment: Acknowledgment) => {
    setUser(addSignedAcknowledgmentToUser(user, acknowledgment));
  };

  const handleVoid = () => {
    return;
  };

  return (
    <Modal
      hide={!showModal}
      fullScreen
      constrainedSize="md"
      header={<ImpersonationBanner />}
      testIdRoot="acknowledgments-modal"
    >
      <DangerTriangleIcon align="center" color="prnOrange" size="md" />
      <H4 align="center" text={props.localePrimer.translate(locale, 'updatedTerms')} extraBold />
      <Paragraph
        size="sm"
        align="center"
        text={props.localePrimer.translate(locale, 'overviewDescription')}
      />
      <AcknowledgmentsForm
        user={user}
        person={person}
        onSigned={handleSigned}
        onNameChange={handleVoid}
        onAllSigned={handleVoid}
        mustConsentToContinue
      />
    </Modal>
  );
};

export default AcknowledgmentsModal;
