import { ReduxAction, SDKActionId, SDKActions } from '@pocketrn/client/dist/entity-sdk';

export const REDUCER_KEY = 'help';
const ra = new ReduxAction(REDUCER_KEY);

export enum ActionKey {
  SupportTicket = 'supportTicket',
}

export const helpActionId = new SDKActionId(ra);
export const helpActions = new SDKActions(helpActionId);
