import { SessionUserController } from '../../apps/user-state';
import { CoreSDK } from '../../services/firebase/CoreSDK';
import { User, SnapshotCoreFields, AccountType } from '@pocketrn/entities/dist/core';
import { Person } from '@pocketrn/entities/dist/community';
import { CommunitySDK } from '../../services/firebase/CommunitySDK';
import { SnapshotHandler } from '../../utils/SnapshotHandler';
import { Firestore } from 'firebase/firestore';
import { ManagedProperty } from '@pocketrn/client/dist/entity-sdk';

// @NOTE: Redux does not export its Store type.
export type ReduxStore = any;
const SNAPSHOT_COLLECTION_NAME = 'snapshot_core';

export class UserController {
  public sdk: CoreSDK;
  public store: ReduxStore;
  public sessionUserController: SessionUserController;
  public communitySDK: CommunitySDK;
  private snapshotHandler: SnapshotHandler;

  constructor(
    sdk: CoreSDK,
    store: ReduxStore,
    sessionUserController: SessionUserController,
    communitySDK: CommunitySDK,
    firestore: Firestore,
  ) {
    this.sdk = sdk;
    this.store = store;
    this.sessionUserController = sessionUserController;
    this.communitySDK = communitySDK;
    this.snapshotHandler = new SnapshotHandler(
      firestore,
      sessionUserController,
      {
        [SnapshotCoreFields.UserLastCheckedAt]: new Date(),
        [SnapshotCoreFields.AccountLastCheckedAt]: new Date(),
      },
    );
  }

  public async acceptUserAcknowledgment(
    id: string,
    acceptedAt: Date,
    acceptedBy: string,
    coAcceptedBy?: string,
    managed?: ManagedProperty,
  ): Promise<void> {
    await this.sdk.acceptUserAcknowledgment(id, acceptedAt, acceptedBy, coAcceptedBy, managed);
    // @NOTE: we need to update the session user to reflect the new acknowledgments.
    // This is important so that the profileContext.user is in sync with the session user.
    await this.sessionUserController.retrieveUser();
  }

  public async retrieveUserWithoutSettingSession(managed?: ManagedProperty): Promise<User> {
    return await this.sdk.getUser(managed);
  }

  public async retrievePersonWithoutSettingSession(
    managed?: ManagedProperty,
    forceAccount?: {
      accountType: AccountType;
      providerId: string;
    },
  ): Promise<Person> {
    return await this.communitySDK.getPerson(managed, forceAccount);
  }

  public async setEmail(
    email: string | null,
    options?: {
      swapWithManagerIfConflict?: boolean,
      managed?: ManagedProperty,
    },
  ): Promise<void> {
    await this.sdk.setEmail(email, options);
  }

  public async subscribeToSnapshotCore(): Promise<void> {
    await this.snapshotHandler.subscribe(
      SNAPSHOT_COLLECTION_NAME,
      () => this.unsubscribeFromSnapshotCore(),
      [
        {
          dateKey: SnapshotCoreFields.UserLastCheckedAt,
          callback: async () => this.sessionUserController.retrieveUser(),
          pingFallbackSeconds: 60,
        },
        {
          dateKey: SnapshotCoreFields.AccountLastCheckedAt,
          callback: async () => this.sessionUserController.refreshAccounts(),
          pingFallbackSeconds: 60,
        },
      ],
    );
  }

  public unsubscribeFromSnapshotCore(): void {
    this.snapshotHandler.unsubscribe();
  }
}
