import {
  ActionLinkFactory,
  ClientActionLink,
  ClientActionLinkJson,
  OptOutPhone,
  OptOutPhoneFactory,
  OptOutPhoneJson,
} from '@pocketrn/entities/dist/core';
import { createSelector } from '@reduxjs/toolkit';
import { CoreState } from './reducer';

const getOptOutPhoneListEntities = (
  state: { core: CoreState },
) => state.core.optOutPhone.listEntities;
const getOptOutPhoneActiveEntity = (
  state: { core: CoreState },
) => state.core.optOutPhone.activeEntity;
const getOptOutPhoneMapEntities = (
  state: { core: CoreState },
) => state.core.optOutPhone.mapEntities;

export const selectOptOutPhoneList = createSelector(
  [getOptOutPhoneListEntities],
  (optOutPhoneList: OptOutPhoneJson[]) => {
    return optOutPhoneList.map(optOutPhone => OptOutPhoneFactory.build(optOutPhone));
  },
);

export const selectActiveOptOutPhone = createSelector(
  [getOptOutPhoneActiveEntity],
  (optOutPhone: OptOutPhoneJson | undefined) => {
    if (!optOutPhone) {
      return undefined;
    }
    return OptOutPhoneFactory.build(optOutPhone);
  },
);

export const selectOptOutPhoneMap = createSelector(
  [getOptOutPhoneMapEntities],
  (optOutPhoneMap: Record<string, OptOutPhoneJson>) => {
    const keys = Object.keys(optOutPhoneMap);
    const map: Record<string, OptOutPhone> = {};
    for (const key of keys) {
      map[key] = OptOutPhoneFactory.build(optOutPhoneMap[key]);
    }
    return map;
  },
);

export const selectOptOutPhoneLoading = (
  state: { core: CoreState },
): boolean => state.core.optOutPhone.loading;

export const getActionLinkExpiresAt = (
  state: { core: CoreState },
): Date | undefined => state.core.actionLink.expiresAt;

export const selectActionLinkExpiresAt = createSelector(
  [getActionLinkExpiresAt],
  (expiresAt: Date | undefined): Date | undefined => {
    if (expiresAt?.getTime() === selectActionLinkExpiresAt.lastResult()?.getTime()) {
      return selectActionLinkExpiresAt.lastResult();
    }
    return expiresAt;
  },
);

const getActionLink = (
  state: { core: CoreState },
): ClientActionLinkJson | undefined => state.core.actionLink.actionLink;

export const selectActionLink = createSelector(
  [getActionLink],
  (actionLink: ClientActionLinkJson | undefined): ClientActionLink | undefined => {
    if (actionLink?.id === selectActionLink.lastResult()?.id) {
      return selectActionLink.lastResult();
    }
    if (!actionLink) {
      return undefined;
    }
    return ActionLinkFactory.buildClient(actionLink);
  },
);
