import { LocalePrimer } from '@pocketrn/localizer';
import React, { ReactElement, FunctionComponent } from 'react';
import {
  IconButton,
  CloseIcon,
} from '@pocketrn/rn-designsystem';
import { WindowType } from '../../../entities/enums/WindowType';
import styles from './TopBar.module.css';

const locale = 'messages';

interface TopBarProps {
  windowType: WindowType;
  close: () => void;
  localePrimer: LocalePrimer;
}

export const TopBar: FunctionComponent<TopBarProps> = (props): ReactElement => {
  const getTitle = (): string => {
    switch (props.windowType) {
      case WindowType.Notifications:
        return props.localePrimer.translate(locale, 'notifications.title');
      case WindowType.Messages:
        return props.localePrimer.translate(locale, 'userChat.title');
      default:
        return props.localePrimer.translate(locale, 'chatbot.title');
    }
  };

  const close = () => {
    props.close();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <h2>{getTitle()}</h2>
      </div>
      <div className={styles.button}>
        <IconButton onClick={close}>
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};
