export type FeatureValue = (
  boolean |
  string |
  number |
  { [key: string]: FeatureValue } |
  undefined
);

const DEFAULT_PROVIDER_MULT = 0.0001;

export const DEFAULT_PRIORITIES = {
  FEATURE: 1 * DEFAULT_PROVIDER_MULT,
  FEATURE_PROVIDER: 2 * DEFAULT_PROVIDER_MULT,
  FEATURE_USER: 3 * DEFAULT_PROVIDER_MULT,
};

export interface FeatureJson {
  id: string;
  groupId: string;
  value: FeatureValue;
  priority: number | null;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
}

export interface FeatureProviderJson {
  id: string;
  groupId: string;
  providerId: string;
  value: FeatureValue;
  priority: number | null;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
}

export interface FeatureUserJson {
  id: string;
  groupId: string;
  uid: string;
  value: FeatureValue;
  priority: number | null;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
}
