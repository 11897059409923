import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { WarningModal } from '@pocketrn/rn-designsystem';
import { LocalePrimer } from '@pocketrn/localizer';

interface ModalProps {
  when?: boolean | undefined;
  markdown: string;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
  localePrimer: LocalePrimer;
  testIdRoot: string;
}

// @SOURCE: https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
export const PromptModal: FunctionComponent<ModalProps> = (props): ReactElement => {
  const { markdown, navigate } = props;

  const [ modalVisible, setModalVisible ] = useState(false);
  const [ lastLocation, setLastLocation ] = useState<Location | null>(null);
  const [ confirmedNavigation, setConfirmedNavigation ] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [ confirmedNavigation, lastLocation ]);

  return (
    <WarningModal
      type="question"
      show={modalVisible}
      markdown={markdown}
      onClose={closeModal}
      onConfirm={handleConfirmNavigationClick}
      localePrimer={props.localePrimer}
      testIdRoot={`${props.testIdRoot}-promptModal`}
    />
  );
};
export default PromptModal;
