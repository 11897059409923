import { SupportTicket, User } from '@pocketrn/entities/dist/core';
import { TechnicianSDK } from '../../services/firebase/TechnicianSDK';
import { Person } from '@pocketrn/entities/dist/community';

// @NOTE: Redux does not export its Store type.
export type ReduxStore = any;

export class SupportTicketController {
  public sdk: TechnicianSDK;

  constructor(sdk: TechnicianSDK) {
    this.sdk = sdk;
  }

  public async retrieveSupportTicket(ticketId: string): Promise<{
    user: User,
    person: Person,
    supportTicket: SupportTicket,
  }> {
    const { user, person, supportTicket } = await this.sdk.getSupportTicket(ticketId);
    return { user, person, supportTicket };
  }
}
