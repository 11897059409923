import { AccountType, isAcceptor, isRequestor } from '@pocketrn/entities/dist/core';
import { TutorialGroup } from '../entities/tutorials';
import { Tutorial } from '../entities/tutorial';
import { LocalePrimer } from '@pocketrn/localizer';

const locale = 'help.tutorials';
const installingLocale = 'help.tutorials.groups.installingZoom.devices';
const usingLocale = 'help.tutorials.groups.usingZoom.devices';

export function getRequiredTutorials(
  localePrimer: LocalePrimer,
  accountType?: AccountType,
): TutorialGroup[] {
  if (isAcceptor(accountType ?? AccountType.Undefined)) {
    return getAcceptorTutorials(localePrimer);
  }
  if (isRequestor(accountType ?? AccountType.Undefined)) {
    return getRequestorTutorials(localePrimer);
  }
  switch (accountType) {
    case AccountType.Technician:
      return getTechnicianTutorials(localePrimer);
    default:
      return getGeneralTutorials(localePrimer);
  }
}

function getRequestorTutorials(localePrimer: LocalePrimer): TutorialGroup[] {
  return [
    ...getGeneralTutorials(localePrimer),
    {
      title: localePrimer.translate(locale, 'groups.gettingStartedAsRequestor.title'),
      id: 'TT_REQUESTORSTART',
      tutorials: [
        new Tutorial(
          undefined,
          localePrimer.translate(locale, 'groups.gettingStartedAsRequestor.content'),
          undefined,
          false,
        ),
      ],
    },
  ];
}

function getAcceptorTutorials(localePrimer: LocalePrimer): TutorialGroup[] {
  return [
    ...getGeneralTutorials(localePrimer),
    {
      title: localePrimer.translate(locale, 'groups.gettingStartedAsAcceptor.title'),
      id: 'TT_ACCEPTORSTART',
      tutorials: [
        new Tutorial(
          undefined,
          localePrimer.translate(locale, 'groups.gettingStartedAsAcceptor.content'),
          undefined,
          true,
        ),
      ],
    },
  ];
}

function getTechnicianTutorials(localePrimer: LocalePrimer): TutorialGroup[] {
  return [...getGeneralTutorials(localePrimer)];
}

function getGeneralTutorials(localePrimer: LocalePrimer): TutorialGroup[] {
  return [
    {
      title: localePrimer.translate(locale, 'groups.installingZoom.title'),
      id: 'TT_INSTALLZOOM',
      tutorials: [
        new Tutorial(
          'https://www.youtube.com/embed/w2OnnF1vVYc',
          localePrimer.translate(installingLocale, 'iPad.content'),
          localePrimer.translate(installingLocale, 'iPad.label'),
        ),
        new Tutorial(
          'https://www.youtube.com/embed/uOXPPgtagXw',
          localePrimer.translate(installingLocale, 'iPhone.content'),
          localePrimer.translate(installingLocale, 'iPhone.label'),
        ),
        new Tutorial(
          'https://www.youtube.com/embed/6e8PndztZcs',
          localePrimer.translate(installingLocale, 'android.content'),
          localePrimer.translate(installingLocale, 'android.label'),
        ),
        new Tutorial(
          'https://www.youtube.com/embed/14SM4l1f6Gw',
          localePrimer.translate(installingLocale, 'computer.content'),
          localePrimer.translate(installingLocale, 'computer.label'),
        ),
      ],
    },
    {
      title: localePrimer.translate(locale, 'groups.usingZoom.title'),
      id: 'TT_USEZOOM',
      tutorials: [
        new Tutorial(
          'https://www.youtube.com/embed/8cz9XoXoJUw',
          localePrimer.translate(usingLocale, 'iPad.content'),
          localePrimer.translate(usingLocale, 'iPad.label'),
        ),
        new Tutorial(
          'https://www.youtube.com/embed/Pj4jY87OBIk',
          localePrimer.translate(usingLocale, 'iPhone.content'),
          localePrimer.translate(usingLocale, 'iPhone.label'),
        ),
        new Tutorial(
          'https://www.youtube.com/embed/ju5AS_9lSbw',
          localePrimer.translate(usingLocale, 'android.content'),
          localePrimer.translate(usingLocale, 'android.label'),
        ),
        new Tutorial(
          'https://www.youtube.com/embed/Ryv9vW5804o',
          localePrimer.translate(usingLocale, 'computer.content'),
          localePrimer.translate(usingLocale, 'computer.label'),
        ),
      ],
    },
  ];
}
