import { ReduxAction, SDKActionId, SDKActions } from '@pocketrn/client/dist/entity-sdk';

export const REDUCER_KEY = 'messages';

const ra = new ReduxAction(REDUCER_KEY);

export enum ActionKey {
  Notification = 'notification',
  UserChats = 'userChats',
  UserChat = 'userChat',
  Person = 'person',
}

export const messagesActionId = new SDKActionId(ra);
export const messagesActions = new SDKActions(messagesActionId);
