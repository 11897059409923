import { ManagedProperty } from '@pocketrn/client/dist/entity-sdk';
import { CoreSDK } from '../../services/firebase/CoreSDK';
import { ActionLinkEventBy, ClientActionLink } from '@pocketrn/entities/dist/core';

export class ActionLinkController {
  public sdk: CoreSDK;

  constructor(
    sdk: CoreSDK,
  ) {
    this.sdk = sdk;
  }

  public async getActionLinks(
    managed?: ManagedProperty,
  ): Promise<ClientActionLink[]> {
    return await this.sdk.getActionLinks(managed);
  }

  public async createActionLink(
    actionLink: ClientActionLink,
    managed?: ManagedProperty,
  ): Promise<void> {
    await this.sdk.createActionLink(actionLink, managed);
  }

  public async cancelActionLink(
    notify?: {
      uids: string[];
      email?: string;
      sms?: string;
    },
    cancelationReason?: string,
    managed?: ManagedProperty,
    actionLinkId?: string,
  ): Promise<void> {
    await this.sdk.cancelActionLink(notify, actionLinkId, cancelationReason, managed);
  }

  public async completeActionLink(
    managed?: ManagedProperty,
  ): Promise<void> {
    await this.sdk.completeActionLink(managed);
  }

  public async setActionLinkMetadata(
    metadata: Record<string, any>,
  ): Promise<void> {
    await this.sdk.setActionLinkMetadata(metadata);
  }

  public async switchToCaregiver(
    notify: {
      uids: string[];
      email?: string;
      sms?: string;
    },
    managed?: ManagedProperty,
  ): Promise<string> {
    const res = await this.sdk.switchToCaregiver(managed);
    const linkFor = notify.email ? ActionLinkEventBy.NotifyEmail : ActionLinkEventBy.NotifySms;
    const redirect = res.actionLink.generateRelativeLink(linkFor);
    return redirect;
  }
}
