import {
  SupportTicketController,
  HelpRouter,
  TutorialController,
} from '../apps/help-app/index';
import { helpSDK } from '../services/firebase';
import store from './store';
import { sessionUserController } from './userStateInit';

export const helpRouter = new HelpRouter();
export const supportTicketController = new SupportTicketController(helpSDK, store);
export const tutorialController = new TutorialController(helpSDK, store, sessionUserController);
