import { ClientActionLink } from '@pocketrn/entities/dist/core';
import { Action, ReduxAction, SDKActionId, SDKActions } from '@pocketrn/client/dist/entity-sdk';

const ra = new ReduxAction('CORE');

export enum ActionKey {
  OptOutPhone = 'optOutPhone',
}

export interface SetActionLinkAction extends Action {
  actionLink: ClientActionLink;
  expiresAt: Date;
}

export const CoreActionId = {
  SET_ACTION_LINK: ra.actionType('SET_ACTION_LINK'),
  CLEAR_ACTION_LINK: ra.actionType('CLEAR_ACTION_LINK'),
};

export class CoreActions {
  public static setActionLink(
    actionLink: ClientActionLink,
    expiresAt: Date,
  ): SetActionLinkAction {
    return {
      type: CoreActionId.SET_ACTION_LINK,
      actionLink,
      expiresAt,
    };
  }

  public static clearActionLink(): Action {
    return {
      type: CoreActionId.CLEAR_ACTION_LINK,
    };
  }
}

export const coreActionId = new SDKActionId(ra);
export const coreActions = new SDKActions(coreActionId);
