import {
  BlockedUsersJson,
  ClientCustomCallTypeJson,
  MeetingJson,
  QueuedAcceptorJson,
  QueuedRequestorJson,
  QueueStatsJson,
  ShiftJson,
} from '@pocketrn/entities/dist/meeting';
import { meetingActionId } from './actions';
import { SDKEntities, Action, getSDKReducerState } from '@pocketrn/client/dist/entity-sdk';
import { PersonJson } from '@pocketrn/entities/dist/community';
import { ProviderJson, UserJson } from '@pocketrn/entities/dist/core';

export interface MeetingState {
  blockedUsers: SDKEntities<BlockedUsersJson>;
  queuedAcceptor: SDKEntities<QueuedAcceptorJson>;
  queuedRequestor: SDKEntities<QueuedRequestorJson>;
  queueStats: SDKEntities<QueueStatsJson>;
  shifts: SDKEntities<ShiftJson>;
  userMatch: {
    users: SDKEntities<UserJson>,
    persons: SDKEntities<PersonJson>,
    meetings: SDKEntities<MeetingJson>;
    providers: SDKEntities<ProviderJson>;
    customCallTypes: SDKEntities<ClientCustomCallTypeJson>;
  };
}

const getInitialState = (): MeetingState => {
  return {
    blockedUsers: { listEntities: [], mapEntities: {}, loading: false },
    queuedAcceptor: { listEntities: [], mapEntities: {}, loading: false },
    queuedRequestor: { listEntities: [], mapEntities: {}, loading: false },
    queueStats: { listEntities: [], mapEntities: {}, loading: false },
    shifts: { listEntities: [], mapEntities: {}, loading: false },
    userMatch: {
      users: { listEntities: [], mapEntities: {}, loading: false },
      persons: { listEntities: [], mapEntities: {}, loading: false },
      meetings: { listEntities: [], mapEntities: {}, loading: false },
      providers: { listEntities: [], mapEntities: {}, loading: false },
      customCallTypes: { listEntities: [], mapEntities: {}, loading: false },
    },
  };
};

const initialState = getInitialState();

const meetingReducer = (state = initialState, action: Action): MeetingState => {
  return getSDKReducerState(state, action, meetingActionId, getInitialState) as MeetingState;
};

export default meetingReducer;
