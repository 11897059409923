import { ReduxAction, Action } from '@pocketrn/client/dist/entity-sdk';
import { Navigation, PageLoadingDetails } from '@pocketrn/rn-designsystem';
import { PageSideStrip } from './reducer';

export const REDUCER_KEY = 'page';
const ra = new ReduxAction(REDUCER_KEY);

export interface PageLoadingAction extends Action {
  loading: boolean | PageLoadingDetails;
}

export interface PageTitleAction extends Action {
  title: string;
}

export interface PageVectorImageAction extends Action {
  vectorImage: string;
}

export interface PageHeaderActionTextAction extends Action {
  headerActionText: string;
}

export interface PageShowOnHeaderAction extends Action {
  showOnHeaderAction: boolean;
}

export interface PageTriggerOnHeaderAction extends Action {
  triggerHeaderAction: boolean;
}

export interface PageDescriptionAction extends Action {
  description: string;
}

export interface PageInnerPageAction extends Action {
  innerPage: boolean;
}

export interface PageSideStripeAction extends Action {
  sideStripe: 'hide' | 'min' | 'max';
}

export interface PageOverlineAction extends Action {
  overline: string;
}

export interface PageTriggerOnBackAction extends Action {
  triggerOnBack: boolean;
}

export interface PageShowOnBackAction extends Action {
  showOnBack: boolean;
}

export interface PagePropsAction extends Action {
  title: string;
  vectorImage: string;
  description: string;
  headerActionText: string,
  innerPage: boolean;
  innerPageTitle: string;
  sideStripe: PageSideStrip;
  overline: string;
  activeNavItem: Navigation;
  isAccountSwitcher: boolean;
  fullWidth: boolean;
}

export const PageActionId = {
  FINISH_INITIAL_LOADING: ra.actionType('FINISH_INITIAL_LOADING'),
  SET_LOADING: ra.actionType('SET_LOADING'),
  SET_TITLE: ra.actionType('SET_TITLE'),
  SET_VECTOR_IMAGE: ra.actionType('SET_VECTOR_IMAGE'),
  SET_HEADER_ACTION_TEXT: ra.actionType('SET_HEADER_ACTION_TEXT'),
  SET_SHOW_ON_HEADER_ACTION: ra.actionType('SET_SHOW_ON_HEADER_ACTION'),
  SET_HEADER_ACTION: ra.actionType('SET_HEADER_ACTION'),
  SET_DESCRIPTION: ra.actionType('SET_DESCRIPTION'),
  SET_INNER_PAGE: ra.actionType('SET_INNER_PAGE'),
  SET_SIDE_STRIPE: ra.actionType('SET_SIDE_STRIPE'),
  SET_OVERLINE: ra.actionType('SET_OVERLINE'),
  SET_IS_ON_BACK: ra.actionType('SET_IS_ON_BACK'),
  SET_SHOW_ON_BACK: ra.actionType('SET_SHOW_ON_BACK'),
  SET_PAGE_PROPS: ra.actionType('SET_PAGE_PROPS'),
};

export class PageActions {
  public static finishInitialLoading(): Action {
    return { type: PageActionId.FINISH_INITIAL_LOADING };
  }

  public static setPageLoading(loading: boolean | PageLoadingDetails): PageLoadingAction {
    return {
      type: PageActionId.SET_LOADING,
      loading,
    };
  }

  public static setPageTitle(title: string): PageTitleAction {
    return {
      type: PageActionId.SET_TITLE,
      title,
    };
  }

  public static setPageVectorImage(vectorImage: string): PageVectorImageAction {
    return {
      type: PageActionId.SET_VECTOR_IMAGE,
      vectorImage,
    };
  }

  public static setPageHeaderActionText(headerActionText: string): PageHeaderActionTextAction {
    return {
      type: PageActionId.SET_HEADER_ACTION_TEXT,
      headerActionText,
    };
  }

  public static setPageShowOnHeaderAction(showOnHeaderAction: boolean): PageShowOnHeaderAction {
    return {
      type: PageActionId.SET_SHOW_ON_HEADER_ACTION,
      showOnHeaderAction,
    };
  }

  public static setPageTriggerHeaderAction(
    triggerHeaderAction: boolean,
  ): PageTriggerOnHeaderAction {
    return {
      type: PageActionId.SET_HEADER_ACTION,
      triggerHeaderAction,
    };
  }

  public static setPageDescription(description: string): PageDescriptionAction {
    return {
      type: PageActionId.SET_DESCRIPTION,
      description,
    };
  }

  public static setPageInnerPage(innerPage: boolean): PageInnerPageAction {
    return {
      type: PageActionId.SET_INNER_PAGE,
      innerPage,
    };
  }

  public static setPageSideStripe(sideStripe: 'hide' | 'min' | 'max'): PageSideStripeAction {
    return {
      type: PageActionId.SET_SIDE_STRIPE,
      sideStripe,
    };
  }

  public static setPageOverline(overline: string): PageOverlineAction {
    return {
      type: PageActionId.SET_OVERLINE,
      overline,
    };
  }

  public static setPageTriggerOnBack(triggerOnBack: boolean): PageTriggerOnBackAction {
    return {
      type: PageActionId.SET_IS_ON_BACK,
      triggerOnBack,
    };
  }

  public static setPageShowOnBack(showOnBack: boolean): PageShowOnBackAction {
    return {
      type: PageActionId.SET_SHOW_ON_BACK,
      showOnBack,
    };
  }

  public static setPageProps(
    props: {
      title: string,
      vectorImage?: string,
      headerActionText?: string,
      description?: string,
      innerPage?: boolean,
      innerPageTitle?: string,
      sideStripe?: PageSideStrip,
      overline?: string,
      activeNavItem?: Navigation,
      isAccountSwitcher?: boolean,
      fullWidth?: boolean,
    },
  ): PagePropsAction {
    return {
      type: PageActionId.SET_PAGE_PROPS,
      title: props.title,
      vectorImage: props.vectorImage ?? '',
      headerActionText: props.headerActionText ?? '',
      description: props.description ?? '',
      innerPage: props.innerPage ?? false,
      innerPageTitle: props.innerPageTitle ?? '',
      sideStripe: props.sideStripe ?? 'hide',
      overline: props.overline ?? '',
      activeNavItem: props.activeNavItem ?? Navigation.Home,
      isAccountSwitcher: props.isAccountSwitcher ?? false,
      fullWidth: props.fullWidth ?? false,
    };
  }
}
