import React, { FunctionComponent, ReactElement } from 'react';
import {
  WarningModal,
} from '@pocketrn/rn-designsystem';
import { LocalePrimer } from '@pocketrn/localizer';

const locale = 'core.nameChangePolicy';

interface Props {
  localePrimer: LocalePrimer;
  onConfirm: () => void;
  owner: 'patient' | 'caregiver' | 'self';
  show: boolean;
}

const NameChangePolicyModal: FunctionComponent<Props> = (props): ReactElement => {
  return (
    <WarningModal
      show={props.show}
      markdown={props.localePrimer.translate(locale, 'markdown', props.owner)}
      type="info"
      localePrimer={props.localePrimer}
      onClose={props.onConfirm}
      testIdRoot="nameChangePolicyModal-modal"
    />
  );
};

export default NameChangePolicyModal;
