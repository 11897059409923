import { Entity } from '@pocketrn/entities/dist/entity';
import { TimeUtils } from '@pocketrn/time-utils';
import {
  FeatureJson,
  FeatureProviderJson,
  FeatureUserJson,
  FeatureValue,
} from './Feature.json';

export class Feature implements Entity {
  public id: string;
  public groupId: string;
  public value: FeatureValue;
  public createdAt: Date;
  public updatedAt: Date;
  public deletedAt?: Date;
  public priority?: number;

  constructor(id: string, groupId: string, value: FeatureValue, priority?: number) {
    this.id = id;
    this.groupId = groupId;
    this.value = value;
    this.priority = priority;
    this.createdAt = new Date();
    this.updatedAt = new Date();
  }

  public json(): FeatureJson {
    return {
      id: this.id,
      groupId: this.groupId,
      value: this.value,
      createdAt: TimeUtils.copyDate(this.createdAt) ?? new Date(),
      updatedAt: TimeUtils.copyDate(this.updatedAt) ?? new Date(),
      deletedAt: TimeUtils.copyDate(this.deletedAt) ?? null,
      priority: this.priority ?? null,
    };
  }

  public copy(): Feature {
    const feature = new Feature(this.id, this.groupId, this.value, this.priority);
    feature.createdAt = TimeUtils.copyDate(this.createdAt) ?? new Date();
    feature.updatedAt = TimeUtils.copyDate(this.updatedAt) ?? new Date();
    feature.deletedAt = TimeUtils.copyDate(this.deletedAt);
    return feature;
  }

  public get name(): string {
    // @NOTE: the name needs to be usable within the URL
    return this.groupId + '-' + this.id;
  }
}

export class FeatureProvider implements Entity {
  public feature: Feature;
  public providerId: string;

  constructor(
    feature: Feature,
    providerId: string,
  ) {
    this.feature = feature;
    this.providerId = providerId;
  }

  public json(): FeatureProviderJson {
    return {
      ...this.feature.json(),
      providerId: this.providerId,
    };
  }

  public copy(): FeatureProvider {
    return new FeatureProvider(this.feature.copy(), this.providerId);
  }
}

export class FeatureUser implements Entity {
  public feature: Feature;
  public uid: string;

  constructor(
    feature: Feature,
    uid: string,
  ) {
    this.feature = feature;
    this.uid = uid;
  }

  public json(): FeatureUserJson {
    return {
      ...this.feature.json(),
      uid: this.uid,
    };
  }

  public copy(): FeatureUser {
    return new FeatureUser(this.feature.copy(), this.uid);
  }
}
