import { PageRouter } from '@pocketrn/rn-designsystem';
import { NavigateFunction } from 'react-router-dom';

export enum MainPageAction {
  ConnectWithNurse = 'connectWithNurse',
  ScheduleMeeting = 'scheduleMeeting',
}

/**
 * @NOTE: when adding new routes, the route path is important to note:
 * 1. Anything that doesn't have subpaths is considered a root path and
 *    will not have a back button.
 * 2. Anything that has subpaths will have a back button that uses the path fragments
 *    as a breadcrumb for the back button.
 */
export enum RelativeMeetingRoute {
  ManageCallTypes = 'call-types',
  ManageShifts = 'shifts',
  AddShift = 'shifts/add',
  EditShift = 'shifts/:id',
}

export enum MeetingRoute {
  Main = '/main',
  ManageCallTypes = '/main/call-types',
  ManageShifts = '/main/shifts',
  AddShift = '/main/shifts/add',
  EditShift = '/main/shifts/:id',
  Meetings = '/meetings',
  ScheduleMeeting = '/meetings/schedule',
  RescheduleMeeting = '/meetings/:id/reschedule',
  EditParticipants = '/meetings/:id/edit-participants',
  UpcomingMeetings = '/meetings/upcoming',
  MeetingsHistory = '/meetings/history',
  Meeting = '/meetings/:id',
  MeetingFeedback = '/meetings/:id/feedback',
}

export default class MeetingRouter extends PageRouter {
  constructor() {
    super('/');
  }

  public static editShiftRoute(shiftId: string): string {
    return `/main/shifts/${shiftId}`;
  }

  public static editProviderCallTypes(providerId: string): string {
    return `/main/call-types/${providerId}`;
  }

  public static navigateToMeet(
    navigate: NavigateFunction,
    action: MainPageAction,
    patientUid?: string,
    providerId?: string,
  ): void {
    navigate(MeetingRoute.Main, { state: {
      patientUid,
      providerId,
      action,
    } });
  }

  public static getMeetingFeedbackRoute(meetingId: string): string {
    return `/meetings/${meetingId}/feedback`;
  }

  public static getMeetingRoute(meetingId: string): string {
    return `/meetings/${meetingId}`;
  }

  public static getRescheduleMeetingRoute(meetingId: string): string {
    return `/meetings/${meetingId}/reschedule`;
  }

  public static getEditParticipantsRoute(meetingId: string): string {
    return `/meetings/${meetingId}/edit-participants`;
  }

  public static getUserMeetings(
    route: MeetingRoute,
    options?: { managedUid?: string },
  ): string {
    if (!options?.managedUid) return route;
    const params = new URLSearchParams();
    if (options?.managedUid) params.set('managedUid', options.managedUid);
    return `${route}?${params.toString()}`;
  }
}
