import { User } from '@pocketrn/entities/dist/core';
import { Feature, FeatureProvider, FeatureUser } from './Feature';
import { DEFAULT_PRIORITIES } from './Feature.json';

export interface FeatureSpecifiers {
  activeUser: User | undefined;
  groupId: string;
  id: string;
}

/**
 * If the flag is not found, rather than throwing an error, we return a StoredFeature
 * with a value of undefined.  This is because flag setting is performed outside
 * of the code and could make codified logic brittle.  So instead, we revert back
 * to a value that is falsey but also distinquishable from false (if necessary).
 */
export function getFlag(
  specifiers: FeatureSpecifiers,
  features: Feature[],
  featureProviders: FeatureProvider[],
  featureUsers: FeatureUser[],
): Feature {
  const { activeUser, id, groupId } = specifiers;
  const uid = activeUser?.uid;
  const providerId = activeUser?.activeProviderId;
  const feature = features.find(f => f.id === id && f.groupId === groupId);
  if (!feature) {
    // @NOTE: see JSDoc note for more details
    return new Feature(id, groupId, undefined);
  }
  const featureProvider = featureProviders.find(f => {
    return f.feature.id === id && f.feature.groupId === groupId && f.providerId === providerId;
  });
  const featureUser = featureUsers.find(f => {
    return f.feature.id === id && f.feature.groupId === groupId && f.uid === uid;
  });
  return getPrioritizedFeature(feature, featureProvider, featureUser);
}

function getPrioritizedFeature(
  feature: Feature,
  featureProvider: FeatureProvider | undefined,
  featureUser: FeatureUser | undefined,
): Feature {
  const featurePriority = feature.priority !== undefined ?
    feature.priority :
    DEFAULT_PRIORITIES.FEATURE;
  const priorities: number[] = [featurePriority];
  let featureProviderPriority;
  if (!featureProvider) {
    featureProviderPriority = undefined;
  } else {
    featureProviderPriority = featureProvider.feature.priority !== undefined ?
      featureProvider?.feature.priority :
      DEFAULT_PRIORITIES.FEATURE_PROVIDER;
    priorities.push(featureProviderPriority);
  }
  let featureUserPriority;
  if (!featureUser) {
    featureUserPriority = undefined;
  } else {
    featureUserPriority = featureUser.feature.priority !== undefined ?
      featureUser?.feature.priority :
      DEFAULT_PRIORITIES.FEATURE_PROVIDER;
    priorities.push(featureUserPriority);
  }
  const max = Math.max(...priorities);
  if (max === featureUserPriority && featureUser) {
    return featureUser.feature;
  } else if (max === featureProviderPriority && featureProvider) {
    return featureProvider.feature;
  } else {
    return feature;
  }
}
