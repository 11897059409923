import { UserMatchState } from './reducer';

export const selectRequestorLeftQueue = (
  state: { userMatch: UserMatchState },
): boolean => state.userMatch.requestorLeftQueue;

export const selectInQueue = (
  state: { userMatch: UserMatchState },
): boolean => state.userMatch.inQueue;

export const selectMeetingFound = (
  state: { userMatch: UserMatchState },
): boolean => state.userMatch.meetingFound;

export const selectScheduledMeetingStarting = (
  state: { userMatch: UserMatchState },
): string | null => state.userMatch.scheduledMeetingStarting || null;
