import { ClientActionLinkJson, OptOutPhoneJson } from '@pocketrn/entities/dist/core';
import { CoreActionId, SetActionLinkAction, coreActionId } from './actions';
import { SDKEntities, Action, getSDKReducerState } from '@pocketrn/client/dist/entity-sdk';

export interface CoreState {
  optOutPhone: SDKEntities<OptOutPhoneJson>;
  actionLink: {
    actionLink: ClientActionLinkJson | undefined;
    expiresAt: Date | undefined;
  };
}

const getInitialState = (): CoreState => {
  return {
    optOutPhone: { listEntities: [], mapEntities: {}, loading: false },
    actionLink: { actionLink: undefined, expiresAt: undefined },
  };
};

const initialState = getInitialState();

const coreReducer = (state = initialState, action: Action): CoreState => {
  const newState = getSDKReducerState(
    state,
    action,
    coreActionId,
    getInitialState,
  ) as CoreState;
  switch (action.type) {
    case CoreActionId.SET_ACTION_LINK:
      return State.setActionLink(newState, action as SetActionLinkAction);
    case CoreActionId.CLEAR_ACTION_LINK:
      return State.clearActionLink(newState);
    default:
      return newState;
  }
};

class State {
  public static setActionLink(
    state: CoreState,
    action: SetActionLinkAction,
  ): CoreState {
    const json = action.actionLink.json();
    return {
      ...state,
      actionLink: {
        actionLink: json,
        expiresAt: new Date(action.expiresAt),
      },
    };
  }

  public static clearActionLink(state: CoreState): CoreState {
    return {
      ...state,
      actionLink: {
        actionLink: undefined,
        expiresAt: undefined,
      },
    };
  }
}

export default coreReducer;
