import { FeatureFlagActions, REDUCER_KEY } from './actions';
import { Feature, FeatureProvider, FeatureUser } from './Feature';
import { buildFeature, buildFeatureProvider, buildFeatureUser } from './Feature.factory';
import { FeatureValue } from './Feature.json';
import { FeatureFlagState } from './reducer';
import { FeatureFlagSDK } from './sdk';
import { FeatureSpecifiers, getFlag } from './utils';

// @NOTE: Redux does not export its Store type.
export type ReduxStore = any;

export class FeatureFlagController {
  public store: ReduxStore;
  public featureFlagSDK: FeatureFlagSDK;

  constructor(
    featureFlagSDK: FeatureFlagSDK,
    store: ReduxStore,
  ) {
    this.featureFlagSDK = featureFlagSDK;
    this.store = store;
  }

  public async retrieveFlags(providerId?: string): Promise<{
    features: Feature[],
    featureProviders: FeatureProvider[],
    featureUsers: FeatureUser[],
  }> {
    const flags = await this.featureFlagSDK.getFlags(providerId);
    this.store.dispatch(FeatureFlagActions.setFlags(flags));
    this.store.dispatch(FeatureFlagActions.initialized(true));
    return flags;
  }

  private get featureFlagState(): FeatureFlagState {
    return this.store.getState()[REDUCER_KEY];
  }

  public getFlag(specifiers: FeatureSpecifiers): Feature {
    return getFlag(
      specifiers,
      this.featureFlagState.flags.features.map(f => buildFeature(f)),
      this.featureFlagState.flags.featureProviders.map(f => buildFeatureProvider(f)),
      this.featureFlagState.flags.featureUsers.map(f => buildFeatureUser(f)),
    );
  }

  public async setFlag(
    id: string,
    groupId: string,
    value: FeatureValue,
    priority: number | null,
    uid?: string,
    providerId?: string,
  ): Promise<void> {
    await this.featureFlagSDK.setFlag(id, groupId, value, priority, uid, providerId);
  }

  public async getFlagScope(id: string, groupId: string): Promise<{
    feature: Feature,
    featureProviders: FeatureProvider[],
    featureUsers: FeatureUser[],
  }> {
    return await this.featureFlagSDK.getFlagScopes(id, groupId);
  }
}
