import {
  UserChatsJson,
  UserChatJson,
  NotificationJson,
} from '@pocketrn/entities/dist/chatbot';
import { PersonJson } from '@pocketrn/entities/dist/community';
import { getSDKReducerState, SDKEntities, Action } from '@pocketrn/client/dist/entity-sdk';
import { messagesActionId } from './actions';

export interface MessagesState {
  notification: SDKEntities<NotificationJson>,
  userChat: SDKEntities<UserChatJson>,
  userChats: SDKEntities<UserChatsJson>,
  person: SDKEntities<PersonJson>,
}

const getInitialState = (): MessagesState => {
  return {
    notification: { listEntities: [], mapEntities: {}, loading: false },
    userChat: { listEntities: [], mapEntities: {}, loading: false },
    userChats: { listEntities: [], mapEntities: {}, loading: false },
    person: { listEntities: [], mapEntities: {}, loading: false },
  };
};

const initialState = getInitialState();

const messagesReducer = (state = initialState, action: Action): MessagesState => {
  return getSDKReducerState(state, action, messagesActionId, getInitialState) as MessagesState;
};

export default messagesReducer;
