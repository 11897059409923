import { SnapshotHandler } from './../../../../utils/SnapshotHandler';
import { ActionKey, messagesActions, REDUCER_KEY } from '../redux/messages/actions';
import {
  Notification,
  Message,
  NotificationFactory,
  UserChat,
  UserChatFactory,
  UserChats,
  UserChatsFactory,
  SnapshotMessagesFields,
} from '@pocketrn/entities/dist/chatbot';
import { ChatbotController, ReduxStore } from './Chatbot.controller';
import { ChatbotSDK } from '../../services/firebase/ChatbotSDK';
import { State } from '@pocketrn/entities/dist/core';
import { SessionUserController, UserStateController } from '../../../user-state/index';
import { MessagesState } from '../redux/messages/reducer';
import { Auth } from 'firebase/auth';
import { Firestore } from 'firebase/firestore';

const ACTIVE_USER_CHAT_ID_USER_STATE = 'activeUserChatId';

const SNAPSHOT_COLLECTION_NAME = 'snapshot_messages';

export class MessagesController {
  public chatbotController: ChatbotController;
  public userStateController: UserStateController;
  public sessionUserController: SessionUserController;
  public firebaseAuth: Auth;
  public firestore: Firestore;
  public chatbotSDK: ChatbotSDK;
  public store: ReduxStore;
  private snapshotHandler: SnapshotHandler;
  private isRetryCallbackIfNoSnapshot = true;

  /**
   * It is assumed that firebaseStore will succeed on the call to:
   * ```
   * firebaseStore.getState().firebase.user?.uid;
   * ```
   * when we need to retrieve the firebase user.
   */
  constructor(
    chatbotController: ChatbotController,
    userStateController: UserStateController,
    sessionUserController: SessionUserController,
    firebaseAuth: Auth,
    firestore: Firestore,
    chatbotSDK: ChatbotSDK,
    store: ReduxStore,
  ) {
    this.chatbotController = chatbotController;
    this.userStateController = userStateController;
    this.sessionUserController = sessionUserController;
    this.firebaseAuth = firebaseAuth;
    this.firestore = firestore;
    this.chatbotSDK = chatbotSDK;
    this.store = store;
    this.snapshotHandler = new SnapshotHandler(
      firestore,
      sessionUserController,
      {
        [SnapshotMessagesFields.LatestMessageAt]: new Date(),
        [SnapshotMessagesFields.LatestNotificationAt]: new Date(),
      },
    );
  }

  public unsubscribeFromSnapshotMessages(): void {
    this.snapshotHandler.unsubscribe();
  };

  public async subscribeToSnapshotMessages(): Promise<void> {
    await this.snapshotHandler.subscribe(
      SNAPSHOT_COLLECTION_NAME,
      () => this.unsubscribeFromSnapshotMessages(),
      [
        {
          dateKey: SnapshotMessagesFields.LatestMessageAt,
          callback: async () => this.retrieveActiveUserChatAndUserChats(),
        },
        {
          dateKey: SnapshotMessagesFields.LatestNotificationAt,
          callback: async () => this.clearAndRetrieveNotifications(),
        },
      ],
      async () => this.createChatbotDocs(),
    );
  }

  public async clearAndRetrieveNotifications(): Promise<void> {
    await this.clearNotifications();
    await this.retrieveNotifications();
  };

  private async retrieveActiveUserChatAndUserChats(): Promise<void> {
    // @NOTE: These need to happen in this order so that new messages in a user
    // chat you are viewing do not show unread messages when you leave the user chat.
    await this.retrieveActiveUserChat();
    await this.retrieveUserChats();
  }

  private async createChatbotDocs(): Promise<void> {
    if (this.isRetryCallbackIfNoSnapshot) {
      this.isRetryCallbackIfNoSnapshot = false;
      await this.chatbotSDK.createChatbotDocs();
    }
  }

  public async retrieveUserChats(): Promise<void> {
    this.store.dispatch(messagesActions.setLoading(ActionKey.UserChats, true));
    this.store.dispatch(messagesActions.setLoading(ActionKey.Person, true));
    const { userChats, persons } = await this.chatbotSDK.retrieveUserChats();
    persons.forEach(person => {
      this.store.dispatch(messagesActions.setMapEntity(ActionKey.Person, person.uid, person));
    });
    this.store.dispatch(messagesActions.setActiveEntity(ActionKey.UserChats, userChats));
    this.store.dispatch(messagesActions.setLoading(ActionKey.UserChats, false));
    this.store.dispatch(messagesActions.setLoading(ActionKey.Person, false));
    this.chatbotController.countUnreadUserChats(userChats);
    return;
  }

  private state(): MessagesState {
    return this.store.getState()[REDUCER_KEY];
  }

  private getNotificationListEntities(): Notification[] {
    const notifications = this.state().notification.listEntities;
    return notifications.map(n => NotificationFactory.build(n));
  }

  private getUserChatsActiveEntity(): UserChats | undefined {
    const userChats = this.state().userChats.activeEntity;
    if (userChats) {
      return UserChatsFactory.build(userChats);
    }
  }

  private getUserChatActiveEntity(): UserChat | undefined {
    const userChat = this.state().userChat.activeEntity;
    if (userChat) {
      return UserChatFactory.build(userChat);
    }
  }

  private markUserChatAsRead(id: string): void {
    const userChats = this.getUserChatsActiveEntity();
    if (!userChats) {
      return;
    }
    const userChat = userChats.chats[id];
    if (!userChat) {
      throw new Error(`cannot find userChat: ${id}`);
    }
    userChat.unreadMessageCount = 0;
    this.store.dispatch(messagesActions.setActiveEntity(ActionKey.UserChats, userChats));
    this.chatbotController.countUnreadUserChats(userChats);
  }

  public async retrieveActiveUserChat(): Promise<void> {
    const userChat = this.getUserChatActiveEntity();
    if (userChat) {
      return await this.retrieveUserChat(userChat.id);
    }
  }

  public async retrieveUserChat(id: string): Promise<void> {
    this.store.dispatch(messagesActions.setLoading(ActionKey.UserChat, true));
    const { userChat, persons } = await this.chatbotSDK.retrieveUserChat(id);
    this.pingOpenUserChat(id);
    persons.forEach(person => {
      this.store.dispatch(messagesActions.setMapEntity(ActionKey.Person, person.uid, person));
    });
    this.store.dispatch(messagesActions.setActiveEntity(ActionKey.UserChat, userChat));
    this.store.dispatch(messagesActions.setLoading(ActionKey.UserChat, false));
    this.markUserChatAsRead(id);
    return;
  }

  private pingOpenUserChat(id: string): void {
    const state = new State(new Date(), { id });
    this.userStateController.updateState(ACTIVE_USER_CHAT_ID_USER_STATE, state, true);
  }

  public async retrieveMessages(id: string): Promise<boolean> {
    const userChat = this.getUserChatActiveEntity();
    if (!userChat) {
      return false;
    }
    this.store.dispatch(messagesActions.setLoading(ActionKey.UserChat, true));
    const newMessages = await this.chatbotSDK.retrieveMessages(
      id,
      this.getLastMessageId(),
    );
    userChat.messages = newMessages.concat(userChat.messages);
    this.store.dispatch(messagesActions.setActiveEntity(ActionKey.UserChat, userChat));
    this.store.dispatch(messagesActions.setLoading(ActionKey.UserChat, false));
    return newMessages.length > 0;
  }

  private getLastMessageId(): string | undefined {
    const userChat = this.getUserChatActiveEntity();
    if (userChat) {
      // @NOTE: because of the way the array is sorted for display,
      // the last message is at the beginning of the arrray.
      const lastMessage = userChat.messages[0];
      if (lastMessage) {
        return lastMessage.id;
      }
    }
    return undefined;
  }

  public async clearUserChat(): Promise<void> {
    this.pingCloseUserChat();
    this.store.dispatch(messagesActions.unsetActiveEntity(ActionKey.UserChat));
    this.store.dispatch(messagesActions.setLoading(ActionKey.UserChat, false));
    return;
  }

  private pingCloseUserChat(): void {
    this.userStateController.clearState(ACTIVE_USER_CHAT_ID_USER_STATE, true);
  }

  public async sendMessage(message: string, id: string): Promise<void> {
    this.store.dispatch(messagesActions.setLoading(ActionKey.UserChat, true));
    const userChat = this.getUserChatActiveEntity();
    if (!userChat) {
      throw new Error('active userChat is not set');
    }
    const responseMessage = await this.chatbotSDK.sendMessage(message, id);
    userChat.messages.push(responseMessage);
    this.store.dispatch(messagesActions.setActiveEntity(ActionKey.UserChat, userChat));
    this.store.dispatch(messagesActions.setLoading(ActionKey.UserChat, false));
    this.updateLatestMessage(id, responseMessage);
  }

  private updateLatestMessage(id: string, message: Message) {
    const userChats = this.getUserChatsActiveEntity();
    if (!userChats) {
      return;
    }
    const userChat = userChats.chats[id];
    if (!userChat) {
      throw new Error(`cannot find userChat: ${id}`);
    }
    userChat.latestMessage = message;
    this.store.dispatch(messagesActions.setActiveEntity(ActionKey.UserChats, userChats));
  }

  public async retrieveNotifications(archived?: boolean): Promise<void> {
    this.store.dispatch(messagesActions.setLoading(ActionKey.Notification, true));
    const previousNotifications = this.getNotificationListEntities();
    const lastNotificationId = this.getLastNotificationId();
    const newNotifications = await this.chatbotSDK.retrieveNotifications(
      lastNotificationId,
      archived,
    );
    const notifications = MessagesController.getUnqiueNotifications(
      previousNotifications,
      newNotifications,
    );
    this.chatbotController.countUnreadNotifications(notifications);
    this.store.dispatch(
      messagesActions.setListEntities(ActionKey.Notification, notifications),
    );
    this.store.dispatch(messagesActions.setLoading(ActionKey.Notification, false));
  }

  public async clearNotifications(): Promise<void> {
    this.store.dispatch(messagesActions.clearListEntities(ActionKey.Notification));
    this.store.dispatch(messagesActions.setLoading(ActionKey.Notification, false));
  }

  private getLastNotificationId(): string | undefined {
    const previousNotifications = this.getNotificationListEntities();
    const lastNotification = previousNotifications[previousNotifications.length - 1];
    if (lastNotification) {
      return lastNotification.id;
    }
    return undefined;
  }

  private static getUnqiueNotifications(
    previousNotifications: Notification[],
    newNotifications: Notification[],
  ): Notification[] {
    const notificationsMap: Record<string, Notification> = {};
    for (const notification of previousNotifications) {
      notificationsMap[notification.id] = notification;
    }
    for (const notification of newNotifications) {
      notificationsMap[notification.id] = notification;
    }
    const unqiueNotifications = [];
    const keys = Object.keys(notificationsMap);
    for (const key of keys) {
      unqiueNotifications.push(notificationsMap[key]);
    }
    return unqiueNotifications.sort((a, b) => a.sentAt > b.sentAt ? -1 : 1);
  }

  public async markNotificationAsRead(id: string): Promise<void> {
    const notifications = this.getNotificationListEntities();
    const [notification] = MessagesController.getNotificationById(id, notifications);
    notification.unread = false;
    this.store.dispatch(
      messagesActions.setListEntities(ActionKey.Notification, notifications),
    );
    this.chatbotController.countUnreadNotifications(notifications);
    await this.chatbotSDK.markNotificationAsRead(notification.id);
  }

  private static getNotificationById(
    id: string,
    notifications: Notification[],
  ): [ Notification, number ] {
    const index = notifications.findIndex(n => n.id === id);
    if (index === -1) {
      throw new Error(`cannot find notification id: ${id}`);
    }
    return [ notifications[index], index ];
  }

  public async archiveNotification(id: string): Promise<void> {
    const notifications = this.getNotificationListEntities();
    const [ notification, index ] = MessagesController.getNotificationById(id, notifications);
    notifications.splice(index, 1);
    this.store.dispatch(
      messagesActions.setListEntities(ActionKey.Notification, notifications),
    );
    this.chatbotController.countUnreadNotifications(notifications);
    await this.chatbotSDK.archiveNotification(notification.id);
  }
}
