import { gettingStartedRequestor } from './tutorials/gettingStartedRequestor/en';
import { gettingStartedAcceptor } from './tutorials/gettingStartedAcceptor/en';
import { meetingWithRequestor } from './tutorials/meetingWithRequestor/en';

export const help = {
  innerPageTitle: 'Help',
  main: {
    overviewTitle: 'Help & Documentation',
    overviewDescription: 'Whether you are new or familiar with PocketRN, you can find the resources and help that you need.',
    tutorials: 'Tutorials',
    contactSupport: 'Contact Support',
    faqs: 'FAQs',
  },
  tutorials: {
    overviewTitle: 'Tutorials',
    overviewDescription: 'The following tutorials are available to help guide you through the PocketRN experience.',
    zoomCheck: 'Zoom is installed',
    incomplete: 'incomplete',
    complete: 'complete',
    tutorialTextContent: 'Text Version',
    tutorialTextVideo: 'Video Version',
    outdatedVideo: 'Video outdated. See the text version for the latest information.',
    groups: {
      installingZoom: {
        title: 'Installing Zoom',
        devices: {
          computer: {
            label: 'Install on Computer',
            content: `
              [Download Zoom](https://zoom.us/download)

              The following video will show you how to install Zoom on your computer.
            `,
          },
          iPad: {
            label: 'Install on iPad',
            content: `
              [Download Zoom](https://apps.apple.com/us/app/zoom-cloud-meetings/id546505307)

              The following video will show you how to install Zoom on your iPad.
            `,
          },
          iPhone: {
            label: 'Install on iPhone',
            content: `
              [Download Zoom](https://apps.apple.com/us/app/zoom-cloud-meetings/id546505307)

              The following video will show you how to install Zoom on your iPhone.
            `,
          },
          android: {
            label: 'Install on Android',
            content: `
              [Download Zoom](https://play.google.com/store/apps/details?id=us.zoom.videomeetings&hl=en_US&gl=US)
              
              The following video will show you how to install Zoom on your Android phone.
            `,
          },
        },
      },
      usingZoom: {
        title: 'Using Zoom',
        devices: {
          computer: {
            label: 'On a Computer',
            content: 'The following video will show you how to use Zoom on your computer.',
          },
          iPad: {
            label: 'On iPad',
            content: 'The following video will show you how to use Zoom on your iPad.',
          },
          iPhone: {
            label: 'On iPhone',
            content: 'The following video will show you how to use Zoom on your iPhone.',
          },
          android: {
            label: 'On Android',
            content: 'The following video will show you how to use Zoom on your Android phone.',
          },
        },
      },
      gettingStartedAsAcceptor: {
        title: 'Getting Started',
        content: gettingStartedAcceptor,
      },
      gettingStartedAsRequestor: {
        title: 'Getting Started',
        content: gettingStartedRequestor,
      },
      meetingWithRequestor: {
        title: 'Meeting with a Patient',
        content: meetingWithRequestor,
      },
    },
    devices: {
      computer: 'On a Computer',
      iPad: 'On iPad',
      iPhone: 'On iPhone',
      android: 'On Android Phone',
    },
  },
  contactSupport: {
    overviewTitle: 'Contact Support',
    overviewDescription: `
      Do not include any medical information.

      All medical related questions should be directed to a nurse or to your primary care provider.
    `,
    sendMessage: 'Send',
    message: {
      title: 'Or send us a message',
      label: 'Message',
      email: '[support@pocketrn.com](mailto:support@pocketrn.com)',
      phone: '[(872) 233-4490](tel:+1872-233-4490)',
    },
    messageSentNote: 'Your message was sent. A representative will contact you via email to follow up within 1-2 business days.',
  },
};

export const locales = { help };
