import {
  Message,
  MessageFactory,
  MessageJson,
  Notification,
  NotificationFactory,
  NotificationJson,
  UserChat,
  UserChatFactory,
  UserChats,
  UserChatsFactory,
} from '@pocketrn/entities/dist/chatbot';
import { Person, PersonFactory, PersonJson } from '@pocketrn/entities/dist/community';
import { FirebaseFunctionInterface, FirebaseSDK, OnCallFunction } from '@pocketrn/client/dist/entity-sdk';
import { Auth } from 'firebase/auth';

export class ChatbotSDK extends FirebaseSDK {
  constructor(functions: FirebaseFunctionInterface, firebaseAuth: Auth) {
    super(functions, firebaseAuth);
  }

  public async createChatbotDocs(): Promise<void> {
    try {
      await this.functions.httpsCallable(OnCallFunction.Messaging, 'createChatbotDocs')({});
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async retrieveUserChats(): Promise<{ userChats: UserChats, persons: Person[] }> {
    try {
      const resp = await this.functions.httpsCallable(OnCallFunction.Messaging, 'getUserChats')({});
      return {
        userChats: UserChatsFactory.build(resp.data.userChats),
        persons: resp.data.persons.map((person: PersonJson) => PersonFactory.build(person)),
      };
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async retrieveUserChat(id: string): Promise<{ userChat: UserChat, persons: Person[] }> {
    try {
      const resp = await this.functions.httpsCallable(OnCallFunction.Messaging, 'getUserChat')({ id, markAsRead: true });
      return {
        userChat: UserChatFactory.build(resp.data.userChat),
        persons: resp.data.persons.map((person: PersonJson) => PersonFactory.build(person)),
      };
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async retrieveMessages(userChatId: string, lastMessageId?: string): Promise<Message[]> {
    try {
      const data: any = { userChatId };
      if (lastMessageId) {
        data.startAfter = lastMessageId;
      }
      const resp = await this.functions.httpsCallable(OnCallFunction.Messaging, 'getMessages')(data);
      return resp.data.messages.map((m: MessageJson) => MessageFactory.build(m));
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async sendMessage(message: string, userChatId: string): Promise<Message> {
    try {
      const resp = await this.functions.httpsCallable(OnCallFunction.Messaging, 'sendMessage')({ userChatId, message });
      return MessageFactory.build(resp.data.message);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async retrieveNotifications(
    lastNotificationId?: string,
    archived?: boolean,
  ): Promise<Notification[]> {
    try {
      const data: any = {};
      if (lastNotificationId) {
        data.startAfter = lastNotificationId;
      }
      if (archived) {
        data.archived = archived;
      }
      const resp = await this.functions.httpsCallable(OnCallFunction.Messaging, 'getNotifications')(data);
      return resp.data.notifications.map((n: NotificationJson) => NotificationFactory.build(n));
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async markNotificationAsRead(id: string): Promise<void> {
    try {
      const data = { id };
      await this.functions.httpsCallable(OnCallFunction.Messaging, 'markNotificationAsRead')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async archiveNotification(id: string): Promise<void> {
    try {
      const data = { id };
      await this.functions.httpsCallable(OnCallFunction.Messaging, 'archiveNotification')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }
}
