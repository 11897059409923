import React, { FunctionComponent, ReactElement } from 'react';
import localePrimer from '../../utils/localePrimer';
import {
  useIsMobile,
  Markdown,
  AddUserIcon,
  Caption,
  H2,
  H3,
  Paragraph,
  PasswordIcon,
  TabbedCard,
  Card,
} from '@pocketrn/rn-designsystem';
import { extractLocationParams } from '@pocketrn/client/dist/app-utils';
import styles from './LoginPage.module.css';
import NavBar from './NavBar/NavBar';
import { AccountType } from '@pocketrn/entities/dist/core';
import { useLocation } from 'react-router-dom';
import { AuthElement } from './AuthElement';
import { ReauthEmailPasswordElement } from './ReauthEmailPasswordElement';

const locale = 'core.login';

export interface InviteProps {
  accountType: AccountType;
  providerName: string;
  inviterFullName: string;
  inviteeEmail: string;
}

interface ReauthProps {
  email?: string;
  type: 'emailPassword' | 'unknown';
}

interface Props {
  invite?: InviteProps;
  reauth?: ReauthProps;
}

export const extractParams = (location: any): InviteProps | undefined => {
  return extractLocationParams(location, {
    accountType: 'accountType',
    providerName: 'providerName',
    inviterFullName: 'inviterFullName',
    inviteeEmail: 'inviteeEmail',
  }) as any || undefined;
};

const LoginPage: FunctionComponent<Props> = (props): ReactElement => {
  const isMobile = useIsMobile();
  const location = useLocation();

  const getWrapperStyles = () => {
    const s = [styles.wrapper];
    if (isMobile) {
      s.push(styles.mobile);
    }
    return s.join(' ');
  };

  const getDescriptionTranslation = () => {
    if (!props.invite) {
      return undefined;
    }
    const { accountType, providerName, inviterFullName } = props.invite;
    if (!providerName) {
      return localePrimer.translate(locale, 'invite.overviewDescriptionOther')(inviterFullName);
    }
    switch (accountType) {
      case AccountType.Patient:
        return localePrimer.translate(locale, 'invite.overviewDescriptionPatient')(
          inviterFullName,
          providerName,
        );
      case AccountType.Caregiver:
        return localePrimer.translate(locale, 'invite.overviewDescriptionCaregiver')(
          inviterFullName,
          providerName,
        );
      case AccountType.Nurse:
        return localePrimer.translate(locale, 'invite.overviewDescriptionNurse')(
          inviterFullName,
          providerName,
        );
      default:
        return localePrimer.translate(locale, 'invite.overviewDescriptionOther')(inviterFullName);
    }
  };

  const localePart = props.invite ? 'invite' : 'login';

  const reauthEmail = props.reauth?.type === 'emailPassword' ? props.reauth?.email : undefined;

  return (
    <div className={getWrapperStyles()}>
      <NavBar />
      <div className={styles.innerWrapper}>
        <section className={styles.pageContent}>
          {!!props.invite &&
            <section className={styles.header}>
              <H2
                text={localePrimer.translate(locale, 'invite.overviewTitle')}
                align="center"
              />
              <Markdown
                markdown={getDescriptionTranslation()}
                align="center"
                noBottomPadding
              />
            </section>
          }
          { reauthEmail ?
            <Card>
              <H3
                text={localePrimer.translate(locale, localePart, 'reauthCard.cardTitle')}
                align="center"
              />
              <Paragraph
                text={localePrimer.translate(locale, localePart, 'reauthCard.cardDescription')}
                size="sm"
                align="center"
              />
              <ReauthEmailPasswordElement email={reauthEmail} />
            </Card>
            :
            <TabbedCard
              leftTab={localePrimer.translate(locale, 'tabTitle.login')}
              rightTab={localePrimer.translate(locale, 'tabTitle.signup')}
              activeTab={location.pathname.includes('/signup') ? 'right' : 'left'}
              leftTabContent={(
                <section className={styles.content}>
                  <PasswordIcon style="squareBubble" />
                  <H3
                    text={localePrimer.translate(locale, localePart, 'loginCard.cardTitle')}
                    align="center"
                    noBottomPadding
                  />
                  <Paragraph
                    text={localePrimer.translate(locale, localePart, 'loginCard.cardDescription')}
                    size="sm"
                    align="center"
                    noBottomPadding
                  />
                  <AuthElement type="login" background="white" />
                  <Caption text={localePrimer.translate(locale, 'disclaimer')} align="center" />
                </section>
              )}
              rightTabContent={(
                <section className={styles.content}>
                  <AddUserIcon style="squareBubble" />
                  <H3
                    text={localePrimer.translate(locale, localePart, 'signupCard.cardTitle')}
                    align="center"
                    noBottomPadding
                  />
                  <Paragraph
                    text={localePrimer.translate(locale, localePart, 'signupCard.cardDescription')}
                    size="sm"
                    align="center"
                    noBottomPadding
                  />
                  <AuthElement type="register" background="white" />
                  <Caption
                    text={localePrimer.translate(locale, 'disclaimer')}
                    align="center"
                    noBottomPadding
                  />
                </section>
              )}
              testIdRoot="loginPage-tabbedCard"
            />
          }
        </section>
      </div>
    </div>
  );
};

export default LoginPage;
