import React, { FunctionComponent, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Choice } from '../../../entities/Choice';
import styles from './ChatChoice.module.css';

interface ChatChoiceProps {
  choice: Choice;
  close: () => void;
}

export const ChatChoice: FunctionComponent<ChatChoiceProps> = (props): ReactElement => {
  const navigate = useNavigate();

  const onClick = () => {
    if (props.choice.close) {
      props.close();
    }
    props.choice.onSelect(navigate);
  };

  return (
    <button className={styles.chatChoice} onClick={onClick}>
      {props.choice.text}
    </button>
  );
};
