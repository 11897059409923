import { TimeUtils } from '@pocketrn/time-utils';
import { Draft, DraftSpecifiers } from './Draft';
import { SecureDraftSDK } from './sdk';

export class SecureDraftController {
  public draftSDK: SecureDraftSDK;

  constructor(
    draftSDK: SecureDraftSDK,
  ) {
    this.draftSDK = draftSDK;
  }

  public async getDraft(specifiers: DraftSpecifiers): Promise<Draft | undefined> {
    return await this.draftSDK.getDraft(specifiers);
  }

  private generateDraft(
    specifiers: DraftSpecifiers,
    value: string,
    expiresInXHours?: number,
  ): Draft {
    const draft = new Draft({ ...specifiers }, value);
    if (expiresInXHours && expiresInXHours > 0) {
      draft.expiresAt = TimeUtils.addHours(new Date(), expiresInXHours) ?? new Date();
    }
    return draft;
  }

  public async setStringDraft(
    specifiers: DraftSpecifiers,
    value: string,
    expiresInXHours?: number,
  ): Promise<void> {
    const draft = this.generateDraft(specifiers, value, expiresInXHours);
    await this.draftSDK.setDraft(draft);
  }

  public async setJsonDraft(
    specifiers: DraftSpecifiers,
    value: JSON,
    expiresInXHours?: number,
  ): Promise<void> {
    const draft = this.generateDraft(specifiers, '', expiresInXHours);
    draft.valueAsJson = value;
    await this.draftSDK.setDraft(draft);
  }

  public async clearDraft(specifiers: DraftSpecifiers): Promise<void> {
    await this.draftSDK.clearDraft(specifiers);
  }
}
