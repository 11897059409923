import { ResponseKeys } from '../utils/BotResponseLibrary';
import { TextType } from './enums/TextType';
import { TextJson } from './interfaces/Text.json';

export class Text {
  public value: string;
  public type: TextType;
  public sentAt?: Date;
  public key?: ResponseKeys;

  constructor(value: string, type: TextType, sentAt?: Date, key?: ResponseKeys) {
    this.value = value;
    this.type = type;
    this.key = key;
    this.sentAt = sentAt;
  }

  public json(): TextJson {
    const res: TextJson = {
      value: this.value,
      type: this.type,
    };
    if (this.key) {
      res.key = this.key;
    }
    if (this.sentAt) {
      res.sentAt = this.sentAt;
    }
    return res;
  }
}
