import { Person, PersonFactory, PersonJson } from '@pocketrn/entities/dist/community';
import {
  AccountFactory,
  Account,
  AccountType,
  Stat,
  StatJson,
  StatFactory,
  ProviderFactory,
  Provider,
  UserFactory,
  User,
  SupportTicket,
  SupportTicketFactory,
  ProviderJson,
  AccountJson,
  AccountStatus,
  StripeProductKey,
  StripeSubscription,
  UserDiscount,
  UserJson,
  License,
  SubscriptionDiscountJson,
  SubscriptionDiscountFactory,
  SubscriptionDiscount,
  ProviderCoupon,
  ClientCustomForm,
  ClientCustomFormJson,
  CustomFormFactory,
} from '@pocketrn/entities/dist/core';
import {
  Meeting,
  MeetingFactory,
  QueueData,
  MeetingJson,
  QueueDataFactory,
  ClientCustomCallType,
  CallTypeFactory,
  ClientCustomCallTypeJson,
} from '@pocketrn/entities/dist/meeting';
import { FirebaseSDK, FirebaseFunctionInterface, OnCallFunction } from '@pocketrn/client/dist/entity-sdk';
import { Auth } from 'firebase/auth';

export interface SubscriptionOptions {
  creditEndsAt?: Date,
  creditProductKeys?: StripeProductKey[],
  amountOff?: number,
  percentOff?: number,
};

export interface UserPerson {
  user: User;
  person?: Person;
}

export class TechnicianSDK extends FirebaseSDK {
  constructor(functions: FirebaseFunctionInterface, firebaseAuth: Auth) {
    super(functions, firebaseAuth);
  }

  public async sendTextAsCallCenter(message: string, to: string): Promise<void> {
    try {
      const data = { message, to };
      await this.functions.httpsCallable(OnCallFunction.Technician, 'sendTextAsCallCenter')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async addAccount(type: AccountType): Promise<Account> {
    try {
      const data = { type };
      const resp = await this.functions.httpsCallable(OnCallFunction.Technician, 'addAccount')(data);
      return AccountFactory.build(resp.data.data.account);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async inviteUserToAccount(
    type: AccountType,
    email: string,
    providerId: string | undefined,
    noEmail: boolean,
    subscriptionOptions: SubscriptionOptions,
  ): Promise<void> {
    try {
      const data: any = {
        type,
        email,
        providerId: providerId ?? null,
      };
      if (noEmail) {
        data.noEmail = noEmail;
      }
      if (subscriptionOptions.creditEndsAt) {
        data.creditEndsAt = subscriptionOptions.creditEndsAt;
      }
      if (subscriptionOptions.creditProductKeys) {
        data.creditProductKeys = subscriptionOptions.creditProductKeys;
      }
      if (subscriptionOptions.amountOff) {
        data.amountOff = subscriptionOptions.amountOff;
      }
      if (subscriptionOptions.percentOff) {
        data.percentOff = subscriptionOptions.percentOff;
      }
      await this.functions.httpsCallable(OnCallFunction.Technician, 'inviteUserToAccount')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getStats(
    start: Date,
    end: Date,
    nextPageCursorId?: string,
  ): Promise<{ stats: Stat[], nextPageCursorId: string | undefined }> {
    try {
      const data: any = {
        start: start.toISOString(),
        end: end.toISOString(),
        nextPageCursorId,
      };
      const resp = await this.functions.httpsCallable(OnCallFunction.Technician, 'getStats')(data);
      return {
        stats: resp.data.stats.map((stat: StatJson) => StatFactory.build(stat)),
        nextPageCursorId: resp.data.nextPageCursorId,
      };
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async createEscalationStat(
    meetingId: string,
    firstName: string,
    lastName: string,
    escalatedAt: Date,
  ): Promise<void> {
    try {
      const data: any = {
        meetingId: meetingId,
        firstName: firstName,
        lastName: lastName,
        escalatedAt: escalatedAt.toISOString(),
      };
      await this.functions.httpsCallable(OnCallFunction.Technician, 'createEscalationStat')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getProvider(id: string): Promise<Provider | undefined> {
    try {
      const data = { id };
      const resp = await this.functions.httpsCallable(OnCallFunction.Technician, 'getProvider')(data);
      if (!resp.data.provider) {
        return undefined;
      }
      return ProviderFactory.build(resp.data.provider);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getProviders(nextPageCursorId?: string): Promise<{
    providers: Provider[];
    nextPageCursorId: string | undefined;
  }> {
    try {
      const resp = await this.functions.httpsCallable(OnCallFunction.Technician, 'getProviders')({
        nextPageCursorId,
      });
      return {
        providers: resp.data.providers.map(
          (provider: ProviderJson) => {
            TechnicianSDK.conformReducedProvider(provider);
            return ProviderFactory.build(provider);
          },
        ),
        nextPageCursorId: resp.data.nextPageCursorId,
      };
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async updateProvider(
    provider: Provider,
    options?: {
      updateExistingPatientsTrial?: boolean,
      updateExistingPatientsDiscount?: boolean,
    },
  ): Promise<void> {
    try {
      const providerJson = provider.json();
      const data = {
        provider: {
          id: providerJson.id,
          name: providerJson.name,
          definedCallTypes: providerJson.definedCallTypes,
          translations: providerJson.translations,
          operationHours: providerJson.operationHours,
          defaultCreditDays: providerJson.defaultCreditDays,
          defaultProductKeys: providerJson.defaultProductKeys,
          defaultDiscount: providerJson.defaultDiscount,
        },
        updateExistingPatientsCredit: options?.updateExistingPatientsTrial ?? false,
        updateExistingPatientsDiscount: options?.updateExistingPatientsDiscount ?? false,
      };
      await this.functions.httpsCallable(OnCallFunction.Technician, 'updateProvider')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async checkProviderNameExists(name: string): Promise<boolean> {
    try {
      const data = { name };
      const resp = await this.functions.httpsCallable(OnCallFunction.Technician, 'checkProviderNameExists')(data);
      return resp.data.exists;
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async addProvider(id: string, name: string): Promise<Provider> {
    try {
      const data = { id, name };
      const resp = await this.functions.httpsCallable(OnCallFunction.Technician, 'addProvider')(data);
      return ProviderFactory.build(resp.data.provider);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getQueueData(): Promise<{ queueData: QueueData, meetings: Meeting[] }> {
    try {
      const resp = await this.functions.httpsCallable(OnCallFunction.Technician, 'getQueueData')({});
      return {
        queueData: QueueDataFactory.build(resp.data.queueData),
        meetings: resp.data.meetings.map((meeting: MeetingJson) => MeetingFactory.build(meeting)),
      };
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getUserByToken(token: string): Promise<UserPerson> {
    try {
      const data = { token };
      const resp = await this.functions.httpsCallable(OnCallFunction.Technician, 'getUserByToken')(data);
      const user = UserFactory.build(resp.data.user);
      let person: Person | undefined;
      if (resp.data.person) {
        person = PersonFactory.build(resp.data.person);
      }
      return { user, person };
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getCustomCallTypes(providerId: string): Promise<ClientCustomCallType[]> {
    try {
      const data = { providerId };
      const resp = await this.functions.httpsCallable(OnCallFunction.Technician, 'getCustomCallTypes')(data);
      return resp.data.customCallTypes.map((callType: ClientCustomCallTypeJson) => {
        return CallTypeFactory.buildClientCustom(callType);
      });
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async updateCustomCallTypeEnabled(callTypeId: string, enabled: boolean): Promise<void> {
    try {
      const data = { callTypeId, enabled };
      await this.functions.httpsCallable(OnCallFunction.Technician, 'updateCustomCallTypeEnabled')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async deleteCustomCallType(callTypeId: string): Promise<void> {
    try {
      const data = { callTypeId };
      await this.functions.httpsCallable(OnCallFunction.Technician, 'deleteCustomCallType')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async setCustomCallType(callType: ClientCustomCallType): Promise<ClientCustomCallType> {
    try {
      const data: any = { ...callType.json() };
      delete data.createdAt;
      delete data.updatedAt;
      delete data.deletedAt;
      delete data.enabledAt;
      const resp = await this.functions.httpsCallable(OnCallFunction.Technician, 'setCustomCallType')(data);
      return CallTypeFactory.buildClientCustom(resp.data.customCallType);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getUserByEmail(email: string): Promise<UserPerson> {
    try {
      const data = { email };
      const resp = await this.functions.httpsCallable(OnCallFunction.Technician, 'getUserByEmail')(data);
      const user = UserFactory.build(resp.data.user);
      let person: Person | undefined;
      if (resp.data.person) {
        person = PersonFactory.build(resp.data.person);
      }
      return { user, person };
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getUserByUid(uid: string): Promise<UserPerson> {
    try {
      const data = { uid };
      const resp = await this.functions.httpsCallable(OnCallFunction.Technician, 'getUserByUid')(data);
      const user = UserFactory.build(resp.data.user);
      let person: Person | undefined;
      if (resp.data.person) {
        person = PersonFactory.build(resp.data.person);
      }
      return { user, person };
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getUsersByPhone(
    phone: string,
  ): Promise<UserPerson[]> {
    try {
      const data = { phone };
      const resp = await this.functions.httpsCallable(OnCallFunction.Technician, 'getUsersByPhone')(data);
      return resp.data.users.map(
        (userPerson: { user: UserJson, person: PersonJson | undefined }) => {
          const user = UserFactory.build(userPerson.user);
          let person: Person | undefined;
          if (userPerson.person) {
            person = PersonFactory.build(userPerson.person);
          }
          return { user, person };
        },
      );
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getUserAccounts(uid: string): Promise<{
    accounts: Account[],
    providerDiscounts: SubscriptionDiscount[],
    userDiscounts: UserDiscount[],
  }> {
    try {
      const data = { uid };
      const resp = await this.functions.httpsCallable(OnCallFunction.Technician, 'getUserAccounts')(data);
      const accounts = resp.data.accounts.map(
        (account: AccountJson) => {
          return AccountFactory.build(account);
        },
      );
      const providerDiscounts = resp.data.providerDiscounts.map(
        (providerDiscount: SubscriptionDiscountJson) => {
          return SubscriptionDiscountFactory.build(providerDiscount);
        },
      );
      const userDiscounts = resp.data.userDiscounts;
      return { accounts, providerDiscounts, userDiscounts };
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async updateUserAccount(
    uid: string,
    accountType: AccountType,
    providerId: string | undefined,
    changes: {
      status?: AccountStatus,
      queuePriority?: number,
    },
  ): Promise<void> {
    try {
      const data = {
        uid,
        accountType,
        providerId: providerId ?? null,
        changes,
      };
      await this.functions.httpsCallable(OnCallFunction.Technician, 'updateUserAccount')(data);
      return;
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async updateUserCredit(
    uid: string,
    providerId: string | undefined,
    accountType: AccountType,
    creditEndsAt: Date,
    creditProductKeys: StripeProductKey[],
  ): Promise<void> {
    try {
      const data = {
        uid,
        providerId: providerId ?? null,
        accountType,
        creditEndsAt,
        creditProductKeys,
      };
      await this.functions.httpsCallable(OnCallFunction.Technician, 'updateUserSubscriptionCredit')(data);
      return;
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async updateUserDiscount(
    uid: string,
    providerId: string | undefined,
    accountType: AccountType,
    coupon: ProviderCoupon,
  ): Promise<void> {
    try {
      const data = { uid, providerId, accountType, coupon };
      await this.functions.httpsCallable(OnCallFunction.Technician, 'updateUserSubscriptionDiscount')(data);
      return;
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getSupportTicket(
    ticketId: string,
  ): Promise<{ supportTicket: SupportTicket, user: User, person: Person }> {
    try {
      const data = { ticketId };
      const resp = await this.functions.httpsCallable(OnCallFunction.Technician, 'getSupportTicket')(data);
      return {
        supportTicket: SupportTicketFactory.build(resp.data.supportTicket),
        user: UserFactory.build(resp.data.user),
        person: PersonFactory.build(resp.data.person),
      };
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getUserSubscriptionDetails(uid: string): Promise<StripeSubscription | undefined> {
    try {
      const data = { uid };
      const resp = await this.functions.httpsCallable(OnCallFunction.Technician, 'getUserSubscriptionDetails')(data);
      return resp.data.subscription;
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  private static conformReducedProvider(provider: ProviderJson) {
    provider.definedCallTypes = provider.definedCallTypes ?? [];
    provider.prnContacts = provider.prnContacts ?? {};
    provider.providerContacts = provider.providerContacts ?? {};
  }

  public async setLicenses(uid: string, licenses: License[]): Promise<void> {
    try {
      const data = { uid, licenses: licenses.map(l => l.json()) };
      await this.functions.httpsCallable(OnCallFunction.Technician, 'setLicenses')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async generateInviteCode(providerId: string): Promise<string> {
    try {
      const data = { providerId };
      const resp = await this.functions.httpsCallable(OnCallFunction.Technician, 'generateInviteCode')(data);
      return resp.data.inviteCode;
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async setInviteCode(providerId: string, inviteCode: string | null): Promise<void> {
    try {
      const data = { providerId, inviteCode };
      await this.functions.httpsCallable(OnCallFunction.Technician, 'setInviteCode')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getFormsForProvider(providerId: string): Promise<ClientCustomForm[]> {
    try {
      const data = { providerId };
      const res = await this.functions.httpsCallable(OnCallFunction.Technician, 'getFormsForProvider')(data);
      const customForms = res.data.customForms.map(
        (f: ClientCustomFormJson) => CustomFormFactory.buildClient(f),
      );
      return customForms;
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async upsertCustomForm(form: ClientCustomForm): Promise<ClientCustomForm> {
    try {
      const data: any = { ...form.json() };
      delete data.createdAt;
      delete data.updatedAt;
      delete data.deletedAt;
      const res = await this.functions.httpsCallable(OnCallFunction.Technician, 'setCustomForm')(data);
      return CustomFormFactory.buildClient(res.data.customForm);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async deleteCustomForm(form: ClientCustomForm): Promise<void> {
    try {
      const data = {
        formId: form.root.id,
        providerId: form.providerId,
      };
      await this.functions.httpsCallable(OnCallFunction.Technician, 'deleteCustomForm')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }
}
