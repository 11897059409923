import { ZoomMeeting } from '@pocketrn/entities/dist/meeting';

export const getZoomUrl = (zoom: ZoomMeeting): string => {
  return `https://zoom.us/j/${zoom.id}${getPasswordParam(zoom)}`;
};

const getPasswordParam = (zoom: ZoomMeeting) => {
  if (zoom.encryptedPassword) {
    return `?pwd=${zoom.encryptedPassword}`;
  }
  return '';
};

export const openZoom = (zoom: ZoomMeeting): void => {
  window.open(getZoomUrl(zoom));
};
