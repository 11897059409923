import { Account, AccountStatus } from '@pocketrn/entities/dist/core';
import { SessionUserController } from '../../apps/user-state/index';
import { CoreSDK } from '../../services/firebase/CoreSDK';
import { ManagedProperty } from '@pocketrn/client/dist/entity-sdk';

// @NOTE: Redux does not export its Store type.
export type ReduxStore = any;

export class AccountController {
  public sdk: CoreSDK;
  public store: ReduxStore;
  public sessionUserController: SessionUserController;

  constructor(sdk: CoreSDK, store: ReduxStore, sessionUserController: SessionUserController) {
    this.sdk = sdk;
    this.store = store;
    this.sessionUserController = sessionUserController;
  }

  public async approveAccount(
    account: Account,
    managed?: ManagedProperty,
    isActionLink?: boolean,
  ): Promise<void> {
    if (!managed) {
      this.sessionUserController.setStoredAccountLoading(true);
    }
    await this.sdk.ackAccountInvitation(
      AccountStatus.Approved,
      account.type,
      account.providerId,
      managed,
    );
    if (!managed && !isActionLink) {
      await this.sessionUserController.setActiveAccount(account);
      this.sessionUserController.setStoredAccountLoading(false);
    }
  }
}
