import React, { ReactElement, FunctionComponent, ReactNode } from 'react';
import styles from './ChatContent.module.css';

interface Props {
  children?: ReactNode;
}

export const ChatContent: FunctionComponent<Props> = (props): ReactElement => {
  return (
    <div className={styles.wrapper}>
      {props.children}
    </div>
  );
};
