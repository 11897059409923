import { ReduxAction, Action } from '@pocketrn/client/dist/entity-sdk';
import { InviteCodeData } from '../../../entities/Router';

const ra = new ReduxAction('REGISTRATION');

export interface IsRegisteringAction extends Action {
  isRegistering: boolean;
}

export interface InviteCodeDataAction extends Action {
  inviteCodeData: InviteCodeData;
}

export const RegistrationActionId = {
  IS_REGISTERING: ra.actionType('IS_REGISTERING'),
  INVITE_CODE: ra.actionType('INVITE_CODE'),
};

export class RegistrationActions {
  public static setIsRegistering(isRegistering: boolean): IsRegisteringAction {
    return {
      type: RegistrationActionId.IS_REGISTERING,
      isRegistering,
    };
  }

  public static setInviteCodeData(
    inviteCodeData: InviteCodeData,
  ): InviteCodeDataAction {
    return {
      type: RegistrationActionId.INVITE_CODE,
      inviteCodeData,
    };
  }

  public static clearInviteCodeData(): InviteCodeDataAction {
    const inviteCodeData = {
      inviteCode: undefined,
      providerId: undefined,
    };
    return {
      type: RegistrationActionId.INVITE_CODE,
      inviteCodeData,
    };
  }
}
