import { RegistrationState } from './reducer';
import { InviteCodeData } from '../../../entities/Router';

export const selectIsRegistering = (
  state: { registration: RegistrationState },
): boolean => state.registration.isRegistering;

export const selectInviteCodeData = (
  state: { registration: RegistrationState },
): InviteCodeData => state.registration.inviteCodeData;

