import { Person, PersonFactory, PersonJson } from '@pocketrn/entities/dist/community';
import { Provider, ProviderFactory, ProviderJson, User, UserFactory, UserJson } from '@pocketrn/entities/dist/core';
import { CallTypeFactory, ClientCustomCallType, ClientCustomCallTypeJson, Meeting, MeetingFactory, MeetingJson, QueuedAcceptor, QueuedRequestor, QueueStats } from '@pocketrn/entities/dist/meeting';

export interface MatchMakingResponse {
  userMatch: UserMatch,
  queuedAcceptor?: QueuedAcceptor,
  queuedRequestor?: QueuedRequestor,
  queueStats?: QueueStats,
}

export interface UserMatchJson {
  users: UserJson[];
  persons: PersonJson[];
  meetings: MeetingJson[];
  providers: Record<string, ProviderJson>;
  customCallTypes: Record<string, ClientCustomCallTypeJson>;
}

export interface UserMatch {
  users: User[];
  persons: Person[];
  meetings: Meeting[];
  providers: Record<string, Provider>;
  customCallTypes: Record<string, ClientCustomCallType>;
}

export const buildUserMatch = (userMatchJson: UserMatchJson) : UserMatch => {
  return {
    users: userMatchJson.users.map(user => UserFactory.build(user)),
    persons: userMatchJson.persons.map(person => PersonFactory.build(person)),
    meetings: userMatchJson.meetings.map(meeting => MeetingFactory.build(meeting)),
    providers: Object.keys(userMatchJson.providers).reduce((acc, key) => {
      acc[key] = ProviderFactory.build(userMatchJson.providers[key]);
      return acc;
    }, {} as Record<string, Provider>),
    customCallTypes: Object.keys(userMatchJson.customCallTypes).reduce((acc, key) => {
      acc[key] = CallTypeFactory.buildClientCustom(userMatchJson.customCallTypes[key]);
      return acc;
    }, {} as Record<string, ClientCustomCallType>),
  };
};
