import { communitySDK, coreSDK, sessionUserSDK } from '../../services/firebase';
import store from '../../utils/store';
import { sessionUserController } from '../../utils/userStateInit';
import { AccountController } from './Account.controller';
import { ProfileController } from './Profile.controller';
import { RegistrationController } from './Registration.controller';
import { UserController } from './User.controller';
import { LinkedUserController } from './LinkedUser.controller';
import { firestore } from '../../utils/firebase';
import { ActionLinkController } from './ActionLink.controller';

export const accountController = new AccountController(coreSDK, store, sessionUserController);
export const profileController = new ProfileController(
  coreSDK,
  communitySDK,
  store,
  sessionUserController,
);
export const registrationController = new RegistrationController(
  coreSDK,
  store,
  sessionUserController,
  sessionUserSDK,
);
export const userController = new UserController(
  coreSDK,
  store,
  sessionUserController,
  communitySDK,
  firestore,
);
export const actionLinkController = new ActionLinkController(coreSDK);

export const linkedUserController = new LinkedUserController(coreSDK, store, sessionUserController);
