import { LocalePrimer } from '@pocketrn/localizer';
import React, { FunctionComponent, ReactElement } from 'react';
import styles from './ChatTextDate.module.css';
import { TimeUtils } from '@pocketrn/time-utils';
import { useSelector } from 'react-redux';
import { selectSessionUser } from '../../../../user-state';

interface ChatTextDateProps {
  date: Date;
  localePrimer: LocalePrimer;
}

export const ChatTextDate: FunctionComponent<ChatTextDateProps> = (props): ReactElement => {
  const sessionUser = useSelector(selectSessionUser);
  return (
    <div className={styles.date}>
      {props.localePrimer.translate('format.date.onlyDate')(
        props.date,
        sessionUser ?
          TimeUtils.getIntlTimeZone(sessionUser.getTimezoneOrDefault({ getFromBrowser: true })) :
          undefined,
      )}
    </div>
  );
};
