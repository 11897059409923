import {
  localizer,
  LanguageCode,
  Language,
  LanguageProficiency,
  locales as localizerLocales,
} from '@pocketrn/localizer';
import { locales as coreLocales } from '../locales/en';
import { locales as messagingAppLocales } from '../apps/messaging-app/index';
import { locales as rnDesignSystemLocales } from '@pocketrn/rn-designsystem';
import { locales as technicianAppLocales } from '../apps/technician-app/index';
import { locales as helpAppLocales } from '../apps/help-app/index';
import { locales as meetingAppLocales } from '../apps/meeting-app/index';
import { locales as formLocales } from '@pocketrn/client/dist/app-utils';

const _locales = {
  ...rnDesignSystemLocales,
  ...formLocales,
  ...helpAppLocales,
  ...technicianAppLocales,
  ...messagingAppLocales,
  ...meetingAppLocales,
  ...coreLocales,
  ...localizerLocales,
};

localizer.setLocale(LanguageCode.English, _locales);
localizer.useFallbackLocale(LanguageCode.English);

export const userLanguages = [new Language(LanguageCode.English, LanguageProficiency.Native)];
const localePrimer = localizer.primeLocale(userLanguages);

export default localePrimer;
