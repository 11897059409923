import React, { ReactElement } from 'react';
import styles from './NavBar.module.css';
import localePrimer from '../../../utils/localePrimer';
import {
  Button,
  Logo,
  useIsMobile,
} from '@pocketrn/rn-designsystem';

export const NavBar = (): ReactElement => {
  const isMobile = useIsMobile();

  const getWrapperStyles = (): string => {
    const s = [styles.wrapper];
    if (isMobile) {
      s.push(styles.mobile);
    }
    return s.join(' ');
  };

  return (
    <nav className={getWrapperStyles()}>
      <Logo isMobile={isMobile} localePrimer={localePrimer} />
      <Button type="text" link="https://www.pocketrn.com" rightArrow>
        {localePrimer.translate('core.login.goToMarketingSite')}
      </Button>
    </nav>
  );
};

export default NavBar;
