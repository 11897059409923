import React, { FunctionComponent, ReactElement, useState } from 'react';
import { Button, PageBanner } from '@pocketrn/rn-designsystem';
import { useSelector } from 'react-redux';
import { AccountType, isRequestor } from '@pocketrn/entities/dist/core';
import { CoreRoute } from '../../../../entities/Router';
import { useNavigate } from 'react-router-dom';
import { selectSessionUser } from '../../../user-state';
import localePrimer from '../../../../utils/localePrimer';

const locale = 'meeting.main.requestor.subscriptionBanner';

const SubscriptionBanner: FunctionComponent = (): ReactElement | null => {
  const [ dismissed, setDismissed ] = useState(false);
  const navigate = useNavigate();

  const sessionUser = useSelector(selectSessionUser);
  const expiredSubscription = (
    sessionUser &&
    !sessionUser.stripe?.getActiveProductKeys().length &&
    isRequestor(sessionUser.activeAccountType)
  );
  const upgrade = () => {
    navigate(CoreRoute.Subscription);
  };

  if (!expiredSubscription || dismissed || sessionUser.activeAccountType !== AccountType.Patient) {
    return null;
  }

  return (
    <PageBanner
      type="error"
      text={localePrimer.translate(locale, 'content')}
      actionButton={
        <Button
          type="primary"
          onClick={upgrade}
          testIdRoot="expiredSubscriptionBanner-upgrade-btn"
          submitOnEnter
        >
          {localePrimer.translate(locale, 'upgrade')}
        </Button>
      }
      onClose={() => setDismissed(true)}
    />
  );
};

export default SubscriptionBanner;
