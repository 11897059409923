import { State, StateJson } from '@pocketrn/entities/dist/core';
import { Action } from '@pocketrn/client/dist/entity-sdk';
import { SetState, ClearState, UserStateActionId } from './actions';

export interface UserStateState {
  state: Record<string, StateJson>;
}

const initalState: UserStateState = { state: {} };

const userStateReducer = (state = initalState, action: Action): UserStateState => {
  const newState = state;
  switch (action.type) {
    case UserStateActionId.SET_STATE_VALUE:
      return UserState.setState(newState, action as SetState);
    case UserStateActionId.CLEAR_STATE_VALUE:
      return UserState.clearState(newState, action as ClearState);
    default:
      return state;
  }
};

class UserState {
  public static setState(
    state: UserStateState,
    action: SetState,
  ): UserStateState {
    const value = action.value.json();
    return {
      ...state,
      state: {
        ...state.state,
        [action.key]: value,
      },
    };
  }

  public static clearState(
    state: UserStateState,
    action: ClearState,
  ): UserStateState {
    const value = new State().json();
    return {
      ...state,
      state: {
        ...state.state,
        [action.key]: value,
      },
    };
  }
}

export default userStateReducer;
