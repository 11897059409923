import { Counter, isActiveInstance } from '@pocketrn/rn-designsystem';
import { isWindowFocused } from '@pocketrn/client/dist/app-utils';
import { logger } from '@pocketrn/client/dist/app-logger';
import { State, StateFactory, UserState } from '@pocketrn/entities/dist/core';
import { FirebaseUser } from '../firebase/FirebaseUser';
import { REDUCER_KEY, UserStateActions } from './actions';
import { UserStateSDK } from './sdk';
import { REDUCER_KEY as FIREBASE_USER_REDUCER_KEY } from '../firebase/actions';
import { FirebaseUserState } from '../firebase/reducer';
import { UserStateState } from './reducer';

const PING_DELAY = 30;

// @NOTE: Redux does not export its Store type.
export type ReduxStore = any;

export class UserStateController {
  public store: ReduxStore;
  private counter: Counter;
  public userStateSDK: UserStateSDK;

  constructor(
    userStateSDK: UserStateSDK,
    store: ReduxStore,
  ) {
    this.userStateSDK = userStateSDK;
    this.store = store;
    this.counter = new Counter();
  }

  public startPing(): void {
    // @NOTE: this would mean we have concurrent requests, so
    // we should kill this one since there is one already in progress
    if (this.counter.hasTick) {
      return;
    }
    // @SOURCE: https://stackoverflow.com/questions/7890685/referencing-this-inside-setinterval-settimeout-within-object-prototype-methods/7890978
    this.counter.startTick(async () => this.ping(), PING_DELAY * 1000);
  }

  public stopPing(): void {
    this.counter.stopTick();
  }

  private resetPing(): void {
    this.stopPing();
    this.startPing();
  }

  private async ping(key?: string, state?: State): Promise<void> {
    if (!isWindowFocused() || !isActiveInstance()) {
      return;
    }
    try {
      const userState = this.buildUserState();
      if (key) {
        if (state) {
          userState.states[key] = state;
        } else {
          userState.states[key] = new State();
        }
      }
      await this.userStateSDK.pingUserState(userState);
    } catch (err) {
      logger.logError(err);
    }
  }

  private firebaseUserState(): FirebaseUserState {
    return this.store.getState()[FIREBASE_USER_REDUCER_KEY];
  }

  public getStoredFirebaseUser(): FirebaseUser | undefined {
    return this.firebaseUserState().user;
  }

  private buildUserState(): UserState {
    const uid = this.getStoredFirebaseUser()?.uid;
    if (!uid) {
      throw new Error('firebase user does not have a uid');
    }
    const updatedAt = new Date();
    const state = this.getStoredState();
    const userState = new UserState(uid, updatedAt, state);
    return userState;
  }

  private state(): UserStateState {
    return this.store.getState()[REDUCER_KEY];
  }

  private getStoredState(): Record<string, State> {
    const state: Record<string, State> = {};
    const json = this.state().state;
    const keys = Object.keys(json);
    for (const key of keys) {
      state[key] = StateFactory.build(json[key]);
    }
    return state;
  }

  public updateState(key: string, state: State, sendPing?: boolean): void {
    this.store.dispatch(UserStateActions.setState(key, state));
    if (sendPing) {
      this.ping(key, state);
      this.resetPing();
    }
  }

  public clearState(key: string, sendPing?: boolean): void {
    this.store.dispatch(UserStateActions.clearState(key));
    if (sendPing) {
      this.ping(key);
      this.resetPing();
    }
  }
}
