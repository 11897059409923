import { useEffect } from 'react';
import { pageController } from './pageStateInit';
import { PageLoadingDetails } from '@pocketrn/rn-designsystem';

/**
 * @param loading if boolean, will set page loading state;
 * if null it will ignore the set.  This is useful for conditional
 * cases for calling the hook since React hooks cannot normally
 * be called conditionally.
 */
export const usePageLoading = (
  loading: boolean | null | PageLoadingDetails,
): void => {
  useEffect(() => {
    if (loading === null) {
      return;
    }
    pageController.setLoading(loading);
    return function cleanup() {
      pageController.setLoading(false);
    };
  }, [loading]);
};
