import { ListActionItem } from '@pocketrn/rn-designsystem';
import React, { ReactElement, FunctionComponent, useEffect, useState } from 'react';

interface Props {
  label: string;
  stringifiedFile: string;
  type: 'csv';
  disabled?: boolean;
  loading?: boolean;
}

export const StringFileDownload: FunctionComponent<Props> = (
  props,
): ReactElement => {
  const content = props.stringifiedFile;
  const type = props.type;
  const [ url, setUrl ] = useState('');

  useEffect(() => {
    let blob;
    switch (type) {
      case 'csv':
        blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
        break;
      default:
        throw new Error(`unexpected type: ${type}`);
    }
    setUrl(URL.createObjectURL(blob));
  }, [ content, type ]);

  return (
    <ListActionItem
      title={props.label}
      loading={props.loading}
      disabled={props.disabled}
      link={url}
      linkDownload={props.label}
      testIdRoot={'listActionItem-' + props.label}
    />
  );
};
