import { Auth } from 'firebase/auth';
import { Feature, FeatureProvider, FeatureUser } from './Feature';
import { buildFeature, buildFeatureProvider, buildFeatureUser } from './Feature.factory';
import { FeatureJson, FeatureProviderJson, FeatureUserJson, FeatureValue } from './Feature.json';
import { FirebaseFunctionInterface, FirebaseSDK, OnCallFunction } from '@pocketrn/client/dist/entity-sdk';

export class FeatureFlagSDK extends FirebaseSDK {
  constructor(functions: FirebaseFunctionInterface, firebaseAuth: Auth) {
    super(functions, firebaseAuth);
  }

  public async getFlags(providerId?: string): Promise<{
    features: Feature[],
    featureProviders: FeatureProvider[],
    featureUsers: FeatureUser[],
  }> {
    try {
      const resp = await this.functions.httpsCallable(OnCallFunction.Technician, 'getFeatureFlags')(
        { providerId: providerId ?? null },
      );
      return {
        features: resp.data.features.map((f: FeatureJson) => buildFeature(f)),
        featureProviders: resp.data.featureProviders.map((f: FeatureProviderJson) => {
          return buildFeatureProvider(f);
        }),
        featureUsers: resp.data.featureUsers.map((f: FeatureUserJson) => buildFeatureUser(f)),
      };
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async setFlag(
    id: string,
    groupId: string,
    value: FeatureValue,
    priority: number | null,
    uid?: string,
    providerId?: string,
  ): Promise<void> {
    try {
      await this.functions.httpsCallable(OnCallFunction.Technician, 'setFeatureFlag')({
        id,
        groupId,
        value,
        priority,
        providerId: providerId ?? null,
        uid: uid ?? null,
      });
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getFlagScopes(id: string, groupId: string): Promise<{
    feature: Feature,
    featureProviders: FeatureProvider[],
    featureUsers: FeatureUser[],
  }> {
    try {
      const resp = await this.functions.httpsCallable(OnCallFunction.Technician, 'getFlagScopes')({
        id,
        groupId,
      });
      return {
        feature: buildFeature(resp.data.feature),
        featureProviders: resp.data.featureProviders.map((f: FeatureProviderJson) => {
          return buildFeatureProvider(f);
        }),
        featureUsers: resp.data.featureUsers.map((f: FeatureUserJson) => buildFeatureUser(f)),
      };
    } catch (err) {
      throw await this.handleErr(err);
    }
  }
}
