import React, { ReactElement, FunctionComponent } from 'react';
import { ChatContent } from '../ds';
import { WindowType } from '../entities/enums/WindowType';
import ChatBotContent from './content/ChatBotContent';
import UserChatsContent from './content/UserChatsContent';
import NotificationsContent from './content/NotificationsContent';
import { MessagesController } from '../state/controllers/Messages.controller';
import { LocalePrimer } from '@pocketrn/localizer';
import { BotResponseLibrary } from '../utils/BotResponseLibrary';

interface Props {
  windowType: WindowType;
  close: () => void;
  messagesController: MessagesController;
  localePrimer: LocalePrimer;
  botResponseLibrary: BotResponseLibrary;
  isOpen: boolean;
}

const ChatDrawerContent: FunctionComponent<Props> = (props): ReactElement => {
  const getContent = () => {
    switch (props.windowType) {
      case WindowType.Notifications:
        return (
          <NotificationsContent
            messagesController={props.messagesController}
            localePrimer={props.localePrimer}
          />
        );
      case WindowType.Bot:
        return (
          <ChatBotContent
            close={props.close}
            botResponseLibrary={props.botResponseLibrary}
            localePrimer={props.localePrimer}
            isOpen={props.isOpen}
          />
        );
      case WindowType.Messages:
      case WindowType.Message:
        return (
          <UserChatsContent
            messagesController={props.messagesController}
            localePrimer={props.localePrimer}
            isOpen={props.isOpen}
          />
        );
      default:
        throw new Error(`unexpected window type: ${props.windowType}`);
    }
  };

  return (
    <ChatContent>
      {getContent()}
    </ChatContent>
  );
};

export default ChatDrawerContent;
