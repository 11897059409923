import { FirebaseUserActionId, SetUser } from './actions';
import { FirebaseUser } from './FirebaseUser';
import { Action } from '@pocketrn/client/dist/entity-sdk';

export interface FirebaseUserState {
  user: FirebaseUser | undefined;
}

const initialState: FirebaseUserState = { user: undefined };

const firebaseUserReducer = (state = initialState, action: Action): FirebaseUserState => {
  const newState = state;
  switch (action.type) {
    case FirebaseUserActionId.UNSET_USER:
      return State.unsetUser(newState);
    case FirebaseUserActionId.SET_USER:
      return State.setUser(newState, action as SetUser);
    default:
      return state;
  }
};

class State {
  public static unsetUser(state: FirebaseUserState): FirebaseUserState {
    return { ...state, user: undefined };
  }

  public static setUser(
    state: FirebaseUserState,
    action: SetUser,
  ): FirebaseUserState {
    return { ...state, user: action.user };
  }
}

export default firebaseUserReducer;
