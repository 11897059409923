export class Tutorial {
  public videoLink?: string;
  public content?: string;
  public label?: string;
  public outdatedVideoLink?: boolean;

  constructor(
    videoLink?: string,
    content?: string,
    label?: string,
    outdatedVideoLink?: boolean,
  ) {
    this.videoLink = videoLink;
    this.label = label;
    this.content = content;
    this.outdatedVideoLink = outdatedVideoLink;
  }

  public json(): any {
    return {
      label: this.label,
      videoLink: this.videoLink,
      content: this.content,
      outdatedVideoLink: this.outdatedVideoLink,
    };
  }

  public copy(): Tutorial {
    return new Tutorial(this.videoLink, this.content, this.label, this.outdatedVideoLink);
  }
}
