import { PageRouter } from '@pocketrn/rn-designsystem';
import { Feature } from '../../feature-flags/src/Feature';

/**
 * @NOTE: when adding new routes, the route path is important to note:
 * 1. Anything that doesn't have subpaths is considered a root path and
 *    will not have a back button.
 * 2. Anything that has subpaths will have a back button that uses the path fragments
 *    as a breadcrumb for the back button.
 */
export enum RelativeTechnicianRoute {
  InviteUser = 'invite-user',
  ManageProviders = 'providers',
  ManageProvider = 'providers/:id',
  ManageProviderCallTypes = 'providers/:id/call-types',
  ManageProviderTranslations = 'providers/:id/translations',
  ManageProviderOperationHours = 'providers/:id/operation-hours',
  ManageProviderInviteCode = 'providers/:id/invite-code',
  ManageProviderCredit = 'providers/:id/credit',
  ManageProviderDiscount = 'providers/:id/discount',
  ManageProviderCustomForms = 'providers/:id/custom-forms',
  CreateProvider = 'providers/create',
  ManageUsers = 'users',
  PullStats = 'pull-stats',
  Escalate = 'escalate',
  ViewSupportTickets = 'support-tickets',
  ViewQueueData = 'queue-data',
  TechnicianExtras = 'extras',
  ManageFeatureFlags = 'feature-flags',
  CreateFeatureFlag = 'feature-flags/create',
  EditFeatureFlag = 'feature-flags/:name',
}

export enum TechnicianRoute {
  Main = '/main',
  InviteUser = '/main/invite-user',
  ManageProviders = '/main/providers',
  ManageProvider = '/main/providers/:id',
  ManageProviderCallTypes = '/main/providers/:id/call-types',
  ManageProviderTranslations = '/main/providers/:id/translations',
  ManageProviderOperationHours = '/main/providers/:id/operation-hours',
  ManageProviderInviteCode = '/main/providers/:id/invite-code',
  CreateProvider = '/main/providers/create',
  ManageUsers = '/main/users',
  PullStats = '/main/pull-stats',
  Escalate = '/main/escalate',
  ViewSupportTickets = '/main/support-tickets',
  ViewQueueData = '/main/queue-data',
  TechnicianExtras = '/main/extras',
  ManageFeatureFlags = '/main/feature-flags',
  CreateFeatureFlag = '/main/feature-flags/create',
  EditFeatureFlag = '/main/feature-flags/:name',
};

export default class TechnicianRouter extends PageRouter {
  constructor() {
    super('/');
  }

  public static getViewProviderRoute(providerId: string): string {
    return `/main/providers/${providerId}`;
  }

  public static getManageProviderCallTypesRoute(providerId: string): string {
    return `/main/providers/${providerId}/call-types`;
  }

  public static getManageProviderTranslationsRoute(providerId: string): string {
    return `/main/providers/${providerId}/translations`;
  }

  public static getManageProviderOperationHoursRoute(providerId: string): string {
    return `/main/providers/${providerId}/operation-hours`;
  }

  public static getManageProviderInviteCodeRoute(providerId: string): string {
    return `/main/providers/${providerId}/invite-code`;
  }

  public static getManageProviderCreditRoute(providerId: string): string {
    return `/main/providers/${providerId}/credit`;
  }

  public static getManageProviderDiscountRoute(providerId: string): string {
    return `/main/providers/${providerId}/discount`;
  }

  public static getManageProviderCustomFormsRoute(providerId: string): string {
    return `/main/providers/${providerId}/custom-forms`;
  }

  public static getViewFeatureFlagRoute(feature: Feature): string {
    return `/main/feature-flags/${feature.name}`;
  }
}
