import { ChatbotActions, REDUCER_KEY } from '../redux/chatbot/actions';
import { Text } from '../../entities/Text';
import { Notification, UserChats } from '@pocketrn/entities/dist/chatbot';
import { ChatbotState } from '../redux/chatbot/reducer';

// @NOTE: Redux does not export its Store type.
export type ReduxStore = any;

export class ChatbotController {
  public store: ReduxStore;

  constructor(store: ReduxStore) {
    this.store = store;
  }

  public setChatbotChat(chat: Text[]): void {
    this.store.dispatch(ChatbotActions.setChatbotChat(chat));
  }

  public addToChatbotChat(chat: Text[]): void {
    this.store.dispatch(ChatbotActions.addToChatbotChat(chat));
  }

  public countUnreadNotifications(notifications: Notification[]): void {
    let unreadCount = 0;
    notifications.forEach(n => {
      if (n.unread) {
        unreadCount++;
      }
    });
    this.setUnreadNotificationsCount(unreadCount);
  }

  private setUnreadNotificationsCount(count: number): void {
    this.store.dispatch(ChatbotActions.setUnreadNotificationsCount(count));
  }

  public countUnreadUserChats(userChats: UserChats): void {
    let unreadCount = 0;
    const keys = Object.keys(userChats.chats);
    for (const key of keys) {
      const currentCount = userChats.chats[key].unreadMessageCount;
      if (currentCount) {
        unreadCount += currentCount;
      }
    }
    this.setUnreadUserChatsCount(unreadCount);
  }

  private setUnreadUserChatsCount(count: number): void {
    this.store.dispatch(ChatbotActions.setUnreadUserChatsCount(count));
  }

  public toggleDrawer(shouldOpen?: boolean): void {
    if (shouldOpen === undefined) {
      this.store.dispatch(ChatbotActions.setDrawerIsOpen(!this.state().drawerIsOpen));
    } else {
      this.store.dispatch(ChatbotActions.setDrawerIsOpen(shouldOpen));
    }
  }

  private state(): ChatbotState {
    return this.store.getState()[REDUCER_KEY];
  }
}
