import { CoreSDK } from './CoreSDK';
import { FirebaseFunctionWrapper } from '@pocketrn/client/dist/entity-sdk';
import { firebaseAuth, firebaseFunctions } from '../../utils/firebase';
import { ChatbotSDK } from '../../apps/messaging-app/index';
import { TechnicianSDK } from '../../apps/technician-app/index';
import { HelpSDK } from '../../apps/help-app/index';
import { MeetingSDK } from '../../apps/meeting-app/index';
import { SessionUserSDK, UserStateSDK } from '../../apps/user-state/index';
import { CommunitySDK } from './CommunitySDK';
import { FeatureFlagSDK } from '../../apps/feature-flags/src/sdk';
import { SecureDraftSDK } from '../../apps/secure-drafts/src/sdk';

const firebaseFunctionsWrapper = new FirebaseFunctionWrapper(firebaseFunctions);

export const coreSDK = new CoreSDK(firebaseFunctionsWrapper, firebaseAuth);
export const communitySDK = new CommunitySDK(firebaseFunctionsWrapper, firebaseAuth);
export const chatbotSDK = new ChatbotSDK(firebaseFunctionsWrapper, firebaseAuth);
export const sessionUserSDK = new SessionUserSDK(firebaseFunctionsWrapper, firebaseAuth);
export const userStateSDK = new UserStateSDK(firebaseFunctionsWrapper, firebaseAuth);
export const technicianSDK = new TechnicianSDK(firebaseFunctionsWrapper, firebaseAuth);
export const helpSDK = new HelpSDK(firebaseFunctionsWrapper, firebaseAuth);
export const meetingSDK = new MeetingSDK(firebaseFunctionsWrapper, firebaseAuth);
export const featureFlagSDK = new FeatureFlagSDK(firebaseFunctionsWrapper, firebaseAuth);
export const secureDraftSDK = new SecureDraftSDK(firebaseFunctionsWrapper, firebaseAuth);
