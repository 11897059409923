import { TimeZone } from '@pocketrn/time-utils';
import { ActionKey, meetingActions } from '../redux/sdk/actions';
import { ManagedProperty, PRNErrorResponse } from '@pocketrn/client/dist/entity-sdk';
import { MeetingSDK } from '../../services/firebase/MeetingSDK';
import { Firestore } from 'firebase/firestore';
import { Meeting, ScheduledMeeting, MultiSelectResponse, ClientCustomCallType } from '@pocketrn/entities/dist/meeting';
import { Person } from '@pocketrn/entities/dist/community';
import { UserMatchController } from './UserMatch.controller';
import { Provider } from '@pocketrn/entities/dist/core';
import { logger } from '@pocketrn/client/dist/app-logger';

// @NOTE: Redux does not export its Store type.
export type ReduxStore = any;

export class MeetingController {
  public sdk: MeetingSDK;
  public store: ReduxStore;
  public firestore: Firestore;
  public userMatchController: UserMatchController;

  constructor(
    sdk: MeetingSDK,
    store: ReduxStore,
    firestore: Firestore,
    userMatchController: UserMatchController,
  ) {
    this.sdk = sdk;
    this.store = store;
    this.firestore = firestore;
    this.userMatchController = userMatchController;
  }

  public async finishMeeting(
    meetingId: string,
    nurseInputtedEndedAt?: Date,
  ): Promise<void> {
    await this.sdk.finishMeeting(meetingId, nurseInputtedEndedAt);
  }

  public async blockMatchedUser(blockUid: string, meetingId: string): Promise<void> {
    await this.sdk.blockUser(blockUid, meetingId);
  }

  public async sendMeetingFeedback(
    meetingId: string,
    feedbackValues: Record<string, number | MultiSelectResponse | null | string>,
    isAcceptor?: boolean,
  ): Promise<void> {
    if (isAcceptor) {
      await this.userMatchController.subscribeToSnapshotMeetings();
    }
    this.store.dispatch(meetingActions.setLoading(ActionKey.Meetings, true));
    await this.sdk.sendMeetingFeedback(meetingId, feedbackValues);
    this.store.dispatch(meetingActions.setLoading(ActionKey.Meetings, false));
  }

  public async clearQueueStats(): Promise<void> {
    await this.userMatchController.getAndSetUserMatch();
  }

  public async getZoomSessionToken(meetingId: string): Promise<string> {
    try {
      return await this.sdk.getZoomSessionToken(meetingId);
    } catch (err) {
      if (err instanceof PRNErrorResponse && err.code === 'SESSION_TOKEN') {
        // @TODO: UI response to error state
        logger.logError('SESSION_TOKEN ERROR:', err);
        throw err;
      } else {
        throw err;
      }
    }
  }

  public async getMeetingsHistory(options?: {
    limit?: { meetings?: number, scheduledMeetings?: number },
    managed?: ManagedProperty,
  }) : Promise<{
    meetings: Meeting[];
    canceledMeetings: ScheduledMeeting[];
    persons: Record<string, Person>;
    providers: Record<string, Provider>;
    customCallTypes: Record<string, ClientCustomCallType>;
  }> {
    return await this.sdk.getMeetingsHistory(options);
  }

  public async updateActiveTimeZone(timeZone: TimeZone): Promise<void> {
    await this.sdk.setActiveTimeZone(timeZone);
  }
}
