import { ReduxAction, SDKActionId, SDKActions } from '@pocketrn/client/dist/entity-sdk';

export const REDUCER_KEY = 'meeting';

const ra = new ReduxAction(REDUCER_KEY);

export enum ActionKey {
  QueueStats = 'queueStats',
  Shifts = 'shifts',
  Meetings = 'userMatch.meetings',
  Users = 'userMatch.users',
  Persons = 'userMatch.persons',
}

export const meetingActionId = new SDKActionId(ra);
export const meetingActions = new SDKActions(meetingActionId);
