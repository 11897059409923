import {
  ProviderJson,
} from '@pocketrn/entities/dist/core';
import { technicianActionId } from './actions';
import { SDKEntities, Action, getSDKReducerState } from '@pocketrn/client/dist/entity-sdk';

export interface TechnicianState {
  provider: SDKEntities<ProviderJson>;
}

const getInitialState = (): TechnicianState => {
  return {
    provider: { listEntities: [], mapEntities: {}, loading: false },
  };
};

const initialState = getInitialState();

const technicianReducer = (state = initialState, action: Action): TechnicianState => {
  return getSDKReducerState(state, action, technicianActionId, getInitialState) as TechnicianState;
};

export default technicianReducer;
