import { Feature, FeatureProvider, FeatureUser } from './Feature';
import { ReduxAction, Action } from '@pocketrn/client/dist/entity-sdk';

export const REDUCER_KEY = 'featureFlag';

const ra = new ReduxAction(REDUCER_KEY);

export interface Flags {
  features: Feature[];
  featureProviders: FeatureProvider[];
  featureUsers: FeatureUser[];
}

export interface SetFlags extends Action {
  flags: Flags;
}

export interface Initialized extends Action {
  initialized: boolean;
}

export const FeatureFlagActionId = {
  SET_FLAGS: ra.actionType('SET_FLAGS'),
  INITIALIZED: ra.actionType('INITIALIZED'),
};

export class FeatureFlagActions {
  public static setFlags(flags: Flags): SetFlags {
    return {
      type: FeatureFlagActionId.SET_FLAGS,
      flags,
    };
  }

  public static initialized(initialized: boolean): Initialized {
    return {
      type: FeatureFlagActionId.INITIALIZED,
      initialized,
    };
  }
}
