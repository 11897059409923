import { NavigateFunction } from 'react-router-dom';
import { ChoiceJson } from './interfaces/Choice.json';

export class Choice {
  public text: string;
  public onSelect: (navigate: NavigateFunction) => void;
  public close?: boolean;

  constructor(text: string, onSelect: (navigate: NavigateFunction) => void, close?: boolean) {
    this.text = text;
    this.onSelect = onSelect;
    this.close = close;
  }

  public json(): ChoiceJson {
    return { text: this.text };
  }
}
