import { ManagedProperty } from '@pocketrn/client/dist/entity-sdk';
import { AccountType } from '@pocketrn/entities/dist/core';

export function getManagedProperty(
  managedUid?: string,
  accountType?: AccountType,
  providerId?: string,
): ManagedProperty | undefined {
  if (!managedUid) {
    return undefined;
  }
  return {
    uid: managedUid,
    accountType: accountType || AccountType.Patient,
    providerId,
  };
}
