import {
  UserChatsJson,
  UserChatsFactory,
  UserChatJson,
  UserChatFactory,
  NotificationJson,
  NotificationFactory,
} from '@pocketrn/entities/dist/chatbot';
import { Person, PersonFactory, PersonJson } from '@pocketrn/entities/dist/community';
import { createSelector } from '@reduxjs/toolkit';
import { MessagesState } from './reducer';

const getNotificationListEntities = (
  state: { messages: MessagesState },
) => state.messages.notification.listEntities;

export const selectNotificationList = createSelector(
  [getNotificationListEntities],
  (notifications: NotificationJson[]) => {
    return notifications.map(n => NotificationFactory.build(n));
  },
);

export const selectNotificationLoading = (
  state: { messages: MessagesState },
): boolean => state.messages.notification.loading;

const getPersonMapEntities = (
  state: { messages: MessagesState },
) => state.messages.person.mapEntities;

export const selectPersonMap = createSelector(
  [getPersonMapEntities],
  (personMap: Record<string, PersonJson>) => {
    const keys = Object.keys(personMap);
    const map: Record<string, Person> = {};
    for (const key of keys) {
      map[key] = PersonFactory.build(personMap[key]);
    }
    return map;
  },
);

const getUserChatsActiveEntity = (
  state: { messages: MessagesState },
) => state.messages.userChats.activeEntity;

export const selectActiveUserChats = createSelector(
  [getUserChatsActiveEntity],
  (userChats: UserChatsJson | undefined) => {
    if (!userChats) {
      return undefined;
    }
    return UserChatsFactory.build(userChats);
  },
);

export const selectUserChatsLoading = (
  state: { messages: MessagesState },
): boolean => state.messages.userChats.loading;

const getUserChatActiveEntity = (
  state: { messages: MessagesState },
) => state.messages.userChat.activeEntity;

export const selectActiveUserChat = createSelector(
  [getUserChatActiveEntity],
  (userChat: UserChatJson | undefined) => {
    if (!userChat) {
      return undefined;
    }
    return UserChatFactory.build(userChat);
  },
);

export const selectUserChatLoading = (
  state: { messages: MessagesState },
): boolean => state.messages.userChat.loading;
