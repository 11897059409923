import { LocalePrimer } from '@pocketrn/localizer';
import { CameraIcon, SearchIcon, StatusBar, useTimeSince } from '@pocketrn/rn-designsystem';
import React, { FunctionComponent, ReactElement } from 'react';
import { CoreRoute } from '../../entities/Router';
import { useNavigate } from 'react-router-dom';
import { Meeting } from '@pocketrn/entities/dist/meeting';
import { MeetingRouter } from '../../apps/meeting-app';

interface Props {
  localePrimer: LocalePrimer;
  queueStartedAt?: Date;
  meeting?: Meeting,
}

export const PageStatusBarTickers: FunctionComponent<Props> = (props): ReactElement => {
  const navigate = useNavigate();
  const timeSinceEnteredQueue = useTimeSince(
    props.localePrimer,
    props.queueStartedAt ?? new Date(),
    undefined,
    true,
  );
  const timeSinceMeetingStarted = useTimeSince(
    props.localePrimer,
    props.meeting?.startedAt ?? new Date(),
    undefined,
    true,
  );
  const timeSinceMeetingCreated = useTimeSince(
    props.localePrimer,
    props.meeting?.createdAt ?? new Date(),
    undefined,
    true,
  );
  const timeSinceMeetingAccepted = useTimeSince(
    props.localePrimer,
    props.meeting?.acceptedAt ?? new Date(),
    undefined,
    true,
  );

  const goToMeeting = () => {
    if (!props.meeting) return;
    navigate(MeetingRouter.getMeetingRoute(props.meeting.id));
  };

  if (props.meeting?.startedAt) {
    return (
      <StatusBar
        icon={<CameraIcon blinking size="sm" />}
        message={props.localePrimer.translate('core.statusBar', 'inProgress')}
        onClick={goToMeeting}
        countdown={timeSinceMeetingStarted}
        color="success"
        testIdRoot="page-statusbar"
      />
    );
  } else if (props.meeting && !props.meeting.startedAt) {
    return (
      <StatusBar
        icon={<SearchIcon blinking size="sm" />}
        message={props.localePrimer.translate('core.statusBar', 'matchFound')}
        onClick={goToMeeting}
        countdown={props.meeting.acceptedAt ? timeSinceMeetingAccepted : timeSinceMeetingCreated}
        color="infoDark"
        testIdRoot="page-statusbar"
      />
    );
  } else if (props.queueStartedAt) {
    return (
      <StatusBar
        icon={<SearchIcon blinking size="sm" />}
        message={props.localePrimer.translate('core.statusBar', 'searching')}
        onClick={() => navigate(CoreRoute.MainPage)}
        countdown={props.meeting?.startedAt ? undefined : timeSinceEnteredQueue}
        color="infoDark"
        testIdRoot="page-statusbar"
      />
    );
  } else {
    throw new Error('PageStatusBarTickers: No status bar to show; please only call this component from PageStatusBar');
  }
};
