import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { ChatOverview } from '@pocketrn/entities/dist/chatbot';
import { useSelector } from 'react-redux';
import { UserChats, UserChat } from '../../ds';
import {
  selectActiveUserChat,
  selectActiveUserChats,
  selectUserChatLoading,
  selectUserChatsLoading,
} from '../../state/redux/messages/selectors';
import { useScrollToTarget } from '@pocketrn/rn-designsystem';
import { MessagesController } from '../../state/controllers/Messages.controller';
import { LocalePrimer } from '@pocketrn/localizer';

interface Props {
  messagesController: MessagesController;
  localePrimer: LocalePrimer;
  isOpen: boolean;
}

const UserChatsContent: FunctionComponent<Props> = (props): ReactElement => {
  const isOpen = props.isOpen;
  const userChats = useSelector(selectActiveUserChats);
  const userChat = useSelector(selectActiveUserChat);
  const userChatsLoading = useSelector(selectUserChatsLoading);
  const userChatLoading = useSelector(selectUserChatLoading);
  const [ shouldScrollToBottom, setShouldScrollToBottom ] = useState(true);
  const [ TopTarget, scrollToTopTarget ] = useScrollToTarget(0, true);
  const [ BottomTarget, scrollToBottomTarget ] = useScrollToTarget();
  const [ endOfUserChatHistory, setEndOfUserChatHistory ] = useState(false);
  const userChatLength = userChat?.messages?.length;

  useEffect(() => {
    props.messagesController.retrieveUserChats();
  }, []);

  useEffect(() => {
    if (userChatLength && shouldScrollToBottom && isOpen) {
      scrollToBottomTarget();
    }
    setShouldScrollToBottom(true);
  }, [ userChatLength, isOpen ]);

  useEffect(() => {
    if (!userChat) {
      setEndOfUserChatHistory(false);
    }
  }, [userChat]);

  const openUserChat = (chatOverview: ChatOverview) => {
    props.messagesController.retrieveUserChat(chatOverview.id);
  };

  const clearUserChat = () => {
    props.messagesController.clearUserChat();
  };

  const sendMessage = (message: string) => {
    if (!userChat?.id) {
      throw new Error('cannot send chat message when there is no userChat');
    }
    props.messagesController.sendMessage(message, userChat.id);
  };

  const handleScroll = async (atTop: boolean) => {
    if (atTop && !userChatLoading && userChat && !endOfUserChatHistory) {
      setShouldScrollToBottom(false);
      const pulledMessages = await props.messagesController.retrieveMessages(userChat.id);
      if (!pulledMessages) {
        setShouldScrollToBottom(true);
        setEndOfUserChatHistory(true);
      } else if (isOpen) {
        // @NOTE: if we pulled messages we don't want the app to scroll to the bottom so this
        // keeps the chat at the top of the old messages.
        scrollToTopTarget();
      }
    }
  };

  if (userChat || userChatLoading) {
    return (
      <UserChat
        chat={userChat}
        atEndOfChatHistory={endOfUserChatHistory}
        scrollToBottomTarget={BottomTarget}
        scrollToTopTarget={TopTarget}
        loading={userChatLoading}
        onBack={clearUserChat}
        onSendMessage={sendMessage}
        onScrollToTop={handleScroll}
        localePrimer={props.localePrimer}
      />
    );
  }

  return (
    <UserChats
      userChats={userChats}
      onOpenUserChat={openUserChat}
      loading={userChatsLoading}
      localePrimer={props.localePrimer}
    />
  );
};

export default UserChatsContent;
