import { Person, PersonFactory, PersonJson } from '@pocketrn/entities/dist/community';
import {
  Account,
  AccountFactory,
  AccountJson,
  ClientCustomForm,
  ClientCustomFormJson,
  ClientSignedCustomForm,
  ClientSignedCustomFormJson,
  CustomFormFactory,
  Provider,
  ProviderFactory,
  ProviderJson,
  SignedCustomFormFactory,
  User,
  UserFactory,
  UserJson,
} from '@pocketrn/entities/dist/core';
import { createSelector } from '@reduxjs/toolkit';
import { REDUCER_KEY, SessionReauthType } from './actions';
import { SessionUserState } from './reducer';
import { CallTypeFactory, ClientCustomCallType, ClientCustomCallTypeJson } from '@pocketrn/entities/dist/meeting';

const getPromptReauthentication = (
  state: { [REDUCER_KEY]: SessionUserState },
) => state[REDUCER_KEY].promptReauthentication;

export const selectPromptReauthentication = createSelector(
  [getPromptReauthentication],
  (prompt: {
    email?: string;
    type?: SessionReauthType | string;
  }): {
    email?: string;
    type?: SessionReauthType;
  } => {
    return {
      email: prompt.email,
      type: (prompt.type) as SessionReauthType,
    };
  },
);

const getAccountActiveEntity = (
  state: { [REDUCER_KEY]: SessionUserState },
) => state[REDUCER_KEY].account.activeEntity;
const getAccountListEntities = (
  state: { [REDUCER_KEY]: SessionUserState },
) => state[REDUCER_KEY].account.listEntities;

export const selectSessionAccount = createSelector(
  [getAccountActiveEntity],
  (account: AccountJson | undefined): Account | undefined => {
    if (!account) {
      return undefined;
    }
    return AccountFactory.build(account);
  },
);

export const selectSessionAccountLoading = (
  state: { [REDUCER_KEY]: SessionUserState },
): boolean => state[REDUCER_KEY].account.loading;

export const selectSessionUserAccounts = createSelector(
  [getAccountListEntities],
  (accountList: AccountJson[]): Account[] => {
    return accountList.map(account => AccountFactory.build(account));
  },
);

const getProviderActiveEntity = (
  state: { [REDUCER_KEY]: SessionUserState },
) => state[REDUCER_KEY].provider.activeEntity;
const getProviderMapEntities = (
  state: { [REDUCER_KEY]: SessionUserState },
) => state[REDUCER_KEY].provider.mapEntities;

export const selectSessionProvider = createSelector(
  [getProviderActiveEntity],
  (provider: ProviderJson | undefined): Provider | undefined => {
    if (!provider) {
      return undefined;
    }
    return ProviderFactory.build(provider);
  },
);

const getCustomCallTypeListEntities = (
  state: { [REDUCER_KEY]: SessionUserState },
) => state[REDUCER_KEY].customCallType.listEntities;

export const selectSessionUserCustomCallTypes = createSelector(
  [getCustomCallTypeListEntities],
  (customCallType: ClientCustomCallTypeJson[]): ClientCustomCallType[] => {
    return customCallType.map(c => CallTypeFactory.buildClientCustom(c));
  },
);

export const selectSessionProviderLoading = (
  state: { [REDUCER_KEY]: SessionUserState },
): boolean => state[REDUCER_KEY].provider.loading;

export const selectSessionUserProviderMap = createSelector(
  [getProviderMapEntities],
  (providerMap: Record<string, ProviderJson>): Record<string, Provider> => {
    const keys = Object.keys(providerMap);
    const map: Record<string, Provider> = {};
    for (const key of keys) {
      map[key] = ProviderFactory.build(providerMap[key]);
    }
    return map;
  },
);

const getUserActiveEntity = (
  state: { [REDUCER_KEY]: SessionUserState },
) => state[REDUCER_KEY].user.activeEntity;

export const selectSessionUser = createSelector(
  [getUserActiveEntity],
  (user: UserJson | undefined): User | undefined => {
    if (!user) {
      return undefined;
    }
    return UserFactory.build(user);
  },
);

export const selectSessionUserLoading = (
  state: { [REDUCER_KEY]: SessionUserState },
): boolean => state[REDUCER_KEY].user.loading;

const getPersonActiveEntity = (
  state: { [REDUCER_KEY]: SessionUserState },
) => state[REDUCER_KEY].person.activeEntity;

export const selectSessionPerson = createSelector(
  [getPersonActiveEntity],
  (person: PersonJson | undefined): Person | undefined => {
    if (!person) {
      return undefined;
    }
    return PersonFactory.build(person);
  },
);

export const selectSessionPersonLoading = (
  state: { [REDUCER_KEY]: SessionUserState },
): boolean => state[REDUCER_KEY].person.loading;

const getCustomFormsListEntities = (
  state: { [REDUCER_KEY]: SessionUserState },
) => state[REDUCER_KEY].customForms.listEntities;
const getCustomFormsMapEntities = (
  state: { [REDUCER_KEY]: SessionUserState },
) => state[REDUCER_KEY].customForms.mapEntities;
const getSignedCustomFormsListEntities = (
  state: { [REDUCER_KEY]: SessionUserState },
) => state[REDUCER_KEY].signedCustomForms.listEntities;
const getSignedCustomFormsMapEntities = (
  state: { [REDUCER_KEY]: SessionUserState },
) => state[REDUCER_KEY].signedCustomForms.mapEntities;

export const selectSessionUserCustomForms = createSelector(
  [getCustomFormsListEntities],
  (forms: ClientCustomFormJson[]): ClientCustomForm[] => {
    return forms.map(f => CustomFormFactory.buildClient(f));
  },
);

export const selectSessionUserCustomFormsMap = createSelector(
  [getCustomFormsMapEntities],
  (formsMap: Record<string, ClientCustomFormJson>): Record<string, ClientCustomForm> => {
    const keys = Object.keys(formsMap);
    const map: Record<string, ClientCustomForm> = {};
    for (const key of keys) {
      map[key] = CustomFormFactory.buildClient(formsMap[key]);
    }
    return map;
  },
);

export const selectSessionUserSignedCustomForms = createSelector(
  [getSignedCustomFormsListEntities],
  (forms: ClientSignedCustomFormJson[]): ClientSignedCustomForm[] => {
    return forms.map(f => SignedCustomFormFactory.buildClient(f));
  },
);

export const selectSessionUserSignedCustomFormsMap = createSelector(
  [getSignedCustomFormsMapEntities],
  (formsMap: Record<string, ClientSignedCustomFormJson>):
  Record<string, ClientSignedCustomForm> => {
    const keys = Object.keys(formsMap);
    const map: Record<string, ClientSignedCustomForm> = {};
    for (const key of keys) {
      map[key] = SignedCustomFormFactory.buildClient(formsMap[key]);
    }
    return map;
  },
);

export const selectSessionUserCustomFormsLoading = (
  state: { [REDUCER_KEY]: SessionUserState },
): boolean => state[REDUCER_KEY].customForms.loading;

export const selectSessionUserSignedCustomFormsLoading = (
  state: { [REDUCER_KEY]: SessionUserState },
): boolean => state[REDUCER_KEY].signedCustomForms.loading;
