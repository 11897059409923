import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectMeetingFound } from '../../state/redux/userMatch/selectors';
import {
  Button,
  Modal,
  ModalFooter,
  Paragraph,
} from '@pocketrn/rn-designsystem';
import MeetingRouter, { MeetingRoute } from '../../entities/Router';
import { LocalePrimer } from '@pocketrn/localizer';
import { UserMatchController } from '../../state/controllers/UserMatch.controller';
import { openZoom } from '../../utils/zoomHelpers';
import { selectUserMatchMeetingsList } from '../../state/redux/sdk/selectors';
import { selectSessionUser } from '../../../user-state';
import { relevantMeetings } from '../../../../utils/myMeetings';

const locale = 'meeting.modal';

interface Props {
  localePrimer: LocalePrimer;
  userMatchController: UserMatchController;
}

const UserMeetingModal: FunctionComponent<Props> = (props): ReactElement => {
  const navigate = useNavigate();
  const meetingFound = useSelector(selectMeetingFound);
  const user = useSelector(selectSessionUser);
  const [ isOpened, setIsOpened ] = useState(false);
  const inProgressMeeting = useSelector(selectUserMatchMeetingsList);
  const _inProgressMeetings = relevantMeetings(inProgressMeeting, user);
  const currentMeeting = _inProgressMeetings.length === 1 ? _inProgressMeetings[0] : null;

  useEffect(() => {
    setIsOpened(meetingFound);
  }, [meetingFound]);

  const closeModal = () => {
    props.userMatchController.ackMeetingFound();
  };

  const reviewDetails = () => {
    navigate(MeetingRoute.Main);
    closeModal();
  };

  const joinMeeting = () => {
    closeModal();
    if (!currentMeeting?.id) {
      throw new Error('meeting does not have an id');
    }
    // @FEATURE: useZoomVideoSession: false
    if (currentMeeting?.zoom) {
      navigate(MeetingRoute.Main);
      openZoom(currentMeeting.zoom);
    } else {
      navigate(MeetingRouter.getMeetingRoute(currentMeeting.id));
    }
  };

  return (
    <Modal
      onClose={closeModal}
      hide={!isOpened}
      clickAway
      constrainedSize="md"
      footer={
        <ModalFooter
          buttons={[
            <Button
              key={0}
              type="text"
              onClick={reviewDetails}
              testIdRoot="meetingModal-reviewDetails-btn"
            >
              {props.localePrimer.translate(locale, 'meeting.reviewDetails')}
            </Button>,
            <Button key={1} type="primary" onClick={joinMeeting}>
              {props.localePrimer.translate(locale, 'meeting.joinMeeting')}
            </Button>,
          ]}
        />
      }
      title={props.localePrimer.translate(locale, 'meeting.title')}
    >
      <Paragraph text={props.localePrimer.translate(locale, 'meeting.content')} />
    </Modal>
  );
};

export default UserMeetingModal;
