/* eslint-disable max-len, react/display-name, react/no-unescaped-entities, @typescript-eslint/explicit-module-boundary-types, react/jsx-no-literals, react/jsx-tag-spacing, react/self-closing-comp */
export const meetingWithRequestor = `
  This tutorial will guide you through the steps to meeting with patients and caregivers on PocketRN.  It will include several tips to help ensure you have the best experience possible so PocketRN can be a friendly tool rather than an operational burden.
  #### Prepare for a Meeting
  Before meeting with patients, you’ll need to ensure that you have at least one care service selected in [Manage Services](/main/manage-services).  You will only be matched with patients that are requesting help for one of the services you have selected.  To maximize the number of patients you can be matched with, we recommend that you enable all services that you are comfortable helping with.
  Additionally, you may be waiting in the matchmaking queue for some time before being matched with a patient.  To ensure you never miss a meeting invite, we recommend that you go to the **[Profile Page](/profile)** and provide the number of a phone that you keep at your side and that can receive text messages.  If you haven’t added a phone previously, you should add one now by selecting [Add Phone Number](/profile/edit-phone).
  You are now ready to start meetings.  Go to the **[Home Page](/main)** and select, **Meet with Patients**.  Once selected, you’ll join the matchmaking queue.
  #### Wait in the Queue
  The queue handles requests on a first-come-first-first basis, so the longer you wait in the queue the more likely you’ll be matched with the next appropriate patient.  While in the queue, you can navigate to other parts of PocketRN, change your browser tab, use other applications, or even close the PocketRN window without leaving the queue.  If you close the window and come back to PocketRN, you will still be in the queue. Once you’re in the queue, you’ll remain in the queue until you click **Cancel** or until you match with a patient.
  If you are using a different browser tab, application, or window, if your device is asleep, or if PocketRN is closed, you will be notified via a text message when a match is found, with a link to PocketRN that you can use to review the match. If you cannot review or approve the match, don't worry, the patient is not aware of the match until you accept it, and there is no penality for missing or declining a potential match. If you do not respond to the text message within a few minutes, the match will be automatically declined, the patient will meet with the next available health professional, and you will be put back in the queue.
  #### Review the Match
  When reviewing the match if you decline the patient, you will not be matched with that patient again until you exit the queue. If you accept the patient, a Zoom link will be shared between you and the patient and you should immediately enter the Zoom meeting with the patient.
  #### Join the Zoom Meeting
  The patient may not be actively waiting for the meeting, if not, they will receive a text message to join from their phone.  You should give the patient a moment to join the Zoom call.  If the patient doesn’t join the meeting after a minute, on the PocketRN app, you can select **Finish Meeting**, which will give you the option to manually send a reminder to the patient’s phone.
  While on the Zoom call with the patient, please turn on your video and audio and be in a quiet well-lit room with few distractions and a stable internet connection.  We recommend joining the Zoom meeting from your laptop or a tablet placed on a solid surface.  Patients will typically join the meeting from their phone so they can move freely and direct the phone camera to areas of interest. 
  To help a patient who is struggling to use Zoom, we recommend that you familiarize yourself with Zoom on multiple devices.  Zoom is a very different experience on a computer, iPad, iPhone or Android phone.  You can familiarize yourself with the Zoom layout on each device by watching the [Using Zoom](/help/tutorials/TT_USEZOOM) tutorial videos.
  If you are unable to address the needs of the patient, advise them to seek medical help through their primary care or their designated home care team.  **If there is a medical emergency, immediately have the patient end the meeting to call 911.**
  #### Finish the Meeting
  When your care service is fulfilled, make sure to leave the Zoom meeting and return to the PocketRN app.  There you can select **Finish Meeting**.  You’ll be asked to provide some quick optional feedback about your experience with the match.  You can then either finish and close to meet with other patients, or **Report an Issue**.  Our support team will review your concerns and get back to within a couple business days.
  #### Block the Patient or Caregiver
  If you wish to never be matched to this patient/caregiver again, you can block them from the **Report Issue Page**.  The patient will still be able to meet with other professionals on PocketRN, but if you include any concerning information regarding the meeting in your issue report, we will take your feedback seriously in preventing the issue from occurring again.
  We hope this tutorial provided some helpful tools for you to have successful meetings on PocketRN.
`;
