import { User } from '@pocketrn/entities/dist/core';
import { Meeting, ScheduledMeeting } from '@pocketrn/entities/dist/meeting';

export function relevantMeetings(
  meetings: Meeting[],
  user: User | undefined,
): Meeting[] {
  if (!user) return [];
  return meetings.filter(m => {
    const isParticipant = m.participants.find(p => {
      return p.uid === user?.uid && p.root.accountType === user.activeAccountType;
    }) !== undefined;
    if (isParticipant) return true;
    if (
      m.requestor.uid === user.uid &&
      m.requestor.accountType === user.activeAccountType
    ) {
      return true;
    }
    if (
      m.acceptor.uid === user.uid &&
      m.acceptor.accountType === user.activeAccountType
    ) {
      return true;
    }
    return false;
  });
}

export function relevantScheduledMeetings(
  meetings: ScheduledMeeting[],
  user: User | undefined,
): ScheduledMeeting[] {
  if (!user) return [];
  return meetings.filter(m => {
    const isParticipant = m.participants.find(p => {
      return p.uid === user?.uid && p.root.accountType === user.activeAccountType;
    }) !== undefined;
    if (isParticipant) return true;
    if (
      m.requestorUid === user.uid &&
      m.requestorAccountType === user.activeAccountType
    ) {
      return true;
    }
    if (
      m.acceptorUid === user.uid &&
      m.acceptorAccountType === user.activeAccountType
    ) {
      return true;
    }
    return false;
  });
}

export function activelyParticipatingMeetings(
  meetings: Meeting[],
  user: User | undefined,
): Meeting[] {
  return relevantMeetings(meetings, user).filter(m => {
    if (!user) return false;
    if (m.acceptor.uid === user.uid) return true;
    if (m.requestor.uid === user.uid) return true;
    return false;
  });
};
