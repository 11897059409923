import React, { Fragment, FunctionComponent, lazy, ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { selectSessionUser } from '../user-state';
import { RouteProps } from './entities/RouteProps';
import { RelativeMeetingRoute } from './entities/Router';
import { isAcceptor } from '@pocketrn/entities/dist/core';

/* eslint-disable @typescript-eslint/promise-function-async */
const Main = lazy(() => import('./components/routes/MainRoute'));
const ManageCallTypes = lazy(() => import('./components/routes/ManageCallTypes'));
const AddShift = lazy(() => import('./components/routes/AddShiftRoute'));
const EditShift = lazy(() => import('./components/routes/EditShiftRoute'));
const ManageShifts = lazy(() => import('./components/routes/ManageShiftsRoute'));
/* eslint-enable @typescript-eslint/promise-function-async */

const RootPage: FunctionComponent<RouteProps> = (props): ReactElement => {
  const user = useSelector(selectSessionUser);

  useEffect(() => {
    props.sessionUserController.startPingRefreshAccounts();
    return () => {
      props.sessionUserController.stopPingRefreshAccounts();
    };
  }, []);

  return (
    <Routes>
      <Route index element={
        <Main {...props} />}
      />
      {user && isAcceptor(user?.activeAccountType) &&
        <Fragment>
          <Route path={RelativeMeetingRoute.ManageCallTypes} element={
            <ManageCallTypes {...props} />}
          />
          <Route path={RelativeMeetingRoute.ManageShifts} element={
            <ManageShifts {...props} />}
          />
          <Route path={RelativeMeetingRoute.AddShift} element={
            <AddShift {...props} />}
          />
          <Route path={RelativeMeetingRoute.EditShift} element={
            <EditShift {...props} />}
          />
        </Fragment>
      }
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default RootPage;
