import { ClientCustomNotified, ClientParticipant, ScheduledMeeting, ScheduledMeetingStatus, ClientCallType, ClientCustomCallType } from '@pocketrn/entities/dist/meeting';
import { AccountType, Provider, Region, User } from '@pocketrn/entities/dist/core';
import { AvailableNurseTimes, MeetingSDK } from '../../services/firebase/MeetingSDK';
import { Firestore } from 'firebase/firestore';
import { Person } from '@pocketrn/entities/dist/community';
import { SessionUserController } from '../../../user-state';
import { ManagedProperty } from '@pocketrn/client/dist/entity-sdk';

// @NOTE: Redux does not export its Store type.
type ReduxStore = any;

export class ScheduledMeetingsController {
  public sdk: MeetingSDK;
  public store: ReduxStore;
  public firestore: Firestore;
  public sessionUserController: SessionUserController;

  constructor(
    sdk: MeetingSDK,
    store: ReduxStore,
    firestore: Firestore,
    sessionUserController: SessionUserController,
  ) {
    this.sdk = sdk;
    this.store = store;
    this.firestore = firestore;
    this.sessionUserController = sessionUserController;
  }

  public async getScheduledMeetings(options?: { limit?: number, managed?: ManagedProperty })
  : Promise<{
    scheduledMeetings: ScheduledMeeting[];
    users: Record<string, User>;
    persons: Record<string, Person>;
    providers: Record<string, Provider>;
    customCallTypes: Record<string, ClientCustomCallType>;
  }> {
    return await this.sdk.getScheduledMeetings(options);
  }

  public async getAvailableNurses(
    providerId: string,
    requestedCallType: ClientCallType,
    region: Region,
    startAt: Date,
    endAt: Date,
    managed?: ManagedProperty,
  ): Promise<AvailableNurseTimes[]> {
    return await this.sdk.getAvailableNurses(
      providerId,
      requestedCallType,
      region,
      startAt,
      endAt,
      managed,
    );
  }

  public async createScheduledMeeting(
    providerId: string,
    meetingDate: Date,
    requestedCallType: ClientCallType,
    requestorNote: string | null,
    region: Region,
    participants: ClientParticipant[],
    customNotified: ClientCustomNotified[],
  ): Promise<ScheduledMeeting> {
    const managed = this.getManaged(participants);
    return await this.sdk.createScheduledMeeting(
      providerId,
      meetingDate,
      requestedCallType,
      requestorNote,
      region,
      participants,
      customNotified,
      managed,
    );
  }

  public async updateScheduledMeetingStatus(
    meetingId: string,
    status: ScheduledMeetingStatus,
    cancelationReason: string | null,
  ): Promise<void> {
    await this.sdk.updateScheduledMeetingStatus(meetingId, status, cancelationReason);
  }

  public getManaged(participants: ClientParticipant[]): ManagedProperty | undefined {
    const user = this.sessionUserController.getStoredActiveUser();
    const patientParticipant = participants.find(p => p.root.accountType === AccountType.Patient);
    if (!patientParticipant || patientParticipant.uid === user?.uid) {
      return undefined;
    }
    return {
      uid: patientParticipant.uid,
      accountType: patientParticipant.root.accountType,
      // @NOTE: We explicitely do not add the providerId because the patient may not have that
      // provider. We may be using the caregiver's instead.
    };
  }

  public async rescheduleMeeting(
    meetingId: string,
    providerId: string,
    meetingDate: Date,
    requestedCallType: ClientCallType,
    requestorNote: string | null,
    region: Region,
    participants: ClientParticipant[],
    customNotified: ClientCustomNotified[],
  ): Promise<ScheduledMeeting> {
    const managed = this.getManaged(participants);
    return await this.sdk.rescheduleMeeting(
      meetingId,
      providerId,
      meetingDate,
      requestedCallType,
      requestorNote,
      region,
      participants,
      customNotified,
      managed,
    );
  };

  public async updateParticipantsAndNotified(
    meetingId: string,
    participants: ClientParticipant[],
    customNotified: ClientCustomNotified[],
  ): Promise<void> {
    const managed = this.getManaged(participants);
    await this.sdk.updateParticipantsAndNotified(
      meetingId,
      participants,
      customNotified,
      managed,
    );
  };
}
