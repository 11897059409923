import { StripePrice, StripeProductKey, StripeSubscription, StripePaymentMethod, StripeInvoice, StripeCustomerBillingAddress, UserDiscount, SubscriptionDiscount } from '@pocketrn/entities/dist/core';
import { CoreSDK } from '../../services/firebase/CoreSDK';

// @NOTE: Redux does not export its Store type.
export type ReduxStore = any;

export class SubscriptionController {
  public sdk: CoreSDK;

  constructor(
    sdk: CoreSDK,
  ) {
    this.sdk = sdk;
  }

  public async getPrices(productKeys: StripeProductKey[]): Promise<StripePrice[]> {
    return await this.sdk.getPrices(productKeys);
  }

  public async getPaymentMethods(): Promise<StripePaymentMethod[]> {
    return await this.sdk.getPaymentMethods();
  }

  public async createSubscription(priceId: string): Promise<{subscriptionId: string}> {
    return await this.sdk.createSubscription(priceId);
  }

  public async createNewPaymentMethod(): Promise<{clientSecret: string, setupIntentId?: string}> {
    return await this.sdk.createNewPaymentMethod();
  }

  public async updateCustomerAddress(address: StripeCustomerBillingAddress) : Promise<void> {
    await this.sdk.updateCustomerAddress(address);
  }

  public async removePaymentMethods() : Promise<void> {
    await this.sdk.removePaymentMethods();
  }

  public async resumeSubscription(subscriptionId: string): Promise<void> {
    await this.sdk.resumeSubscription(subscriptionId);
  }

  public async cancelSubscription(subscriptionId: string): Promise<void> {
    await this.sdk.cancelSubscription(subscriptionId);
  }

  public async updateSubscription(
    subscriptionId: string,
    newProductKey: StripeProductKey,
  ): Promise<void> {
    await this.sdk.updateSubscription(subscriptionId, newProductKey);
  }

  public async getSubscriptions(): Promise<StripeSubscription[]> {
    return await this.sdk.getSubscriptions();
  }

  public async getUpcomingInvoice(subscriptionId: string): Promise<StripeInvoice> {
    return await this.sdk.getUpcomingInvoice(subscriptionId);
  }

  public async getSubscriptionDiscounts(): Promise<UserDiscount[]> {
    return await this.sdk.getSubscriptionDiscounts();
  }

  public async getUserDiscountsWithProviders(): Promise<SubscriptionDiscount[]> {
    return await this.sdk.getUserDiscountsWithProviders();
  }
}
