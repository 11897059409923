import { Person, PersonJson, PersonFactory } from '@pocketrn/entities/dist/community';
import {
  MeetingFactory,
  MeetingJson,
  QueueStats,
  QueueStatsFactory,
  QueueStatsJson,
  Shift,
  ShiftFactory,
  ShiftJson,
  ShiftType,
  SingleShiftFactory,
  SingleShiftJson,
} from '@pocketrn/entities/dist/meeting';
import { User, UserJson, UserFactory } from '@pocketrn/entities/dist/core';
import { createSelector } from '@reduxjs/toolkit';
import { MeetingState } from './reducer';

const getQueueStatsListEntities = (
  state: { meeting: MeetingState },
) => state.meeting.queueStats.listEntities;
const getQueueStatsActiveEntity = (
  state: { meeting: MeetingState },
) => state.meeting.queueStats.activeEntity;
const getQueueStatsMapEntities = (
  state: { meeting: MeetingState },
) => state.meeting.queueStats.mapEntities;

export const selectQueueStatsList = createSelector(
  [getQueueStatsListEntities],
  (queueStatsJsonList: QueueStatsJson[]) => {
    return queueStatsJsonList.map(queueStats => QueueStatsFactory.build(queueStats));
  },
);

export const selectActiveQueueStats = createSelector(
  [getQueueStatsActiveEntity],
  (queueStatsJson: QueueStatsJson | undefined) => {
    if (!queueStatsJson) {
      return undefined;
    }
    return QueueStatsFactory.build(queueStatsJson);
  },
);

export const selectQueueStatsMap = createSelector(
  [getQueueStatsMapEntities],
  (queuedStatsMap: Record<string, QueueStatsJson>) => {
    const keys = Object.keys(queuedStatsMap);
    const map: Record<string, QueueStats> = {};
    for (const key of keys) {
      map[key] = QueueStatsFactory.build(queuedStatsMap[key]);
    }
    return map;
  },
);

export const selectQueueStatsLoading = (
  state: { meeting: MeetingState },
): boolean => state.meeting.queueStats.loading;

const getShiftsListEntities = (
  state: { meeting: MeetingState },
) => state.meeting.shifts.listEntities;
const getShiftsActiveEntity = (
  state: { meeting: MeetingState },
) => state.meeting.shifts.activeEntity;
const getShiftsMapEntities = (
  state: { meeting: MeetingState },
) => state.meeting.shifts.mapEntities;

export const selectShiftsList = createSelector(
  [getShiftsListEntities],
  (shiftsList: ShiftJson[]) => {
    return shiftsList.map(shift => {
      switch (shift.type) {
        case ShiftType.Single:
          return SingleShiftFactory.build(shift as SingleShiftJson);
        default:
          return ShiftFactory.build(shift);
      }
    });
  },
);

export const selectActiveShift = createSelector(
  [getShiftsActiveEntity],
  (shift: ShiftJson | undefined) => {
    if (!shift) {
      return undefined;
    }
    switch (shift.type) {
      case ShiftType.Single:
        return SingleShiftFactory.build(shift as SingleShiftJson);
      default:
        return ShiftFactory.build(shift);
    }
  },
);

export const selectShiftsMap = createSelector(
  [getShiftsMapEntities],
  (shiftsMap: Record<string, ShiftJson>) => {
    const keys = Object.keys(shiftsMap);
    const map: Record<string, Shift> = {};
    for (const key of keys) {
      switch (shiftsMap[key].type) {
        case ShiftType.Single:
          map[key] = SingleShiftFactory.build(shiftsMap[key] as SingleShiftJson);
          break;
        default:
          map[key] = ShiftFactory.build(shiftsMap[key]);
      }
    }
    return map;
  },
);

export const selectShiftsLoading = (
  state: { meeting: MeetingState },
): boolean => state.meeting.shifts.loading;

const getMeetings = (
  state: { meeting: MeetingState },
) => state.meeting.userMatch.meetings.listEntities;

export const selectUserMatchMeetingsList = createSelector(
  [getMeetings],
  (meetings: MeetingJson[]) => {
    return meetings.map(meeting => MeetingFactory.build(meeting));
  },
);

const getMeetingsLoading = (
  state: { meeting: MeetingState },
) => state.meeting.userMatch.meetings.loading;

export const selectUserMatchMeetingsLoading = createSelector(
  [getMeetingsLoading],
  (loading: boolean) => loading,
);

const getUserMatchUsersMapEntities = (
  state: { meeting: MeetingState },
) => state.meeting.userMatch.users.mapEntities;

export const selectUserMatchUsersMap = createSelector(
  [getUserMatchUsersMapEntities],
  (usersMap: Record<string, UserJson>) => {
    const keys = Object.keys(usersMap);
    const map: Record<string, User> = {};
    for (const key of keys) {
      map[key] = UserFactory.build(usersMap[key]);
    }
    return map;
  },
);

const getUserMatchPersonMapEntities = (
  state: { meeting: MeetingState },
) => state.meeting.userMatch.persons.mapEntities;

export const selectUserMatchPersonsMap = createSelector(
  [getUserMatchPersonMapEntities],
  (personMap: Record<string, PersonJson>) => {
    const keys = Object.keys(personMap);
    const map: Record<string, Person> = {};
    for (const key of keys) {
      map[key] = PersonFactory.build(personMap[key]);
    }
    return map;
  },
);

export const selectUserMatchLoading = (
  state: { meeting: MeetingState },
): boolean => state.meeting.userMatch.meetings.loading ||
state.meeting.userMatch.persons.loading ||
state.meeting.userMatch.users.loading;
