import React, { FunctionComponent, lazy, ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { RelativeHelpRoute } from './entities/Router';
import { RouteProps } from './entities/RouteProps';

/* eslint-disable @typescript-eslint/promise-function-async */
const Main = lazy(() => import('./components/routes/MainRoute'));
const Tutorials = lazy(() => import('./components/routes/TutorialsRoute'));
const ContactSupport = lazy(() => import('./components/routes/ContactSupportRoute'));
/* eslint-enable @typescript-eslint/promise-function-async */

const RootPage: FunctionComponent<RouteProps> = (props): ReactElement => {
  return (
    <Routes>
      <Route index element={
        <Main {...props} />}
      />
      <Route path={RelativeHelpRoute.Tutorials} element={
        <Tutorials {...props} />}
      />
      <Route path={RelativeHelpRoute.ContactSupport} element={
        <ContactSupport {...props} />}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default RootPage;
