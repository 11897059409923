import { State, StateFactory, StateJson } from '@pocketrn/entities/dist/core';
import { createSelector } from '@reduxjs/toolkit';
import { REDUCER_KEY } from './actions';
import { UserStateState } from './reducer';

const getUserState = (
  state: { [REDUCER_KEY]: UserStateState},
) => state[REDUCER_KEY].state;

export const selectUserState = createSelector(
  [getUserState],
  (state: Record<string, StateJson>) => {
    const _state: Record<string, State> = {};
    const keys = Object.keys(state);
    for (const key of keys) {
      _state[key] = StateFactory.build(state[key]);
    }
    return _state;
  },
);
