import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './utils/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import '@pocketrn/rn-designsystem/dist/index.css';
import { isLocal } from '@pocketrn/client/dist/app-utils';
import { logger } from '@pocketrn/client/dist/app-logger';
import { sessionUserController } from './utils/userStateInit';

function loadReact() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(document.getElementById('root')!);

  root.render(
    <Provider store={store}>
      <BrowserRouter basename="/">
        <App />
      </BrowserRouter>
    </Provider>,
  );
}

// @DEBUG: change from true to false as needed
const ignoreLocalWipe = false;

if (isLocal() && !ignoreLocalWipe && process.env.REACT_APP_RUN_MODE !== 'e2e') {
  sessionUserController.initLocal()
    .then(() => {
      loadReact();
    })
    .catch(err => {
      logger.logError(err);
    });
} else {
  loadReact();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// Note that for the app to show an automatic message to add to homescreen,
// it requires the service worker.
serviceWorker.unregister();
