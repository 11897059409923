import { Entity } from '@pocketrn/entities/dist/entity';
import { TimeUtils } from '@pocketrn/time-utils';
import { DraftJson } from './Draft.json';

export const DEFAULT_HOURS_TO_EXPIRE = 48;

export interface DraftSpecifiers {
  id: string,
  groupId: string;
  providerId?: string;
}

export class Draft implements Entity {
  public specifiers: DraftSpecifiers;
  public createdAt: Date;
  public updatedAt: Date;
  public expiresAt: Date;
  public value: string;

  constructor(
    specifiers: DraftSpecifiers,
    value: string,
  ) {
    this.specifiers = { ...specifiers };
    this.value = value;
    this.createdAt = new Date();
    this.updatedAt = new Date();
    this.expiresAt = TimeUtils.addHours(this.createdAt, DEFAULT_HOURS_TO_EXPIRE) ?? new Date();
  }

  public json(): DraftJson {
    return {
      id: this.specifiers.id,
      groupId: this.specifiers.groupId,
      value: this.value,
      createdAt: TimeUtils.copyDate(this.createdAt) ?? new Date(),
      updatedAt: TimeUtils.copyDate(this.updatedAt) ?? new Date(),
      expiresAt: TimeUtils.copyDate(this.expiresAt) ?? new Date(),
      providerId: this.specifiers.providerId,
    };
  }

  public copy(): Draft {
    const draft = new Draft({ ...this.specifiers }, this.value);
    draft.createdAt = TimeUtils.copyDate(this.createdAt) ?? new Date();
    draft.updatedAt = TimeUtils.copyDate(this.updatedAt) ?? new Date();
    draft.expiresAt = TimeUtils.copyDate(this.expiresAt) ?? new Date();
    return draft;
  }

  get valueAsJson(): any {
    if (!this.value) {
      return {};
    }
    return JSON.parse(this.value);
  }

  set valueAsJson(value: JSON) {
    if (!value) {
      this.value = '';
      return;
    }
    this.value = JSON.stringify(value);
  }
}
