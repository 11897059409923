import { SessionUserController } from '../../../user-state/index';
import { MeetingSDK } from '../../services/firebase/MeetingSDK';

// @NOTE: Redux does not export its Store type.
export type ReduxStore = any;

export class ProfileController {
  public sdk: MeetingSDK;
  public store: ReduxStore;
  public sessionUserController: SessionUserController;

  constructor(
    sdk: MeetingSDK,
    store: ReduxStore,
    sessionUserController: SessionUserController,
  ) {
    this.sdk = sdk;
    this.store = store;
    this.sessionUserController = sessionUserController;
  }

  public async setAcceptedCallTypes(acceptedCallTypes: string[]): Promise<void> {
    this.sessionUserController.setStoredAccountLoading(true);
    await this.sdk.setAcceptedCallTypes(acceptedCallTypes);
    await this.refreshAccountsForNewImpersonatedUser();
    this.sessionUserController.setStoredAccountLoading(false);
  }

  public async setIncludeInQueue(providerIdsToInclude: (string | undefined)[]): Promise<void> {
    this.sessionUserController.setStoredAccountLoading(true);
    await this.sdk.setIncludeInQueue(providerIdsToInclude);
    await this.refreshAccountsForNewImpersonatedUser();
    this.sessionUserController.setStoredAccountLoading(false);
  }

  // @NOTE: When we impersonate new users, it is necessary to manually refresh the accounts
  // because we can't depend on snapshots.
  // This is because new users can't subscribe to these snapshots until
  // they've logged in and their ipEmailUid has been established.
  private async refreshAccountsForNewImpersonatedUser(): Promise<void> {
    const user = this.sessionUserController.getStoredActiveUser();
    if (user?.ipEmailUid) return;
    await this.sessionUserController.refreshAccounts();
  }
}
