import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { Notification as NotificationEntity } from '@pocketrn/entities/dist/chatbot';
import styles from './Notifications.module.css';
import { Notification } from './Notification';
import { LocalePrimer } from '@pocketrn/localizer';
import { Spinner, useScrolledToBottom } from '@pocketrn/rn-designsystem';

interface NotificationsProps {
  notifications: NotificationEntity[];
  activeNotification?: NotificationEntity;
  filter: ReactElement;
  onScrollToBottom: (atBottom: boolean) => void;
  onToggleNotification: (notification: NotificationEntity) => void;
  onArchiveNotification: (notification: NotificationEntity) => void;
  loading?: boolean;
  localePrimer: LocalePrimer;
}

export const Notifications: FunctionComponent<NotificationsProps> = (props): ReactElement => {
  const [ handleScroll, isScrolledToBottom ] = useScrolledToBottom();

  useEffect(() => {
    props.onScrollToBottom(isScrolledToBottom);
  }, [isScrolledToBottom]);

  return (
    <section className={styles.wrapper}>
      {props.filter}
      <div className={styles.notifications} onScroll={handleScroll}>
        {props.notifications &&
          props.notifications.map(notification => {
            return (
              <div className={styles.notification} key={notification.id}>
                <Notification
                  notification={notification}
                  isActive={props.activeNotification?.id === notification.id}
                  onToggleNotification={() => props.onToggleNotification(notification)}
                  onArchiveNotification={() => props.onArchiveNotification(notification)}
                  localePrimer={props.localePrimer}
                />
              </div>
            );
          })
        }
        {props.loading && <Spinner />}
      </div>
    </section>
  );
};
