import { TimeUtils } from '@pocketrn/time-utils';
import { Feature, FeatureProvider, FeatureUser } from './Feature';
import { FeatureJson, FeatureProviderJson, FeatureUserJson } from './Feature.json';

export function buildFeature(data: FeatureJson): Feature {
  const feature = new Feature(data.id, data.groupId, data.value, data.priority ?? undefined);
  feature.createdAt = TimeUtils.copyDate(data.createdAt) ?? new Date();
  feature.updatedAt = TimeUtils.copyDate(data.updatedAt) ?? new Date();
  feature.deletedAt = TimeUtils.copyDate(data.deletedAt ?? undefined);
  return feature;
}

export function buildFeatureProvider(data: FeatureProviderJson): FeatureProvider {
  const feature = buildFeature(data);
  return new FeatureProvider(feature, data.providerId);
}

export function buildFeatureUser(data: FeatureUserJson): FeatureUser {
  const feature = buildFeature(data);
  return new FeatureUser(feature, data.uid);
}
