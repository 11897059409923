import { State } from '@pocketrn/entities/dist/core';
import { Action, ReduxAction } from '@pocketrn/client/dist/entity-sdk';

export const REDUCER_KEY = 'userState';

const ra = new ReduxAction(REDUCER_KEY);

export interface SetState extends Action {
  key: string;
  value: State;
}

export interface ClearState extends Action {
  key: string;
}

export const UserStateActionId = {
  SET_STATE_VALUE: ra.actionType('SET_STATE_VALUE'),
  CLEAR_STATE_VALUE: ra.actionType('CLEAR_STATE_VALUE'),
};

export class UserStateActions {
  public static setState(key: string, value: State): SetState {
    return {
      type: UserStateActionId.SET_STATE_VALUE,
      key,
      value,
    };
  }

  public static clearState(key: string): ClearState {
    return {
      type: UserStateActionId.CLEAR_STATE_VALUE,
      key,
    };
  }
}
