import { LocalePrimer } from '@pocketrn/localizer';
import { Navbar, Page } from '@pocketrn/rn-designsystem';
import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import ChatDrawer from '../../apps/messaging-app/components/ChatDrawer';
import { ChatbotController } from '../../apps/messaging-app/state/controllers/Chatbot.controller';
import { MessagesController } from '../../apps/messaging-app/state/controllers/Messages.controller';
import { BotResponseLibrary } from '../../apps/messaging-app/utils/BotResponseLibrary';
import { selectDescription, selectInnerPage, selectNavBar, selectSideStripe, selectTitle } from '../../apps/page-state';
import { PageSideStrip } from '../../apps/page-state/redux/reducer';
import {
  selectFullWidth,
  selectHeaderActionText,
  selectOverline,
  selectShowOnBack,
  selectShowOnHeaderAction,
  selectVectorImage,
  selectInnerPageTitle,
  selectPageLoading,
} from '../../apps/page-state/redux/selectors';
import { AccountSwitcher } from '../../ds';
import { useNavbarProps } from '../../utils/navbarProps';
import { pageController } from '../../utils/pageStateInit';
import { sessionUserController } from '../../utils/userStateInit';
import { selectActiveQueueStats, selectUserMatchMeetingsList } from '../../apps/meeting-app/state/redux/sdk/selectors';
import PageStatusBar, { RenderProps, shouldRender } from './PageStatusBar';
import PageLoadingDetailsVector from '../../assets/vectors/addToWaitlist.svg';
import { selectSessionUser } from '../../apps/user-state';

interface PageLayoutProps {
  localePrimer: LocalePrimer;
  children: ReactNode;
  banners?: ReactElement[];
  drawer?: {
    messagesController: MessagesController;
    chatbotController: ChatbotController;
    localePrimer: LocalePrimer;
    botResponseLibrary: BotResponseLibrary;
  }
}

const PageLayout: FunctionComponent<PageLayoutProps> = (props): ReactElement => {
  const loading = useSelector(selectPageLoading);
  const title = useSelector(selectTitle);
  const vectorImage = useSelector(selectVectorImage);
  const headerActionText = useSelector(selectHeaderActionText);
  const showOnHeaderAction = useSelector(selectShowOnHeaderAction);
  const description = useSelector(selectDescription);
  const innerPage = useSelector(selectInnerPage);
  const innerPageTitle = useSelector(selectInnerPageTitle);
  const sideStripe = useSelector(selectSideStripe);
  const navbarState = useSelector(selectNavBar);
  const showOnBack = useSelector(selectShowOnBack);
  const overline = useSelector(selectOverline);
  const navBarProps = useNavbarProps(navbarState.activeNavigation);
  const fullWidth = useSelector(selectFullWidth);
  const queueStats = useSelector(selectActiveQueueStats);
  const inProgressMeetings = useSelector(selectUserMatchMeetingsList);
  const sessionUser = useSelector(selectSessionUser);

  const goBack = () => {
    pageController.setTriggerOnBack(true);
  };

  const triggerAction = () => {
    pageController.setTriggerHeaderAction(true);
  };

  const logout = () => {
    sessionUserController.logout();
  };

  const pageStatusBarRenderProps: RenderProps = {
    queueStartedAt: queueStats?.startedAt,
    inProgressMeetings,
    user: sessionUser,
  };

  const shouldRenderPageStatus = shouldRender(pageStatusBarRenderProps);

  return (
    <Page
      loading={loading}
      localePrimer={props.localePrimer}
      title={title}
      vectorImage={vectorImage}
      headerActionText={headerActionText}
      onHeaderAction={showOnHeaderAction ? triggerAction : undefined}
      description={description}
      overline={overline}
      sideStripe={sideStripe as PageSideStrip}
      innerPage={innerPage}
      innerPageTitle={innerPageTitle}
      onBack={showOnBack ? goBack : undefined}
      fullWidth={fullWidth}
      navBar={
        <Navbar
          {...navBarProps}
          accountSwitcher={
            navbarState.isAccountSwitcher ?
              <AccountSwitcher inline /> :
              undefined
          }
          {...navbarState}
          localePrimer={props.localePrimer}
          statusBar={
            shouldRenderPageStatus ?
              <PageStatusBar
                localePrimer={props.localePrimer}
                {...pageStatusBarRenderProps}
              /> : undefined
          }
          onLogout={navbarState.showLogout ? logout : undefined}
        />
      }
      banners={props.banners ?? undefined}
      drawer={props.drawer &&
        <ChatDrawer
          localePrimer={props.drawer.localePrimer}
          messagesController={props.drawer.messagesController}
          botResponseLibrary={props.drawer.botResponseLibrary}
          chatbotController={props.drawer.chatbotController}
        />
      }
      testIdRoot="page"
      requiredAssets={{
        svgs: {
          loadingDetails: PageLoadingDetailsVector,
        },
      }}
    >
      {props.children}
    </Page>
  );
};

export default PageLayout;
