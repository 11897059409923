import { Text } from '../entities/Text';
import { Choice } from '../entities/Choice';
import { TextType } from '../entities/enums/TextType';
import { LocalePrimer } from '@pocketrn/localizer';
import { ChatbotController } from '../state/controllers/Chatbot.controller';
import { NavigateFunction } from 'react-router-dom';

const textLocale = 'messages.chatbot.text';
const choiceLocale = 'messages.chatbot.choice';

export enum ResponseKeys {
  Welcome = 'welcome',
  Continue = 'continue',
  ContactSupport = 'contactSupport',
  ViewTutorials = 'viewTutorials',
  GotoSupport = 'gotoSupport',
  GotoTutorials = 'gotoTutorials',
}

export class BotResponseLibrary {
  private textLibrary: Record<string, Text> = {};
  private choiceLibrary: Record<string, Choice> = {};
  private choiceSets: Record<string, Choice[]> = {};
  public localePrimer: LocalePrimer;
  public chatbotController: ChatbotController;

  /**
   * Must be primed with `/locales/*.ts`'s `locales` at root of locale file given
   * for localePrimer.
   */
  constructor(localePrimer: LocalePrimer, chatbotController: ChatbotController) {
    this.localePrimer = localePrimer;
    this.chatbotController = chatbotController;

    this.textLibrary = {
      welcome: this.getBotText(ResponseKeys.Welcome),
      continue: this.getBotText(ResponseKeys.Continue),
      contactSupport: this.getBotText(ResponseKeys.ContactSupport),
      viewTutorials: this.getBotText(ResponseKeys.ViewTutorials),
      gotoSupport: this.getBotText(ResponseKeys.GotoSupport),
      gotoTutorials: this.getBotText(ResponseKeys.GotoTutorials),
    };

    this.choiceLibrary = {
      continue: new Choice(
        localePrimer.translate(choiceLocale, 'no'),
        this.getChoiceInputMethod(ResponseKeys.Continue),
      ),
      contactSupport: new Choice(
        localePrimer.translate(choiceLocale, 'contactSupport'),
        this.getChoiceInputMethod(ResponseKeys.ContactSupport),
      ),
      confirmContactSupport: new Choice(
        localePrimer.translate(choiceLocale, 'yes'),
        this.getRouteMethod('/help/contact-support', ResponseKeys.GotoSupport),
        true,
      ),
      viewTutorials: new Choice(
        localePrimer.translate(choiceLocale, 'viewTutorials'),
        this.getChoiceInputMethod(ResponseKeys.ViewTutorials),
      ),
      confirmViewTutorials: new Choice(
        localePrimer.translate(choiceLocale, 'yes'),
        this.getRouteMethod('/help/tutorials', ResponseKeys.GotoTutorials),
        true,
      ),
    };

    this.choiceSets = {
      welcome: [
        this.choiceLibrary.contactSupport,
        this.choiceLibrary.viewTutorials,
      ],
      continue: [
        this.choiceLibrary.contactSupport,
        this.choiceLibrary.viewTutorials,
      ],
      contactSupport: [
        this.choiceLibrary.confirmContactSupport,
        this.choiceLibrary.continue,
      ],
      viewTutorials: [
        this.choiceLibrary.confirmViewTutorials,
        this.choiceLibrary.continue,
      ],
    };
  }

  private getBotText(key: ResponseKeys): Text {
    return new Text(this.localePrimer.translate(textLocale, key), TextType.Bot, undefined, key);
  }

  public initializeBotChat(): void {
    this.chatbotController.setChatbotChat([this.textLibrary[ResponseKeys.Welcome]]);
  }

  private getRouteMethod(route: string, key: ResponseKeys): (navigate: NavigateFunction) => void {
    const textToAdd = [
      new Text(this.localePrimer.translate(choiceLocale, 'yes'), TextType.Self),
      this.textLibrary[key],
      this.textLibrary[ResponseKeys.Continue],
    ];
    return (navigate: NavigateFunction) => {
      navigate(route);
      this.chatbotController.addToChatbotChat(textToAdd);
    };
  }

  private getChoiceInputMethod(key: ResponseKeys): () => void {
    return () => this.choiceInput(key);
  }

  private choiceInput(key: ResponseKeys): void {
    const choice = this.choiceLibrary[key];
    const textToAdd = [
      new Text(choice.text, TextType.Self, undefined, key),
      this.textLibrary[key],
    ];
    this.chatbotController.addToChatbotChat(textToAdd);
  }

  public getChoices(text: Text): Choice[] {
    if (!text.key) {
      return [];
    }
    return this.choiceSets[text.key];
  }
}
