import React, { FunctionComponent, ReactElement } from 'react';
import { ChatOverview as ChatOverviewEntity } from '@pocketrn/entities/dist/chatbot';
import { TimeUtils } from '@pocketrn/time-utils';
import { ElapsedTime } from '@pocketrn/time-utils/lib/src/TimeUtils';
import styles from './ChatOverview.module.css';
import { useSelector } from 'react-redux';
import { selectPersonMap } from '../../../state/redux/messages/selectors';
import { LocalePrimer } from '@pocketrn/localizer';
import { CircleIcon } from './CircleIcon';
import { selectSessionPerson } from '../../../../user-state/index';

interface ChatOverviewProps {
  chatOverview: ChatOverviewEntity;
  onOpen: () => void;
  localePrimer: LocalePrimer;
}

export const ChatOverview: FunctionComponent<ChatOverviewProps> = (props): ReactElement => {
  const personMap = useSelector(selectPersonMap);
  const selfPerson = useSelector(selectSessionPerson);

  const getTimeString = (elapsedTime: ElapsedTime): string => {
    return props.localePrimer.translate(
      'format.time.unitsAbbreviations',
      elapsedTime.localeKey,
    )(elapsedTime.time);
  };

  const ChatOverviewStyles = () => {
    const s = [`${styles.chatOverview}`];
    if (props.chatOverview.unreadMessageCount) {
      s.push(`${styles.unread}`);
    }
    return s.join(' ');
  };

  const getUserChatUserList = (): string => {
    const names = [];
    for (const uid of props.chatOverview.participantUids) {
      if (uid !== selfPerson?.uid) {
        const person = personMap[uid];
        names.push(person.displayName(props.localePrimer));
      }
    }
    return names.join(', ');
  };

  return (
    <div className={styles.wrapper} onClick={props.onOpen}>
      {!!props.chatOverview.unreadMessageCount &&
        <CircleIcon />
      }
      <div className={ChatOverviewStyles()}>
        <div className={styles.chatOverviewUser}>{getUserChatUserList()}</div>
        <div className={styles.chatOverviewMessage}>{props.chatOverview.latestMessage.text}</div>
      </div>
      <div className={styles.infoAndOptions}>
        <div className={styles.time}>
          {getTimeString(TimeUtils.getElapsedTime(props.chatOverview.latestMessage.sentAt))}
        </div>
      </div>
    </div>
  );
};
