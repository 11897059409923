import { Action } from '@pocketrn/client/dist/entity-sdk';
import { TextJson } from '../../../entities/interfaces/Text.json';
import {
  ChatbotActionId,
  SetChatbotChat,
  AddToChatbotChat,
  SetUnreadNotificationsCount,
  SetUnreadUserChatsCount,
  SetDrawerIsOpen,
} from './actions';

export interface ChatbotState {
  chatbotChat: TextJson[];
  unreadNotificationsCount: number;
  unreadUserChatsCount: number;
  drawerIsOpen: boolean;
}

const initialState: ChatbotState = {
  chatbotChat: [],
  unreadNotificationsCount: 0,
  unreadUserChatsCount: 0,
  drawerIsOpen: false,
};

const chatbotReducer = (state = initialState, action: Action): ChatbotState => {
  const newState = state;
  switch (action.type) {
    case ChatbotActionId.SET_CHATBOT_CHAT:
      return State.setChatbotChat(newState, action as SetChatbotChat);
    case ChatbotActionId.ADD_TO_CHATBOT_CHAT:
      return State.addToChatbotChat(newState, action as AddToChatbotChat);
    case ChatbotActionId.SET_UNREAD_NOTIFICATIONS_COUNT:
      return State.setUnreadNotificationsCount(newState, action as SetUnreadNotificationsCount);
    case ChatbotActionId.SET_UNREAD_USERCHATS_COUNT:
      return State.setUnreadUserChatsCount(newState, action as SetUnreadUserChatsCount);
    case ChatbotActionId.SET_DRAWER_IS_OPEN:
      return State.setDrawerIsOpen(newState, action as SetDrawerIsOpen);
    default:
      return state;
  }
};

class State {
  public static setChatbotChat(
    state: ChatbotState,
    action: SetChatbotChat,
  ): ChatbotState {
    return {
      ...state,
      chatbotChat: action.chatbotChat,
    };
  }

  public static addToChatbotChat(
    state: ChatbotState,
    action: AddToChatbotChat,
  ): ChatbotState {
    return {
      ...state,
      chatbotChat: [
        ...state.chatbotChat,
        ...action.chatbotChat,
      ],
    };
  }

  public static setUnreadNotificationsCount(
    state: ChatbotState,
    action: SetUnreadNotificationsCount,
  ): ChatbotState {
    return {
      ...state,
      unreadNotificationsCount: action.unreadNotificationsCount,
    };
  }

  public static setUnreadUserChatsCount(
    state: ChatbotState,
    action: SetUnreadUserChatsCount,
  ): ChatbotState {
    return {
      ...state,
      unreadUserChatsCount: action.unreadUserChatsCount,
    };
  }

  public static setDrawerIsOpen(
    state: ChatbotState,
    action: SetDrawerIsOpen,
  ): ChatbotState {
    return {
      ...state,
      drawerIsOpen: action.isOpen,
    };
  }
}

export default chatbotReducer;
