import { LocalePrimer } from '@pocketrn/localizer';
import { Navigation } from '@pocketrn/rn-designsystem';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ChatbotActions } from '../apps/messaging-app/state/redux/chatbot/actions';
import { selectMessagesCount } from '../apps/messaging-app/state/redux/chatbot/selectors';
import localePrimer from './localePrimer';
import store from './store';
import { selectSessionUser, selectSessionUserAccounts } from '../apps/user-state';
import { AccountStatus } from '@pocketrn/entities/dist/core';
import { BadgeType } from '@pocketrn/rn-designsystem/dist/components/Icon/Badge';
import { useEffect, useState } from 'react';
import { selectUserMatchMeetingsList } from '../apps/meeting-app';
import { relevantMeetings } from './myMeetings';

export function useNavbarProps(activeNavigation: Navigation): {
  onNavigate: (navigation: Navigation) => void,
  routePush: (path: string) => void,
  profileNotifications: BadgeType,
  messagesNotifications: number,
  meetingNotifications: number,
  localePrimer: LocalePrimer,
  activeNavigation: Navigation,
} {
  const navigate = useNavigate();
  const messagesCount = useSelector(selectMessagesCount);
  const user = useSelector(selectSessionUser);
  const allInProgressMeetings = useSelector(selectUserMatchMeetingsList);
  const inProgressMeetings = relevantMeetings(allInProgressMeetings, user);
  const meetingsCount = inProgressMeetings.length;
  const expiredSubscription = (
    user &&
    !user.stripe?.getActiveProductKeys().length
  );
  const accounts = useSelector(selectSessionUserAccounts);
  const inviteAccountsLength = accounts.filter(account => {
    return account.status === AccountStatus.Invited;
  }).length;
  const [ profileNotifications, setProfileNotifications ] = useState<BadgeType>(undefined);

  useEffect(() => {
    handleProfileNotifications();
  }, [ expiredSubscription, inviteAccountsLength ]);

  const handleNavbarNavigate = (navigation: Navigation) => {
    if (navigation === Navigation.Messages) {
      store.dispatch(ChatbotActions.setDrawerIsOpen(!store.getState().chatbot.drawerIsOpen));
    }
  };

  const handleProfileNotifications = () => {
    if (inviteAccountsLength > 0) {
      setProfileNotifications(inviteAccountsLength);
    } else if (expiredSubscription) {
      setProfileNotifications('dot');
    } else {
      setProfileNotifications(undefined);
    }
  };

  return {
    onNavigate: handleNavbarNavigate,
    routePush: navigate,
    messagesNotifications: messagesCount,
    profileNotifications,
    meetingNotifications: meetingsCount,
    localePrimer,
    activeNavigation,
  };
}
