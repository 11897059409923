import { createSelector } from '@reduxjs/toolkit';
import { Flags, REDUCER_KEY } from './actions';
import { buildFeature, buildFeatureProvider, buildFeatureUser } from './Feature.factory';
import { FeatureFlagState, Flags as JsonFlags } from './reducer';

const getFeatureFlags = (
  state: { [REDUCER_KEY]: FeatureFlagState},
) => state[REDUCER_KEY].flags;

export const selectFeatureFlags = createSelector(
  [getFeatureFlags],
  (flags: JsonFlags): Flags => {
    return {
      features: flags.features.map(f => buildFeature(f)),
      featureProviders: flags.featureProviders.map(f => buildFeatureProvider(f)),
      featureUsers: flags.featureUsers.map(f => buildFeatureUser(f)),
    };
  },
);

export const selectFeatureFlagsInitialized = (
  state: { [REDUCER_KEY]: FeatureFlagState},
): boolean => state[REDUCER_KEY].initialized;
