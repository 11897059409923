import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  WarningModal,
} from '@pocketrn/rn-designsystem';
import MeetingRouter, { MeetingRoute } from '../../entities/Router';
import { LocalePrimer } from '@pocketrn/localizer';
import { UserMatchController } from '../../state/controllers/UserMatch.controller';
import { selectSessionAccount } from '../../../user-state';
import { isRequestor } from '@pocketrn/entities/dist/core';
import { selectScheduledMeetingStarting } from '../../state/redux/userMatch/selectors';

const locale = 'meeting.modal.startScheduledMeeting';

interface Props {
  localePrimer: LocalePrimer;
  userMatchController: UserMatchController;
}

const StartScheduledMeetingModal: FunctionComponent<Props> = (props): ReactElement => {
  const navigate = useNavigate();
  const account = useSelector(selectSessionAccount);
  const _isRequestor = account?.type && isRequestor(account?.type);
  const scheduledMeetingStarting = useSelector(selectScheduledMeetingStarting);
  const [ isOpened, setIsOpened ] = useState(false);

  useEffect(() => {
    if (scheduledMeetingStarting) {
      setIsOpened(!!scheduledMeetingStarting);
    }
  }, [scheduledMeetingStarting]);

  const confirm = () => {
    if (!scheduledMeetingStarting) {
      navigate(MeetingRoute.Meetings);
      return;
    }
    navigate(MeetingRouter.getMeetingRoute(scheduledMeetingStarting));
    props.userMatchController.ackScheduledMeetingStarting();
    setIsOpened(false);
  };

  return (
    <WarningModal
      type="info"
      onClose={() => setIsOpened(false)}
      show={isOpened}
      onConfirm={confirm}
      localePrimer={props.localePrimer}
      markdown={props.localePrimer.translate(
        locale, _isRequestor ? 'requestor' : 'acceptor', 'markdown',
      )}
      confirmBtnText={props.localePrimer.translate(
        locale, _isRequestor ? 'requestor' : 'acceptor', 'confirm',
      )}
      testIdRoot="startScheduledMeeting-modal"
    />
  );
};

export default StartScheduledMeetingModal;
