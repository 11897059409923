import { FeatureFlagController } from '../apps/feature-flags/src/controller';
import { SecureDraftController } from '../apps/secure-drafts/src/controller';
import {
  UserStateController,
  FirebaseUserController,
  SessionUserController,
} from '../apps/user-state/index';
import {
  coreSDK,
  sessionUserSDK,
  userStateSDK,
  featureFlagSDK,
  secureDraftSDK,
} from '../services/firebase';
import { SubscriptionController } from '../state/controllers/Subscription.controller';
import { firebaseAuth } from './firebase';
import store from './store';

export const userStateController = new UserStateController(
  userStateSDK,
  store,
);
export const firebaseUserController = new FirebaseUserController(
  firebaseAuth,
  store,
  userStateController,
);
export const featureFlagController = new FeatureFlagController(
  featureFlagSDK,
  store,
);
export const sessionUserController = new SessionUserController(
  sessionUserSDK,
  firebaseUserController,
  featureFlagController,
  store,
);
export const subscriptionController = new SubscriptionController(
  coreSDK,
);
export const secureDraftController = new SecureDraftController(
  secureDraftSDK,
);
