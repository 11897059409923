import { isLocal, isProd, isGamma, isBeta, getRegion } from '@pocketrn/client/dist/app-utils';
import { initializeApp } from 'firebase/app';
import { browserSessionPersistence, connectAuthEmulator, getAuth, setPersistence } from 'firebase/auth';
import { getFirestore, initializeFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

export function getFirebaseConfig(): any {
  if (isProd()) {
    return {
      apiKey: 'AIzaSyCvrtMEEktDYcrhb3EcxBnzj3Q9z_fV12A',
      authDomain: 'auth.pocketrn.com',
      projectId: 'core-functions-prod',
    };
  } else if (isGamma()) {
    return {
      apiKey: 'AIzaSyCogMjpZrUqdw9AVwLAhrlLataV6T0AX4M',
      authDomain: 'core-functions-xyz-temp.firebaseapp.com',
      projectId: 'core-functions-xyz-temp',
    };
  } else if (isBeta()) {
    return {
      apiKey: 'AIzaSyCPtoXzdLBd8-d96mqM_KCBE747R0m77vo',
      // @NOTE: something in the setup isn't allowing localhost from `auth.pocketrn.dev` unfortunately
      authDomain: 'core-functions-dev.firebaseapp.com',
      projectId: 'core-functions-dev',
    };
  } else {
    return {
      // @NOTE: this is a hardcoded API key that the emulator uses behind the curtains.
      // It's never explicitely stated, but you can see it in use here:
      // https://firebase.google.com/docs/emulator-suite/connect_auth
      apiKey: 'fake-api-key',
      // @NOTE: something in the setup isn't allowing localhost from `auth.pocketrn.dev` unfortunately
      authDomain: 'core-functions-dev.firebaseapp.com',
      projectId: 'core-functions-dev',
    };
  }
}

// Configure Firebase.
const config = getFirebaseConfig();
const app = initializeApp(config);

// Initialize Cloud Functions through Firebase
export const firebaseFunctions = getFunctions(app, getRegion());
if (isLocal()) {
  connectFunctionsEmulator(firebaseFunctions, '127.0.0.1', 5001);
}

export const firebaseAuth = getAuth(app);
// See: https://github.com/pocketrn/Task-Management/issues/199
// This will ensure that if the browser tab is closed that the user needs to log back in.
setPersistence(firebaseAuth, browserSessionPersistence);
if (isLocal()) {
  initializeFirestore(app, {
    host: '127.0.0.1:8081',
    ssl: false,
    // @NOTE: See: https://stackoverflow.com/a/61619639/6090140
    experimentalForceLongPolling: true,
  });
  if (process.env.REACT_APP_RUN_MODE === 'e2e') {
    connectAuthEmulator(firebaseAuth, 'http://127.0.0.1:9099');
  }
}

export const firestore = getFirestore(app);
