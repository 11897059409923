import React, { FunctionComponent, ReactElement } from 'react';
import { PageBanner } from '@pocketrn/rn-designsystem';
import localePrimer from '../../../utils/localePrimer';
import { useSelector } from 'react-redux';
import { selectSessionUser } from '../../user-state';
import { useFlag } from '../../feature-flags/src/useFlag';

const locale = 'technician.featureFlagTestBanner';

const FeatureFlagTestBanner: FunctionComponent = (): ReactElement | null => {
  const activeUser = useSelector(selectSessionUser);
  const checkFeatureTrue = useFlag({
    activeUser,
    groupId: 'test',
    id: 'checkFeatureTrue',
  });
  const checkFeatureFalse = useFlag({
    activeUser,
    groupId: 'test',
    id: 'checkFeatureFalse',
  });
  const checkFeatureProviderFalse = useFlag({
    activeUser,
    groupId: 'test',
    id: 'checkFeatureProviderFalse',
  });
  const checkFeatureProviderPriorityTrue = useFlag({
    activeUser,
    groupId: 'test',
    id: 'checkFeatureProviderPriorityTrue',
  });

  if (!checkFeatureTrue.value) {
    return null;
  }

  const checkString = `
    checkFeatureTrue: ${checkFeatureTrue.value}
    checkFeatureFalse: ${checkFeatureFalse.value}
    checkFeatureProviderFalse: ${checkFeatureProviderFalse.value}
    checkFeatureProviderPriorityTrue: ${checkFeatureProviderPriorityTrue.value}
  `;

  return (
    <PageBanner
      type="info"
      text={localePrimer.translate(locale, 'passThrough')(checkString)}
      testIdRoot="featureFlagTestBanner"
    />
  );
};

export default FeatureFlagTestBanner;
