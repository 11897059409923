import { ReduxAction, Action } from '@pocketrn/client/dist/entity-sdk';

export const REDUCER_KEY = 'userMatch';
const ra = new ReduxAction(REDUCER_KEY);

export interface SetRequestorLeftQueue extends Action {
  leftQueue: boolean;
}

export interface SetMatchMaking extends Action {
  inQueue: boolean;
}

export interface SetScheduledMeetingStarting extends Action {
  meetingId: string;
}

export const UserMatchActionId = {
  SET_REQUESTOR_LEFT_QUEUE: ra.actionType('SET_REQUESTOR_LEFT_QUEUE'),
  SET_MATCHMAKING: ra.actionType('SET_MATCHMAKING'),
  SET_MEETINGFOUND: ra.actionType('SET_MEETINGFOUND'),
  ACK_MEETINGFOUND: ra.actionType('ACK_MEETINGFOUND'),
  SET_SCHEDULED_MEETING_STARTING: ra.actionType('SET_SCHEDULED_MEETING_STARTING'),
  ACK_SCHEDULED_MEETING_STARTING: ra.actionType('ACK_SCHEDULED_MEETING_STARTING'),
};

export class UserMatchActions {
  public static setRequestorLeftQueue(leftQueue: boolean): SetRequestorLeftQueue {
    return {
      type: UserMatchActionId.SET_REQUESTOR_LEFT_QUEUE,
      leftQueue,
    };
  }

  public static setMatchmaking(inQueue: boolean): SetMatchMaking {
    return {
      type: UserMatchActionId.SET_MATCHMAKING,
      inQueue,
    };
  }

  public static setMeetingFound(): Action {
    return { type: UserMatchActionId.SET_MEETINGFOUND };
  }

  public static ackMeetingFound(): Action {
    return { type: UserMatchActionId.ACK_MEETINGFOUND };
  }

  public static setScheduledMeetingStarting(meetingId: string): SetScheduledMeetingStarting {
    return {
      type: UserMatchActionId.SET_SCHEDULED_MEETING_STARTING,
      meetingId,
    };
  }

  public static ackScheduledMeetingStarting(): SetScheduledMeetingStarting {
    return { type: UserMatchActionId.ACK_SCHEDULED_MEETING_STARTING, meetingId: '' };
  }
}
