import { UserState } from '@pocketrn/entities/dist/core';
import { FirebaseFunctionInterface, FirebaseSDK, OnCallFunction } from '@pocketrn/client/dist/entity-sdk';
import { Auth } from 'firebase/auth';

export class UserStateSDK extends FirebaseSDK {
  constructor(functions: FirebaseFunctionInterface, firebaseAuth: Auth) {
    super(functions, firebaseAuth);
  }

  public async pingUserState(userState: UserState): Promise<void> {
    try {
      const data = {
        userState: {
          uid: userState.uid,
          states: { ...userState.states },
        },
      };
      await this.functions.httpsCallable(OnCallFunction.Core, 'pingUserState')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }
}
