import { selectSessionUserCustomFormsMap } from '../apps/user-state/src/session/selectors';
import { ProfileContext } from '../components/routes/profile/ProfileContext';
import { ClientCustomForm, ActionLinkType, CUSTOM_FORM_AL_METADATA_KEY } from '@pocketrn/entities/dist/core';
import { selectActionLink } from '../state/redux/core/selectors';
import { useSelector } from 'react-redux';
import { useContext, useMemo } from 'react';

// @NOTE: this hook will return the customForms data according to the UI context
// e.g. if the user is on the actionLink page, it will return the customForms data
// according to the actionLink metadata, otherwise it will return the customForms
// data according to the session data or the context data.
export const useFilteredCustomForms = (
  shouldUseSessionData?: boolean,
): Record<string, ClientCustomForm> => {
  const actionLink = useSelector(selectActionLink);
  const sessionCustomFormsMap = useSelector(selectSessionUserCustomFormsMap);
  const ctx = useContext(ProfileContext);

  const filteredCustomForms = useMemo(() => {
    const actionLinksForms = actionLink?.metadata[CUSTOM_FORM_AL_METADATA_KEY] as string[];
    if (actionLink?.root.type === ActionLinkType.SignConsents) {
      const formsMap: Record<string, ClientCustomForm> = {};
      Object.values(sessionCustomFormsMap).forEach(form => {
        if (form.root.required) {
          formsMap[form.root.id] = form;
        }
        if (actionLinksForms && actionLinksForms.includes(form.root.id)) {
          formsMap[form.root.id] = form;
        }
      });
      return formsMap;
    }
    if (shouldUseSessionData) {
      return sessionCustomFormsMap;
    }
    return ctx.customFormsMap;
  }, [ actionLink, sessionCustomFormsMap, ctx.customFormsMap ]);

  return filteredCustomForms;
};
