import { createSelector } from '@reduxjs/toolkit';
import { NavbarState, PageState } from './reducer';

const selectPageState = (state: { page: PageState }) => state.page;

// Simplified selectors for properties without transformation
export const selectLoading = createSelector(
  [selectPageState],
  (pageState: PageState) => !!pageState.loading || !!pageState.initialLoading,
);

export const selectPageLoading = createSelector(
  [selectPageState],
  // @NOTE: Unlike, selectPageLoading, this selector will return boolean value, whereas
  // selectPageLoading returns boolean or PageLoadingDetails.
  (pageState: PageState) => pageState.loading || pageState.initialLoading,
);

export const selectInitialLoading = createSelector(
  [selectPageState],
  (pageState: PageState) => pageState.initialLoading,
);

export const selectTitle = createSelector(
  [selectPageState],
  (pageState: PageState) => pageState.title,
);

export const selectVectorImage = createSelector(
  [selectPageState],
  (pageState: PageState) => pageState.vectorImage,
);

export const selectHeaderActionText = createSelector(
  [selectPageState],
  (pageState: PageState) => pageState.headerActionText,
);

export const selectShowOnHeaderAction = createSelector(
  [selectPageState],
  (pageState: PageState) => pageState.showOnHeaderAction,
);

export const selectTriggerHeaderAction = createSelector(
  [selectPageState],
  (pageState: PageState) => pageState.triggerHeaderAction,
);

export const selectDescription = createSelector(
  [selectPageState],
  (pageState: PageState) => pageState.description,
);

export const selectInnerPage = createSelector(
  [selectPageState],
  (pageState: PageState) => pageState.innerPage,
);

export const selectInnerPageTitle = createSelector(
  [selectPageState],
  (pageState: PageState) => pageState.innerPageTitle,
);

export const selectSideStripe = createSelector(
  [selectPageState],
  (pageState: PageState) => pageState.sideStripe,
);

export const selectOverline = createSelector(
  [selectPageState],
  (pageState: PageState) => pageState.overline,
);

export const selectTriggerOnBack = createSelector(
  [selectPageState],
  (pageState: PageState) => pageState.triggerOnBack,
);

export const selectShowOnBack = createSelector(
  [selectPageState],
  (pageState: PageState) => pageState.showOnBack,
);

// Complex selector with transformation logic
export const selectNavBar = createSelector(
  [ selectPageState, selectInitialLoading ],
  (pageState: PageState, initialLoading: boolean) => {
    const navBar: NavbarState = { ...pageState.navBar };
    if (initialLoading) {
      navBar.onlyLogo = true;
      navBar.isAccountSwitcher = false;
      navBar.showLogout = false;
    }
    return navBar;
  },
);

export const selectFullWidth = createSelector(
  [selectPageState],
  (pageState: PageState) => pageState.fullWidth,
);
