export const chatbot = {
  title: 'Chatbot',
  text: {
    welcome: 'Welcome to PocketRN, I\'m Chatbot! How may I help you today?',
    continue: 'Is there anything else I can help you with today?',
    contactSupport: 'Can I take you to our support page?',
    viewTutorials: 'Can I take you to our tutorials page?',
    gotoSupport: 'Okay, I\'ll take you to our support page!',
    gotoTutorials: 'Okay, I\'ll take you to our tutorials page!',
  },
  choice: {
    yes: 'Yes, take me there.',
    no: 'No, I\'d like help with something else.',
    contactSupport: 'I\'d like to talk to support.',
    viewTutorials: 'I\'d like to see the tutorials.',
  },
};

export const notifications = {
  title: 'Notifications',
  filter: {
    notifications: 'Notifications',
    archived: 'Archived',
    type: {
      all: 'All',
      system: 'System',
      prnStaff: 'PocketRN Staff',
    },
  },
};

export const userChat = {
  title: 'Messages',
  sendMessage: 'Send Message',
  disclaimer: 'Messages are not monitored regularly and responses are not guaranteed. For clinical advice, please meet with a nurse now or schedule a meeting for later.',
  endOfChat: 'Start of Message History',
  eventMessages: { meetingFound: 'Your meeting has started. Join Zoom to start the conversation or use this chat to send information to the other participant to refer to during or after the meeting.' },
};

export const messages = {
  chatbot,
  notifications,
  userChat,
};

export const locales = { messages };
