import { Auth } from 'firebase/auth';
import { ActionKey, sessionUserActions } from '../session/actions';
import { UserStateController } from '../state/controller';
import { FirebaseUserActions, REDUCER_KEY } from './actions';
import { FirebaseUser } from './FirebaseUser';
import { FirebaseUserState } from './reducer';
import { isLocal } from '@pocketrn/client/dist/app-utils';

// @NOTE: Redux does not export its Store type.
export type ReduxStore = any;

export class FirebaseUserController {
  public firebaseAuth: Auth;
  public userStateController: UserStateController;

  public store: ReduxStore;

  constructor(
    firebaseAuth: Auth,
    store: ReduxStore,
    userStateController: UserStateController,
  ) {
    this.firebaseAuth = firebaseAuth;
    this.store = store;
    this.userStateController = userStateController;
  }

  private state(): FirebaseUserState {
    return this.store.getState()[REDUCER_KEY];
  }

  public getStoredFirebaseUser(): FirebaseUser | undefined {
    return this.state().user;
  }

  public initFirebaseAuth(loadingFinished: () => void): void {
    if (isLocal() && process.env.REACT_APP_RUN_MODE !== 'e2e') {
      this.setFirebaseUser({
        uid: 'MOCK',
        email: 'mock@email.com',
        // @DEBUG: comment out if you need to see the verify by email page.
        emailVerified: true,
        displayName: 'John Doe',
        signinProvider: 'email',
      }, loadingFinished);
    } else {
      this.firebaseAuth.onAuthStateChanged(
        async user => {
          let _user: FirebaseUser | null = null;
          if (user) {
            _user = {
              uid: user.uid,
              email: user.email,
              displayName: user.displayName,
              emailVerified: user.emailVerified,
              signinProvider: user.providerData[0].providerId,
            };
          }
          this.setFirebaseUser(_user || undefined, loadingFinished);
        },
      );
    }
  }

  private async setFirebaseUser(
    user: FirebaseUser | undefined,
    loadingFinished: () => void,
  ): Promise<void> {
    if (!user) {
      this.store.dispatch(FirebaseUserActions.unsetUser());
      this.userStateController.stopPing();
      loadingFinished();
      return;
    }
    this.store.dispatch(FirebaseUserActions.setUser(user));
    if (user.emailVerified) {
      this.userStateController.startPing();
    }
    loadingFinished();
  }

  public async logout(): Promise<void> {
    this.store.dispatch(sessionUserActions.setLoading(ActionKey.Account, true));
    this.store.dispatch(sessionUserActions.setLoading(ActionKey.User, true));
    this.userStateController.stopPing();
    await this.firebaseAuth.signOut();
    this.store.dispatch(FirebaseUserActions.unsetUser());
    this.store.dispatch(sessionUserActions.resetAll());
    // @NOTE: this is to clear the redux stores of their history
    if (!isLocal()) {
      window.location.reload();
    }
  }
}
