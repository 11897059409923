export const genderIdentityInformation = `
  #### Why is PocketRN asking for a patient's gender?
  * It is helpful for nurses to understand the patient's gender when making decisions about their care.  
  * There are a lot of disparities in healthcare for transgender and gender expansive individuals; understanding the patient's gender can help our care team ensure they have an equitable experience using PocketRN. 
  * Preventive care is very important for us at PocketRN. If you use PocketRN for preventative care, our care team may use the patient's gender and other related information to curate an appropriate care plan for them. 

  #### How do I answer this question?
  * Please answer the gender question however you feel comfortable. There are no right or wrong answers. You may add more than one gender. 
  * When you click on the textbox, you will notice a list of options. You may select any of those options. 
  * If you start typing, you will see additional options for selection that match what you are searching for. If there are no options that match the gender term you wrote in, you may still add the gender. 
  * If you do not wish to answer this question, you may select 'I prefer not to answer'

  If you have any concerns about this question, please contact us at [support@pocketrn.com](mailto:support@pocketrn.com).
`;
