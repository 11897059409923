import { Action } from '@pocketrn/client/dist/entity-sdk';
import { InviteCodeDataAction, IsRegisteringAction, RegistrationActionId } from './actions';
import { InviteCodeData } from '../../../entities/Router';

export interface RegistrationState {
  isRegistering: boolean;
  inviteCodeData: InviteCodeData;
  finishingRegistration: boolean;
}

const initialState: RegistrationState = {
  isRegistering: false,
  finishingRegistration: false,
  inviteCodeData: { providerId: '', inviteCode: '' },
};

const registrationReducer = (state = initialState, action: Action): RegistrationState => {
  const newState = state;
  switch (action.type) {
    case RegistrationActionId.IS_REGISTERING:
      return State.setIsRegistering(newState, action as IsRegisteringAction);
    case RegistrationActionId.INVITE_CODE:
      return State.setInviteCode(newState, action as InviteCodeDataAction);
    default:
      return state;
  }
};

class State {
  public static setIsRegistering(
    state: RegistrationState,
    action: IsRegisteringAction,
  ): RegistrationState {
    return { ...state, isRegistering: action.isRegistering };
  }

  public static setInviteCode(
    state: RegistrationState,
    action: InviteCodeDataAction,
  ): RegistrationState {
    return { ...state, inviteCodeData: action.inviteCodeData };
  }
}

export default registrationReducer;
