import { TimeUtils } from '@pocketrn/time-utils';
import { Draft } from './Draft';
import { DraftJson } from './Draft.json';

export function buildDraft(data: DraftJson): Draft {
  const feature = new Draft({
    id: data.id,
    groupId: data.groupId,
    providerId: data.providerId,
  }, data.value ?? '');
  feature.createdAt = TimeUtils.copyDate(data.createdAt) ?? new Date();
  feature.updatedAt = TimeUtils.copyDate(data.updatedAt) ?? new Date();
  feature.expiresAt = TimeUtils.copyDate(data.expiresAt) ?? new Date();
  return feature;
}
