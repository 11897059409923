import { Auth } from 'firebase/auth';
import { FirebaseSDK, FirebaseFunctionInterface, OnCallFunction } from '@pocketrn/client/dist/entity-sdk';

export class HelpSDK extends FirebaseSDK {
  constructor(functions: FirebaseFunctionInterface, firebaseAuth: Auth) {
    super(functions, firebaseAuth);
  }

  public async contactSupport(message: string, messageType: string): Promise<void> {
    try {
      const data: any = { message, messageType };
      await this.functions.httpsCallable(OnCallFunction.Help, 'contactSupport')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async reportIssue(message: string, messageType: string): Promise<void> {
    try {
      const data: any = { message, messageType };
      await this.functions.httpsCallable(OnCallFunction.Help, 'reportIssue')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async completeUserTutorial(tutorialId: string): Promise<void> {
    try {
      const data = { tutorialId };
      await this.functions.httpsCallable(OnCallFunction.Help, 'completeUserTutorial')(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }
}
