import React, {
  Fragment,
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { Text } from '../../../entities/Text';
import styles from './UserChat.module.css';
import { ChatText } from './ChatText';
import { Message, UserChat as UserChatEntity } from '@pocketrn/entities/dist/chatbot';
import { TextType } from '../../../entities/enums/TextType';
import {
  MultiLineTextField,
  LeftArrowIcon,
  IconButton,
  SendIcon,
  Spinner,
  useScrolledToTop,
  Caption,
} from '@pocketrn/rn-designsystem';
import { useSelector } from 'react-redux';
import { ChatTextDate } from './ChatTextDate';
import { selectPersonMap } from '../../../state/redux/messages/selectors';
import { LocalePrimer } from '@pocketrn/localizer';
import { selectSessionPerson, selectSessionUser } from '../../../../user-state/index';
import { Person } from '@pocketrn/entities/dist/community';
import { isRequestor } from '@pocketrn/entities/dist/core';

interface UserChatProps {
  chat: UserChatEntity | undefined;
  atEndOfChatHistory: boolean;
  scrollToBottomTarget: FunctionComponent;
  scrollToTopTarget: FunctionComponent;
  loading?: boolean;
  onBack: () => void;
  onSendMessage: (message: string) => void;
  onScrollToTop: (atTop: boolean) => void;
  localePrimer: LocalePrimer;
}

const locale = 'messages.userChat';

export const UserChat: FunctionComponent<UserChatProps> = (props): ReactElement => {
  const [ userMessage, setUserMessage ] = useState('');
  const [ sendingMessage, setSendingMessage ] = useState(false);
  const [ handleScroll, isScrolledToTop ] = useScrolledToTop();
  const [ previousMessagesLength, setPreviousMessagesLength ] = useState(0);
  const personMap = useSelector(selectPersonMap);
  const selfPerson = useSelector(selectSessionPerson);
  const loading = props.loading;
  const user = useSelector(selectSessionUser);

  useEffect(() => {
    setPreviousMessagesLength(props.chat?.messages.length ?? 0);
    props.onScrollToTop(isScrolledToTop);
  }, [isScrolledToTop]);

  useEffect(() => {
    if (!loading && sendingMessage) {
      setSendingMessage(false);
      setUserMessage('');
    }
  }, [loading]);

  const sendMessage = () => {
    if (!userMessage) {
      return;
    }
    props.onSendMessage(userMessage);
    setSendingMessage(true);
  };

  const updateMessage = (value: string) => {
    setUserMessage(value);
  };

  const getSender = (message: Message): Person | undefined => {
    if (!props.chat) {
      return undefined;
    }
    for (const uid of props.chat.participantUids) {
      if (message.uid === selfPerson?.uid) {
        return selfPerson;
      }
      if (message.uid === uid) {
        return personMap[uid];
      }
    }
    return undefined;
  };

  const getTextType = (message: Message): TextType => {
    const sender = getSender(message);
    if (!sender) {
      return TextType.Event;
    }
    return sender.uid === user?.uid ? TextType.Self : TextType.Other;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperTop}>
        <div className={styles.nav}>
          <IconButton onClick={props.onBack}><LeftArrowIcon /></IconButton>
        </div>
        <div className={`${styles.chat} ${loading ? styles.loading : ''}`} onScroll={handleScroll}>
          {loading && <Spinner />}
          {props.atEndOfChatHistory &&
            <div className={styles.endOfChat}>{props.localePrimer.translate(locale, 'endOfChat')}</div>
          }
          <div className={styles.chatMessages}>
            {props.chat?.messages && props.chat.messages.map((message, index) => {
              const prevIndex = index === 0 ? index : index - 1;
              const prevSentAt = props.chat?.messages[prevIndex].sentAt;
              const showDate = index === 0 || (
                message.sentAt.getFullYear() !== prevSentAt?.getFullYear() ||
                message.sentAt.getMonth() !== prevSentAt.getMonth() ||
                message.sentAt.getDate() !== prevSentAt.getDate()
              );
              const chatText = (
                <ChatText
                  text={new Text(message.text, getTextType(message), message.sentAt)}
                  sender={getSender(message)}
                  localePrimer={props.localePrimer}
                />
              );
              let chatTextWrapper;
              if (!showDate) {
                chatTextWrapper = chatText;
              } else {
                chatTextWrapper = (
                  <Fragment>
                    <ChatTextDate date={message.sentAt} localePrimer={props.localePrimer} />
                    {chatText}
                  </Fragment>
                );
              }
              if (!props.chat?.messages.length) {
                throw new Error('unexpected state of props.chat');
              }
              if (props.chat.messages.length - index === previousMessagesLength + 2) {
                return (
                  <Fragment key={index}>
                    { chatTextWrapper }
                    {props.scrollToTopTarget({})}
                  </Fragment>
                );
              } else {
                return (
                  <Fragment key={index}>
                    { chatTextWrapper }
                  </Fragment>
                );
              }
            })}
          </div>
          {props.scrollToBottomTarget({})}
        </div>
      </div>
      <section>
        <div className={styles.wrapperBottom}>
          <div className={styles.sendMessageInput}>
            <MultiLineTextField
              label={props.localePrimer.translate(locale, 'sendMessage')}
              value={userMessage}
              onChange={updateMessage}
              loading={props.loading}
              background="white"
            />
          </div>
          <div className={styles.sendMessageBtn}>
            <IconButton
              onClick={sendMessage}
              loading={props.loading}
              disabled={!userMessage}
            >
              <SendIcon />
            </IconButton>
          </div>
        </div>
        {!userMessage && user && isRequestor(user.activeAccountType) &&
          <div className={styles.disclaimer}>
            <Caption
              align="center"
              text={props.localePrimer.translate(locale, 'disclaimer')}
              noBottomPadding
            />
          </div>
        }
      </section>
    </div>
  );
};
