import { Tutorial, Tutorials } from '@pocketrn/entities/dist/core';
import { SessionUserController } from '../../../user-state/index';
import { HelpSDK } from '../../services/firebase/HelpSDK';

// @NOTE: Redux does not export its Store type.
export type ReduxStore = any;

export class TutorialController {
  public sdk: HelpSDK;
  public store: ReduxStore;
  public sessionUserController: SessionUserController;

  constructor(sdk: HelpSDK, store: ReduxStore, sessionUserController: SessionUserController) {
    this.sdk = sdk;
    this.store = store;
    this.sessionUserController = sessionUserController;
  }

  public async completeTutorial(tutorialId: string): Promise<void> {
    this.sessionUserController.setStoredUserLoading(true);
    await this.sdk.completeUserTutorial(tutorialId);
    const user = this.sessionUserController.getStoredActiveUser();
    if (!user) {
      throw new Error('No session user');
    }
    if (!user.tutorials) {
      user.tutorials = new Tutorials([]);
    }
    user.tutorials.tutorials.push(new Tutorial(tutorialId));
    this.sessionUserController.setStoredActiveUser(user);
    this.sessionUserController.setStoredUserLoading(false);
  }
}
