import React, {
  ReactElement,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import ChatDrawerContent from './ChatDrawerContent';
import { WindowType } from '../entities/enums/WindowType';
import { useSelector } from 'react-redux';
import {
  selectChatDrawerIsOpen,
  selectUnreadNotificationsCount,
  selectUnreadUserChatsCount,
} from '../state/redux/chatbot/selectors';
import { TopBar, AppBar } from '../ds';
import { Drawer, useWindowSize } from '@pocketrn/rn-designsystem';
import { MessagesController } from '../state/controllers/Messages.controller';
import { LocalePrimer } from '@pocketrn/localizer';
import { BotResponseLibrary } from '../utils/BotResponseLibrary';
import { ChatbotController } from '../state/controllers/Chatbot.controller';

export interface ChatDrawerProps {
  messagesController: MessagesController;
  chatbotController: ChatbotController;
  localePrimer: LocalePrimer;
  botResponseLibrary: BotResponseLibrary;
}

const DRAWER_OVERLAYS_CONTENT = 1300;

const ChatDrawer: FunctionComponent<ChatDrawerProps> = (props): ReactElement => {
  const unreadNotificationsCount = useSelector(selectUnreadNotificationsCount);
  const unreadUserChatsCount = useSelector(selectUnreadUserChatsCount);
  const [ windowType, setWindowType ] = useState(WindowType.Bot);
  const isOpen = useSelector(selectChatDrawerIsOpen);
  const { width } = useWindowSize();
  const shouldClickAway = width < DRAWER_OVERLAYS_CONTENT;

  useEffect(() => {
    props.messagesController.subscribeToSnapshotMessages();
    return function cleanup() {
      props.messagesController.unsubscribeFromSnapshotMessages();
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      if (unreadUserChatsCount > 0) {
        setWindowType(WindowType.Messages);
      } else if (unreadNotificationsCount > 0) {
        setWindowType(WindowType.Notifications);
      }
    }
  }, [ isOpen, unreadUserChatsCount, unreadNotificationsCount ]);

  useEffect(() => {
    if (!isOpen) {
      props.messagesController.clearUserChat();
    }
  }, [isOpen]);

  const changeWindowType = (windowType: WindowType): void => {
    setWindowType(windowType);
  };

  const close = (): void => {
    props.chatbotController.toggleDrawer(false);
  };

  const toggle = (): void => {
    if (!shouldClickAway) {
      return;
    }
    // @TODO: *Create issue for next iteration to fulfill drawer toggling
    // props.chatbotController.toggleDrawer(!isOpen);
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      {windowType !== WindowType.Message &&
        <TopBar
          windowType={windowType}
          close={close}
          localePrimer={props.localePrimer}
        />
      }
      <ChatDrawerContent
        isOpen={isOpen}
        windowType={windowType}
        close={close}
        messagesController={props.messagesController}
        localePrimer={props.localePrimer}
        botResponseLibrary={props.botResponseLibrary}
      />
      {windowType !== WindowType.Message &&
        <AppBar
          windowType={windowType}
          changeWindowType={changeWindowType}
          unreadNotificationsCount={unreadNotificationsCount}
          unreadUserChatsCount={unreadUserChatsCount}
        />
      }
    </Drawer>
  );
};

export default ChatDrawer;
