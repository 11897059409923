import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Feature } from './Feature';
import { selectFeatureFlags } from './selectors';
import { FeatureSpecifiers, getFlag } from './utils';

export function useFlag(specifiers: FeatureSpecifiers): Feature {
  const { id, groupId, activeUser } = specifiers;
  const uid = activeUser?.uid;
  const providerId = activeUser?.activeProviderId;
  const flags = useSelector(selectFeatureFlags);
  const [ feature, setFeature ] = useState(getFlag(
    specifiers,
    flags.features,
    flags.featureProviders,
    flags.featureUsers,
  ));

  useEffect(() => {
    setFeature(getFlag(
      specifiers,
      flags.features,
      flags.featureProviders,
      flags.featureUsers,
    ));
  }, [ id, groupId, uid, providerId ]);

  return feature;
}
