import { Person, PersonFactory } from '@pocketrn/entities/dist/community';
import { AccountType, User, UserFactory } from '@pocketrn/entities/dist/core';
import { FirebaseFunctionInterface, FirebaseSDK, ManagedProperty, OnCallFunction } from '@pocketrn/client/dist/entity-sdk';
import { Auth } from 'firebase/auth';

export class CommunitySDK extends FirebaseSDK {
  constructor(functions: FirebaseFunctionInterface, firebaseAuth: Auth) {
    super(functions, firebaseAuth);
  }

  public async declareEmergency(
    meetingId: string,
    managed?: ManagedProperty,
  ): Promise<{
    person: Person;
    user: User;
  }> {
    try {
      const resp = await this.functions.httpsCallable(
        OnCallFunction.Community,
        'declareEmergency',
        managed,
      )({ meetingId });
      return {
        person: PersonFactory.build(resp.data.person),
        user: UserFactory.build(resp.data.user),
      };
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async updatePerson(person: Person, managed?: ManagedProperty): Promise<void> {
    try {
      const json = person.json();
      const data = {
        person: {
          firstName: json.firstName,
          lastName: json.lastName,
          genderIdentity: json.genderIdentity,
          dateOfBirth: json.dateOfBirth,
          preferredName: json.preferredName,
          address: json.address,
          emergencyInstructions: json.emergencyInstructions,
        },
      };
      await this.functions.httpsCallable(OnCallFunction.Community, 'updatePerson', managed)(data);
    } catch (err) {
      throw await this.handleErr((err as Error));
    }
  }

  public async getPerson(
    managed?: ManagedProperty,
    forceAccount?: {
      accountType: AccountType;
      providerId: string;
    },
  ): Promise<Person> {
    let data: any = {};
    if (forceAccount) {
      data = {
        ...data,
        _managedAccount: {
          type: forceAccount.accountType,
          providerId: forceAccount.providerId,
        },
      };
    }
    try {
      const resp = await this.functions.httpsCallable(
        OnCallFunction.Community,
        'getPerson',
        managed,
      )(data);
      return PersonFactory.build(resp.data.person);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }
}
